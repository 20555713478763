import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Container,
  Button,
  Grid,
  Paper,
  CircularProgress,
  Popover,
  TextField,
  InputAdornment,
  IconButton,
  Alert,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import OfferDocumentService from "../../services/OfferDocument";
import OfferCard from "./OfferCard";
import { useSnackbar } from "notistack";
import OfferDialog from "./OfferDialog";
// Store
import ClubStore from "../../store/club";
import { observer } from "mobx-react-lite";

function Offers() {
  let currentClubId = ClubStore.currentClubId;
  const { enqueueSnackbar } = useSnackbar();
  let [showDialog, setShowDialog] = useState(null);
  let [dialogData, setDialogData] = useState(null);
  let [list, setList] = useState([]);

  useEffect(() => {
    OfferDocumentService.getAll().then((r) => setList(r.data));
  }, [currentClubId]);

  function closeDialogHandler() {
    setDialogData(null);
    setShowDialog(false);
  }
  function openDialogHandler(d) {
    setDialogData(d);
    setShowDialog(true);
  }

  function addItemHandler(i) {
    enqueueSnackbar("Документ добавлен!", { variant: "success" });
    setList([i, ...list]);
  }

  function changeItemHandler(i) {
    enqueueSnackbar("Документ изменён!", { variant: "success" });
    let newList = [...list];
    newList = newList.map((d) => (d._id == i._id ? i : d));
    setList(newList);
  }

  function deleteItemHandler(id) {
    if (window.confirm("Удалить документ")) {
      OfferDocumentService.delete(id).then(() => {
        let newList = [...list];
        newList = newList.filter((i) => i._id !== id);
        setList(newList);
        enqueueSnackbar("Документ удалён!", { variant: "success" });
      });
    }
  }

  return (
    <Box>
      <Typography variant="h5" gutterBottom component="div">
        Оферта
      </Typography>
      <Button
        size="small"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => setShowDialog(true)}
      >
        Добавить
      </Button>
      {showDialog && (
        <OfferDialog
          dialogData={dialogData}
          showDialog={showDialog}
          addItemHandler={addItemHandler}
          handleClose={closeDialogHandler}
          changeItemHandler={changeItemHandler}
        />
      )}

      <Grid sx={{ mt: 2 }} container spacing={2}>
        <Grid item xs={12} md={6}>
          {list.map((d) => (
            <OfferCard
              openDialogHandler={openDialogHandler}
              deleteHandler={deleteItemHandler}
              data={d}
            />
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}

export default observer(Offers);
