import axios from "axios";

class OrderSource {
  create(data) {
    return axios.post(`/source`, data);
  }
  getAll() {
    return axios.get(`/source`);
  }
  change(data) {
    return axios.put(`/source`, data);
  }
  delete(id) {
    return axios.delete(`/source`, { params: { id } });
  }
}

export default new OrderSource({});
