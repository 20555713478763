import React from "react";
import { Box, Select, MenuItem } from "@mui/material";
import InputWrap from "../InputWrap";
import moment from "moment";
import "moment/locale/ru";
import firstLetterUppercase from "../../common/firstLetterUppercase";
function generatedMonth() {
  let startMonth = moment().subtract(5, "months");
  let result = [];
  for (let i = 1; i <= 10; i++) {
    let date = moment(startMonth.valueOf()).add(i, "month").startOf("month");
    result.push({
      value: date.valueOf(),
      name: `${firstLetterUppercase(date.format("MMMM"))} ${date.format(
        "YYYY"
      )}`,
    });
  }

  return {
    list: result,
    current: moment().startOf("month").valueOf(),
  };

  // '
  // let currentData = moment().startOf('month');
  // console.log(currentData.format('MMMM YYYY'),currentData)
}

export default function SelectMonth({ date, setDate }) {
  let { list, current } = generatedMonth();
  if (!date) {
    setDate(current);
  }

  return (
    <InputWrap id="month" label="Месяц">
      <Select
        size="small"
        value={date}
        onChange={(e) => setDate(e.target.value)}
        fullWidth
      >
        {list.map((m) => {
          return <MenuItem key={m.value} value={m.value}>{m.name}</MenuItem>;
        })}
      </Select>
    </InputWrap>
  );
}
