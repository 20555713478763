import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import SmsService from "../../../services/Sms";
import SmsTable from "../../SmsTable/SmsTable";
import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
import { EventBus } from "../../../event";

export default function SmsList({ clientId }) {
  let [list, setList] = useState([]);
  useEffect(() => {
    SmsService.getByClient(clientId).then((r) => setList(r.data));
  }, []);

  return (
    <Box>
      <Button
        size="small"
        color="secondary"
        sx={{ mb: 2 }}
        variant="contained"
        startIcon={<MarkChatUnreadIcon size="large" />}
        onClick={(e) => {
          EventBus.$emit("modal_client_action", {
            action: "sms-send",
            data: [clientId],
          });
        }}
      >
        Отправить
      </Button>
      <SmsTable list={list} hideUser />
    </Box>
  );
}
