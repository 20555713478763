import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardActionArea,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableRow,
  Paper,
  Grid,
  Divider,
  Typography,
  SwipeableDrawer,
  Button,
  TextField,
  IconButton,
  ButtonGroup,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";

// import CloseIcon from "@mui/icons-material/Close";
// import InputWrap from "../../components/InputWrap";
// import PeopleIcon from "@mui/icons-material/People";
// import FunnelStageService from "../../services/FunnelStage";
import SelectGroup from "./SelectGroup";
import SearchClient from "./SearchClient";
import OrderService from "../../services/Order";
import { useSnackbar } from "notistack";
import SidebarWrap from "../SidebarWrap";
import InputWrap from "../InputWrap";
import StageStore from "../../store/funnelStage";
import { EventBus } from "../../event";

export default function CreateOrder({ closeHandler }) {
  const { enqueueSnackbar } = useSnackbar();
  let stageList = StageStore.orderStages;
  let [isSubmitting, setSubmitting] = useState(false);
  let [orderData, setOrderData] = useState({
    isChild: false,
    stage: "",
    group: "",
    visitDate: "",
    client: "",
  });
  let [errorData, setErrorData] = useState({
    group: "",
    client: "",
  });

  function changeOrderDataHandler(name, value) {
    let newOrderData = Object.assign({}, orderData, { [name]: value });
    setOrderData(newOrderData);
  }
  function changeErrorData(name, val) {
    let newErrorData = Object.assign({}, errorData, { [name]: val });
    setErrorData(newErrorData);
  }

  if (!orderData.stage && stageList.length !== 0) {
    changeOrderDataHandler("stage", stageList[0]._id);
  }

  const {
    register,
    handleSubmit,
    control,
    watch,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: "",
    },
  });

  const onSubmit = handleSubmit((d) => {
    console.log(d, "d");
    if (!orderData.client) {
      changeErrorData("client", "Выберете клиента");
      return;
    }
    if (!orderData.group || !orderData.visitDate) {
      changeErrorData("group", "Выберете группу и день посещения");
      return;
    }
    let fetchData = Object.assign({}, d, orderData);

    console.log(fetchData, "fetchData");

    setSubmitting(true);
    OrderService.create(fetchData)
      .then((res) => {
        enqueueSnackbar("Заявка создана!", { variant: "success" });
        closeHandler();
      })
      .catch((e) => {})
      .finally(() => {
        setSubmitting(false);
      });
  });

  return (
    <SidebarWrap title="Создать заявку" closeHandler={closeHandler}>
      <ButtonGroup sx={{ mb: 2 }} fullWidth variant="contained">
        <Button
          size="small"
          onClick={() => changeOrderDataHandler("isChild", false)}
          variant={!orderData.isChild ? "contained" : "outlined"}
        >
          Взрослый
        </Button>
        <Button
          size="small"
          onClick={() => changeOrderDataHandler("isChild", true)}
          variant={orderData.isChild ? "contained" : "outlined"}
        >
          Ребёнок
        </Button>
      </ButtonGroup>

      <form noValidate onSubmit={onSubmit}>
        <Controller
          name={"name"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputWrap wrapStyle={{ mb: 2 }} id="name" label="Наименование">
              <TextField
                error={Boolean(errors.name?.message)}
                {...register("name", {
                  required: "Название не может быть пустым",
                  minLength: {
                    message: "Мин. 4 символов",
                    value: 4,
                  },
                  maxLength: 200,
                })}
                fullWidth
                helperText={errors.name?.message}
                label=""
                type="text"
                onChange={onChange}
                value={value}
                sx={{ m: 0 }}
                size="small"
                variant="outlined"
              />
            </InputWrap>
          )}
        />
        <SearchClient
          isChild={orderData.isChild}
          title="Клиент"
          clientId={orderData.client}
          setClientId={(id) => {
            changeOrderDataHandler("client", id);
            changeErrorData("client", "");
          }}
          clientError={errorData.client}
          clearClientError={(id) => {
            changeOrderDataHandler("client", id);
          }}
        />
        <SelectGroup
          hasError={errorData.group}
          groupId={orderData.group}
          setGroupId={(id) => changeOrderDataHandler("group", id)}
          visitDate={orderData.visitDate}
          setVisitDate={(v) => changeOrderDataHandler("visitDate", v)}
        />
        <InputWrap id="stage" label="Этап воронки" wrapStyle={{ mb: 2 }}>
          <Select
            value={orderData.stage + ""}
            fullWidth
            size="small"
            onChange={(e) =>
              changeOrderDataHandler("stage", e.target.value + "")
            }
          >
            {stageList.map((so) => (
              <MenuItem key={so._id} value={so._id}>
                {so.name}
              </MenuItem>
            ))}
          </Select>
        </InputWrap>
        <Button
          fullWidth
          sx={{ marginTop: 2 }}
          variant="outlined"
          type="submit"
          startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
          disabled={isSubmitting}
        >
          Создать
        </Button>
      </form>
    </SidebarWrap>
  );
}
