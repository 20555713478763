import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Typography,
  SwipeableDrawer,
  Chip,
  Button,
  IconButton,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import AddIcon from "@mui/icons-material/Add";
import StageWindow from "./StageWindow";
import { useSnackbar } from "notistack";
import FunnelStageService from "../../services/FunnelStage";
import FunnelStageStore from "../../store/funnelStage";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteIcon from "@mui/icons-material/Delete";

function FunnelStages() {
  const { enqueueSnackbar } = useSnackbar();
  let [showStage, setShowStage] = useState(null);
  let [mode, setMode] = useState("");

  function closeStageHandler() {
    setShowStage(null);
  }

  function openStageHandler(id, mode) {
    setMode(mode);
    setShowStage(id);
  }

  function deleteStageHandler(id) {
    if (window.confirm("Удалить этап?")) {
      FunnelStageService.delete(id).then((r) => {
        FunnelStageStore.deleteStage(id);
        enqueueSnackbar("Этап удалён!", { variant: "success" });
      });
    }
  }

  return (
    <Box>
      <SwipeableDrawer
        anchor="right"
        open={!!showStage}
        onClose={closeStageHandler}
      >
        <StageWindow
          closeHandler={closeStageHandler}
          mode={mode}
          id={showStage}
        />
      </SwipeableDrawer>
      <Typography sx={{ mb: 6 }} variant="h5" gutterBottom>
        Этапы воронки
      </Typography>
      <Button
        sx={{ marginBottom: "20px" }}
        size="small"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => openStageHandler("new", "order")}
      >
        Добавить
      </Button>
      <Typography variant="h6" gutterBottom>
        Заявки
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ borderTop: "none" }}>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Название</TableCell>
              <TableCell> Порядок</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {FunnelStageStore.orderStages.map((s, i) => {
              return (
                <TableRow key={s._id}>
                  <TableCell>{i + 1}</TableCell>
                  <TableCell>
                    <Chip
                      label={s.name}
                      sx={{ bgcolor: s.backgroundColor, color: s.textColor }}
                    />
                  </TableCell>
                  <TableCell>{s.displayOrder}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => openStageHandler(s._id)}>
                      <ModeEditOutlineIcon />
                    </IconButton>
                    {!s.isSuccess && (
                      <IconButton onClick={() => deleteStageHandler(s._id)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography sx={{ mt: 4 }} variant="h6" gutterBottom>
        Абонементы
      </Typography>
      <Button
        sx={{ marginBottom: "20px" }}
        size="small"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => openStageHandler("new", "membership")}
      >
        Добавить
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ borderTop: "none" }}>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Название</TableCell>
              <TableCell> Порядок</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {FunnelStageStore.membershipStages.map((s, i) => {
              return (
                <TableRow key={s._id}>
                  <TableCell>{i + 1}</TableCell>
                  <TableCell>
                    <Chip
                      label={s.name}
                      sx={{ bgcolor: s.backgroundColor, color: s.textColor }}
                    />
                  </TableCell>
                  <TableCell>{s.displayOrder}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => openStageHandler(s._id)}>
                      <ModeEditOutlineIcon />
                    </IconButton>
                    {!s.isSuccess && (
                      <IconButton onClick={() => deleteStageHandler(s._id)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default observer(FunnelStages);
