import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Typography,
  CircularProgress,
  SwipeableDrawer,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Tabs,
  Tab,
} from "@mui/material";
import Chat from "./Chat";
import Feedback from "./Feedback";
import Push from "./Push";
import Sms from "./Sms";
import Telegram from "./Telegram/Telegram";

function Communication() {
  const [currentTab, setCurrentTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  function viewSection(n) {
    switch (n) {
      case 0:
        return <Chat />;
      case 1:
        return <Feedback />;
      case 2:
        return <Push />;
      case 3:
        return <Sms />;
      case 4:
        return <Telegram />;
      default:
        return null;
    }
  }

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Коммуникация
      </Typography>
      <Box sx={{ overflowX: "auto", mb: 1 }}>
        <Tabs
          sx={{ mb: 1, width: "max-content" }}
          value={currentTab}
          onChange={handleChangeTab}
        >
          <Tab label="Чат" />
          <Tab label="Обратная связь" />
          <Tab label="Уведомления" />
          <Tab label="SMS" />
          <Tab label="Telegram" />
        </Tabs>
      </Box>

      <Box>{viewSection(currentTab)}</Box>
    </Box>
  );
}

export default Communication;
