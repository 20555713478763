import React from "react";
import { Box, Typography } from "@mui/material";
import getDayNameByNumer from "../../../common/getDayNameByNumer";
import moment from "moment";
import "moment/locale/ru";

import groupStore from "../../../store/group";
import trainerStore from "../../../store/trainer";

export default function StageCardInfo({ data }) {
  if (!data) return;
  let groupList = groupStore.list;
  let trainerList = trainerStore.list;

  let group = groupList.find((g) => g._id == data.group);
  let trainer = trainerList.find((t) => t._id == group.trainer);
  let visitDays = data.visitDays.map((d) => getDayNameByNumer(+d)).join(", ");

  return (
    <Box sx={{ p: 1 }}>
      <Typography variant="subtitle1">{group.name}</Typography>
      <Typography variant="subtitle1">{trainer.name}</Typography>
      <Typography variant="subtitle1">{visitDays}</Typography>
      <Typography variant="subtitle1">{data.paymentAmount} руб. </Typography>
      <Typography variant="subtitle1">
        {moment(data.datePayment).format("DD MMMM YYYY, в HH:mm")}
      </Typography>
    </Box>
  );
}
