import axios from "axios";

class TrainerReview {
  getAll() {
    return axios.get(`/trainer-review`);
  }
  change(data) {
    return axios.put(`/trainer-review`, data);
  }
}

export default new TrainerReview({});
