import React, { useState } from "react";
import {
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  Chip,
  TableContainer,
  TableHead,
  TableRow,
  Popover,
  Checkbox,
  Paper,
  Divider,
  Typography,
  SwipeableDrawer,
  Button,
  Menu,
  IconButton,
  FormControl,
  TablePagination,
  Avatar,
  Select,
  MenuItem,
} from "@mui/material";
import StageOrderCard from "./StageOrderCard";
import moment from "moment";
import "moment/locale/ru";
import FunnelStageCard from "../../components/FunnelStageCard";
import OrderService from "../../services/Order";
import { useSnackbar } from "notistack";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import { EventBus } from "../../event";
import GroupStore from "../../store/group";
import ClubStore from "../../store/club";

import TrainerStore from "../../store/trainer";
import FunnelStageStore from "../../store/funnelStage";
import groupGetFullInfo from "../../common/groupGetFullInfo";
import StageCardChange from "./StageCardChange";

function OrderTableRow({ data, handleOpenStageCard }) {
  let currentClubId = ClubStore.currentClubId;

  let groupList = GroupStore.list;
  let trainerList = TrainerStore.list;
  let stageList = FunnelStageStore.list;

  

  // const { enqueueSnackbar } = useSnackbar();
  // let [order, setOrder] = useState(data);

  let client = data.client;

  let clientName = `${client.surname} ${client.name} ${client.patronymic}`;
  let childName = "-";

  if (client.isChild) {
    childName = `${client.child.surname} ${client.child.name} ${client.child.patronymic}`;
  }

  // // count = count + 1;
  let createdText = moment(new Date(data.created_at)).format(
    "DD.MM.YYYY, в HH:mm"
  );

  let group = groupList.find((g) => g._id == data.group);
  let trainer = trainerList.find((t) => t._id == group.trainer);
  let st = stageList.find((s) => s._id == data.stage);

  return (
    <TableRow key={data._id}>
      <TableCell sx={{ width: "50px" }}>
        <Checkbox color="primary" />
      </TableCell>

      <TableCell sx={{ width: "50px" }}>{data.name}</TableCell>
      <TableCell>{createdText}</TableCell>
      <TableCell
        sx={{
          cursor: "pointer",
          position: "sticky",
          left: 0,
          bgcolor: "#434242",
        }}
        onClick={() => EventBus.$emit("open_client_window", client._id)}
      >
        <Typography variant="caption"> {clientName}</Typography>
      </TableCell>
      <TableCell>{childName}</TableCell>
      <TableCell>{`+${client.phone}`}</TableCell>
      <TableCell>{trainer.name}</TableCell>
      <TableCell>{groupGetFullInfo(group)}</TableCell>
      <TableCell>
        {moment(data.visitDate)
          .set("hour", group.visitingTime.split(":")[0])
          .set("minute", group.visitingTime.split(":")[1])
          .format("DD.MM.YYYY, в HH:mm")}
      </TableCell>

      <TableCell
      // id={`sc-${r._id}`}
      >
        <Box
          sx={{ w: "100%" }}
          // aria-describedby={anchorStageCard}
          onClick={(e) => handleOpenStageCard(e.currentTarget, data)}
        >
          {st && (
            <Chip
              sx={{
                minWidth: "100%",
                borderRadius: "5px",
                color: st.textColor,
                bgcolor: st.backgroundColor,
              }}
              label={st.name}
            />
          )}
        </Box>
      </TableCell>
      <TableCell>
        {!data.isSuccess && (
          <IconButton
            onClick={() => EventBus.$emit("open_edit_order_window", data)}
          >
            <EditIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
}

export default function OrderTable({
  list,
  paginData,
  handleChangePage,
  handleChangeRowsPerPage,
  changeStageOrder,
}) {
  const [stageCardData, setStageCardData] = useState(null);
  const [anchorStageCard, setAnchorStageCard] = useState(null);

  const handleOpenStageCard = (anc, data) => {
    let isSuccess = data.isSuccess;

    if (isSuccess) {
      return;
    } else {
      setStageCardData(data);
      setAnchorStageCard(anc);
    }
  };

  const handleCloseStageCard = () => {
    setAnchorStageCard(null);
    setStageCardData(null);
  };

  return (
    <Box>
      <Popover
        id={anchorStageCard}
        open={!!anchorStageCard && !!stageCardData}
        anchorEl={anchorStageCard}
        onClose={handleCloseStageCard}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          sx: {
            borderRadius: "10px",
            overflow: "hidden",
          },
        }}
      >
        <StageCardChange
          closeHandler={handleCloseStageCard}
          changeStageOrder={changeStageOrder}
          data={stageCardData}
        />
      </Popover>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "50px" }}>
                <Checkbox color="primary" />
              </TableCell>
              <TableCell>Название</TableCell>
              <TableCell> Дата создания</TableCell>
              <TableCell> ФИО Клиента</TableCell>
              <TableCell> ФИО Ребёнка</TableCell>
              <TableCell> Телефон</TableCell>
              <TableCell> Тренер</TableCell>
              <TableCell> Группа</TableCell>
              <TableCell> Дата занятия</TableCell>
              <TableCell> Этап воронки</TableCell>
              <TableCell> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((r, i) => {
              return (
                <OrderTableRow
                  handleOpenStageCard={handleOpenStageCard}
                  // onClick={(e) =>
                  //   handleOpenStageCard(e.currentTarget, r.membership)
                  // }

                  data={r}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component={Paper}
        count={paginData.countRows}
        rowsPerPage={paginData.rowsPerPage}
        page={paginData.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
