import React, { useState } from "react";
import {
  TextField,
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ButtonGroup,
  CircularProgress,
  Paper,
  Typography,
  Grid,
  IconButton,
  Divider,
} from "@mui/material";
import InputWrap from "../InputWrap";
import SidebarWrap from "../SidebarWrap";
import CashbackService from "../../services/Cashback";
import { useSnackbar } from "notistack";
import { EventBus } from "../../event";

export default function ChargeClientBonus({ closeHandler, id }) {
  const { enqueueSnackbar } = useSnackbar();
  let [isSubmitting, setSubmitting] = useState(false);
  let [data, setData] = useState({
    action: "add",
    amount: 0,
    comment: "",
  });
  let [errors, setErrors] = useState({
    amount: null,
    comment: null,
  });

  function changeDataHandler(name, value) {
    let newData = Object.assign({}, data, { [name]: value });
    setData(newData);
    setErrors({ amount: null, comment: null });
  }

  function changeError(name, value) {
    let newError = Object.assign({}, errors, { [name]: value });
    setErrors(newError);
  }

  function onSubmit() {
    console.log("onSubmit");
    if (data.amount <= 0) {
      changeError("amount", "Введите сумму");
      return;
    }
    if (data.comment.trim().length == 0) {
      changeError("comment", "Введите комментарий");
      return;
    }

    let sum = Math.abs(data.amount);

    if (data.action == "sub") {
      sum = -sum;
    }
    CashbackService.chargeSum({ sum, id, comment: data.comment }).then((r) => {
      enqueueSnackbar("Бонусный баланс изменён!", { variant: "success" });
      closeHandler();
    });
  }

  return (
    <SidebarWrap title="Бонусы" closeHandler={closeHandler}>
      <Box>
        <ButtonGroup sx={{ mb: 2 }} fullWidth variant="contained">
          <Button
            size="small"
            onClick={() => changeDataHandler("action", "add")}
            variant={data.action == "add" ? "contained" : "outlined"}
          >
            Начислить
          </Button>
          <Button
            size="small"
            onClick={() => changeDataHandler("action", "sub")}
            variant={data.action == "sub" ? "contained" : "outlined"}
          >
            Списать
          </Button>
        </ButtonGroup>
        <InputWrap wrapStyle={{ mb: 2 }} id="amount" label="Сумма">
          <TextField
            fullWidth
            label=""
            error={!!errors.amount}
            helperText={errors.amount}
            type="number"
            onChange={(e) =>
              changeDataHandler("amount", Math.abs(e.target.value))
            }
            value={data.amount}
            sx={{ m: 0 }}
            size="small"
            variant="outlined"
          />
        </InputWrap>

        <InputWrap wrapStyle={{ mb: 2 }} id="comment" label="Комментарий">
          <TextField
            fullWidth
            label=""
            type="text"
            error={!!errors.comment}
            helperText={errors.comment}
            onChange={(e) => changeDataHandler("comment", e.target.value)}
            value={data.comment}
            sx={{ m: 0 }}
            size="small"
            variant="outlined"
          />
        </InputWrap>
        <Button
          fullWidth
          sx={{ marginTop: 2 }}
          variant="outlined"
          startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
          disabled={isSubmitting}
          onClick={onSubmit}
        >
          Отправить
        </Button>
      </Box>
    </SidebarWrap>
  );
}
