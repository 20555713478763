import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Avatar } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import InputWrap from "../InputWrap";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectChip({
  title,
  list,
  setList,
  data,
  photo = "",
}) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setList(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  function getNameById(id) {
    return data.find((d) => d._id == id)?.name;
  }

  const handleDelete = (e, id) => {
    e.preventDefault();
    let newList = [...list];
    newList = newList.filter((d) => d != id);
    setList(newList);
  };

  return (
    <InputWrap label={title}>
      <Select
        multiple
        fullWidth
        value={list}
        onChange={handleChange}
        size="small"
        renderValue={(selected) => {
          console.log(selected, "selected");

          return (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  // avatar={
                  //   <Avatar
                  //     alt="Natacha"
                  //     src="https://s2.coinmarketcap.com/static/img/coins/200x200/5805.png"
                  //   />
                  // }
                  // onClick={() => }
                  // clickable
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                  onDelete={(e) => handleDelete(e, value)}
                  key={value}
                  label={getNameById(value)}
                />
              ))}
            </Box>
          );
        }}
        MenuProps={MenuProps}
      >
        {data.map((d) => (
          <MenuItem key={d._id} value={d._id}>
            {d.name}
          </MenuItem>
        ))}
      </Select>
    </InputWrap>
  );
}
