import React, { useState, useCallback, useEffect } from "react";
import {
  TextField,
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Avatar,
  TableRow,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ButtonGroup,
  CircularProgress,
  Paper,
  Typography,
  Grid,
  IconButton,
  Divider,
  Modal,
  Alert,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import InputWrap from "../../components/InputWrap";
import { useSnackbar } from "notistack";
import getTheme from "../../common/feedback/getTheme";
import getPrivateImageUri from "../../common/file/getPrivateImageUri";
import { EventBus } from "../../event";
import FeedbackService from "../../services/Feedback";

export default function ShowFeedback({ data }) {
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (!data.isViewed) {
      FeedbackService.changeViewed(data._id).then(() => {
        enqueueSnackbar("Сообщение просмотрено!", { variant: "success" });
      });
    }
  }, []);

  let document = null;

  function openImage() {
    EventBus.$emit("show_image_viewer", [getPrivateImageUri(data.photo)]);
  }

  if (data.photo) {
    document = (
      <React.Fragment>
        <Typography sx={{ fontSize: 14, color: "gray", my: 2 }}>
          Документ:
        </Typography>
        <Avatar
          onClick={openImage}
          variant="square"
          sx={{
            width: "250px",
            mx: "auto",
            display: "block",
            height: "auto",
            mb: 4,
          }}
          src={getPrivateImageUri(data.photo)}
        />
      </React.Fragment>
    );
  }

  return (
    <Box>
      <Typography sx={{ fontSize: 14, color: "gray" }}>Тема:</Typography>
      <Typography fontWeight="normal" variant="body1">
        {getTheme(data.theme)}
      </Typography>
      <Typography sx={{ fontSize: 14, color: "gray", mt: 2 }}>
        Сообщение:
      </Typography>
      <Typography variant="body1">{data.message}</Typography>
      {document}
    </Box>
  );
}
