import React from "react";
import { Box, MenuList, MenuItem } from "@mui/material";
import { useSnackbar } from "notistack";
import FunnelStageStore from "../../store/funnelStage";
import OrderService from "../../services/Order";

function StageCardChange({ data, closeHandler, changeStageOrder }) {
  const { enqueueSnackbar } = useSnackbar();
  let stageList = FunnelStageStore.orderStages;

  function changeStageHandler(id) {
    // FunnelStage.
    OrderService.changeStage({ stage: id, id: data._id }).then((r) => {
      changeStageOrder(r.data);
      closeHandler();
      enqueueSnackbar("Этап изменён!", { variant: "success" });
    });
  }

  return (
    <MenuList sx={{ p: 0 }}>
      {stageList.map((s) => (
        <MenuItem
          onClick={() => changeStageHandler(s._id)}
          sx={{
            bgcolor: s.backgroundColor,
            color: s.textColor,
            "&:hover": { bgcolor: s.backgroundColor, opacity: 0.7 },
          }}
        >
          {s.name}
        </MenuItem>
      ))}
    </MenuList>
  );
}

export default StageCardChange;
