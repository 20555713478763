import React from "react";
import {
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Typography,
  SwipeableDrawer,
  Button,
  IconButton,
  Grid,
} from "@mui/material";
import SourceList from "./SourceList";
import TrainerWage from "./TrainerWage";
import CostCategory from "./CostCategory";

export default function GlobalSettings() {
  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Общие настройки
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <CostCategory />
        </Grid>
        <Grid item xs={12} md={6}>
          <SourceList />
        </Grid>
        <Grid item xs={12} md={6}>
          <TrainerWage />
        </Grid>
      </Grid>
    </Box>
  );
}
