import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/ru";
import {
  Box,
  TablePagination,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Popover,
  Divider,
  Typography,
  SwipeableDrawer,
  Button,
  IconButton,
  Menu,
  MenuList,
  Chip,
  MenuItem,
  ListItemText,
  Checkbox,
  ListItemIcon,
} from "@mui/material";
import StageCard from "./StageCard";
import { EventBus } from "../../../event";
import EditIcon from "@mui/icons-material/Edit";
import SourceStore from "../../../store/source";
import FunnelStageStore from "../../../store/funnelStage";
import getClientStatusName from "../../../common/getClientStatusName";
import constants from "../../../constants";
import getPrivateImageUri from "../../../common/file/getPrivateImageUri";
import SortIcon from "@mui/icons-material/Sort";

export default function ClientsList({
  list,
  stat = {},
  month,
  changeMember,
  paginData,
  handleChangePage,
  handleChangeRowsPerPage,
  selectedClients,
  changeSelectedClients,
  allSelectedClients,
  changeAllSelectedClients,
  changeSortData,
  sortData,
}) {
  let sources = SourceStore.list;
  const [stageCardData, setStageCardData] = useState(null);
  const [anchorStageCard, setAnchorStageCard] = useState(null);
  const [moreClient, setMoreClient] = useState(null);

  const handleOpenStageCard = (anc, data) => {
    setStageCardData(data);
    setAnchorStageCard(anc);
  };

  const handleCloseStageCard = () => {
    setAnchorStageCard(null);
    setStageCardData(null);
  };

  useEffect(() => {
    handleCloseStageCard();
  }, [list]);

  return (
    <Box sx={{ mt: 4 }}>
      <Popover
        id={anchorStageCard}
        open={!!anchorStageCard && !!stageCardData}
        anchorEl={anchorStageCard}
        onClose={handleCloseStageCard}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          sx: {
            borderRadius: "10px",
            overflow: "hidden",
          },
        }}
      >
        <StageCard
          closeHandler={handleCloseStageCard}
          month={month}
          changeMember={changeMember}
          data={stageCardData}
        />
      </Popover>

      <TableContainer
        sx={{ maxWidth: "100%", overflowX: "scroll" }}
        // component={Paper}
      >
        <Popover
          id={moreClient}
          open={!!moreClient}
          anchorEl={moreClient}
          onClose={() => setMoreClient(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Paper>
            <MenuList>
              <MenuItem>
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Редактировать</ListItemText>
              </MenuItem>
            </MenuList>
          </Paper>
        </Popover>

        <Table
          sx={{ maxWidth: "100%", overflowX: "scroll" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "50px" }}>
                <Checkbox
                  color="primary"
                  checked={allSelectedClients}
                  onChange={(e) => changeAllSelectedClients(e.target.checked)}
                />
              </TableCell>
              <TableCell sx={{ width: "50px" }}></TableCell>
              <TableCell>Статус</TableCell>
              <TableCell>ФИО Клиента</TableCell>
              <TableCell>ФИО Ребёнка</TableCell>
              <TableCell>Телефон</TableCell>
              <TableCell>Дата рождения </TableCell>
              <TableCell
                onClick={() => changeSortData("reg")}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                Дата регистрации
                {sortData.reg !== null && (
                  <SortIcon
                    size="small"
                    sx={{
                      ml: 0.2,
                      minWidth: "15px",
                      transform: `rotate(${sortData.reg ? 180 : 0}deg)`,
                    }}
                  />
                )}
              </TableCell>
              <TableCell>Бонусный уровень</TableCell>
              <TableCell>Источник</TableCell>
              <TableCell>Этап воронки</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((r) => {
              let clientFullName = `${r.surname} ${r.name} ${r.patronymic}`;
              let childFullName = "-";

              let birthdayDate = "-";
              let ageDate = "-";

              let createdText = moment(new Date(r.created_at)).format(
                "DD.MM.YYYY, HH:mm"
              );

              if (r.isChild) {
                birthdayDate = moment().diff(r.child.birthday, "years");
                ageDate = moment(r.child.birthday).format("DD.MM.YYYY");
                childFullName = `${r.child.surname} ${r.child.name} ${r.child.patronymic}`;
              } else {
                ageDate = moment(r.birthday).format("DD.MM.YYYY");
                birthdayDate = moment().diff(r.birthday, "years");
              }

              let stageId = r.membership && r.membership.stage;
              let st;
              if (stageId) {
                st = FunnelStageStore.list.find((s) => s._id == stageId);
              }
              let cashback = r?.account?.cashback;

              if (!cashback) return null;

              return (
                <TableRow key={r._id}>
                  <TableCell sx={{ width: "50px" }}>
                    <Checkbox
                      color="primary"
                      checked={selectedClients.includes(r._id)}
                      onChange={(e) => changeSelectedClients(r._id)}
                    />
                  </TableCell>

                  <TableCell sx={{ width: "50px" }}>
                    <Avatar src={getPrivateImageUri(r.photo)} />
                  </TableCell>
                  <TableCell>
                    <Chip
                      sx={{
                        minWidth: "100%",
                        color: "white",
                        bgcolor: constants.clientStatusChip[r.status],
                      }}
                      label={getClientStatusName(r.status)}
                    />
                  </TableCell>

                  <TableCell
                    onClick={() => EventBus.$emit("open_client_window", r._id)}
                    sx={{
                      cursor: "pointer",
                      position: "sticky",
                      left: 0,
                      bgcolor: "#434242",
                    }}
                  >
                    <Typography variant="caption">{clientFullName}</Typography>
                  </TableCell>
                  <TableCell>{childFullName}</TableCell>
                  <TableCell>+{r.phone}</TableCell>
                  <TableCell>
                    {ageDate}
                    <br />
                    <small style={{ color: "#9faecb" }}>
                      ({birthdayDate} лет)
                    </small>
                  </TableCell>
                  <TableCell>{createdText}</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {r.account.cashback.name}
                  </TableCell>

                  <TableCell>
                    {sources.find((s) => s._id == r.source)?.name}
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{ w: "100%" }}
                      onClick={(e) =>
                        handleOpenStageCard(e.currentTarget, r.membership)
                      }
                    >
                      {st && (
                        <Chip
                          sx={{
                            minWidth: "100%",
                            borderRadius: "5px",
                            color: st.textColor,
                            bgcolor: st.backgroundColor,
                          }}
                          label={st.name}
                        />
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    {r.membership.isPayment && (
                      <IconButton
                        onClick={() =>
                          EventBus.$emit("open_edit_memb_window", r)
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component={Paper}
        count={paginData.countRows}
        rowsPerPage={paginData.rowsPerPage}
        page={paginData.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
