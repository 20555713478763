import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardActionArea,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableRow,
  Paper,
  Grid,
  Divider,
  Typography,
  SwipeableDrawer,
  Button,
  TextField,
  IconButton,
  ButtonGroup,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import SelectGroup from "./SelectGroup";
import OrderService from "../../services/Order";
import { useSnackbar } from "notistack";
import SidebarWrap from "../SidebarWrap";
import InputWrap from "../InputWrap";
import { EventBus } from "../../event";

function ChangeOrder({ closeHandler, data }) {
  const { enqueueSnackbar } = useSnackbar();
  let [isSubmitting, setSubmitting] = useState(false);

  let [orderData, setOrderData] = useState({
    group: data ? data.group : "",
    visitDate: data ? data.visitDate : "",
  });
  let [errorData, setErrorData] = useState({
    group: "",
    client: "",
  });
  function changeOrderDataHandler(name, value) {
    let newOrderData = Object.assign({}, orderData, { [name]: value });
    setOrderData(newOrderData);
  }
  function changeErrorData(name, val) {
    let newErrorData = Object.assign({}, errorData, { [name]: val });
    setErrorData(newErrorData);
  }

  const {
    register,
    handleSubmit,
    control,
    watch,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: data ? data.name : "",
    },
  });
  const onSubmit = handleSubmit((d) => {
    console.log(d, "d");
    // if (!orderData.client) {
    //   changeErrorData("client", "Выберете клиента");
    //   return;
    // }
    if (!orderData.group || !orderData.visitDate) {
      changeErrorData("group", "Выберете группу и день посещения");
      return;
    }
    let fetchData = Object.assign({}, d, orderData, { _id: data._id });
    // console.log(fetchData, "fetchData");
    setSubmitting(true);
    OrderService.change(fetchData)
      .then((res) => {
        let data = res.data;
        EventBus.$emit("order_list_change", data);
        // //   addOrder(data);
        // // console.log(data, "data");
        enqueueSnackbar("Заявка изменена!", { variant: "success" });
        closeHandler();
      })
      .catch((e) => {})
      .finally(() => {
        setSubmitting(false);
      });
  });

  let name = "";
  let adult = "";
  if (data) {
    let client = data.client;
    let isChild = client.isChild;
    if (isChild) {
      adult = "Ребёнок";
      name = `${client.child.surname} ${client.child.name} ${client.child.patronymic}`;
    } else {
      adult = "Взрослый";
      name = `${client.surname} ${client.name} ${client.patronymic}`;
    }
  }

  return (
    <SidebarWrap title="Изменение заявки" closeHandler={closeHandler}>
      <form noValidate onSubmit={onSubmit}>
        {name && (
          <Typography variant="h6" sx={{ mb: 2 }} gutterBottom>
            {name} ({adult})
          </Typography>
        )}

        <Controller
          name={"name"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputWrap wrapStyle={{ mb: 2 }} id="name" label="Наименование">
              <TextField
                error={Boolean(errors.name?.message)}
                {...register("name", {
                  required: "Название не может быть пустым",
                  minLength: {
                    message: "Мин. 4 символов",
                    value: 4,
                  },
                  maxLength: 200,
                })}
                fullWidth
                helperText={errors.name?.message}
                label=""
                type="text"
                onChange={onChange}
                value={value}
                sx={{ m: 0 }}
                size="small"
                variant="outlined"
              />
            </InputWrap>
          )}
        />
        <SelectGroup
          hasError={errorData.group}
          groupId={orderData.group}
          setGroupId={(id) => changeOrderDataHandler("group", id)}
          visitDate={orderData.visitDate}
          setVisitDate={(v) => changeOrderDataHandler("visitDate", v)}
        />
        <Button
          fullWidth
          sx={{ marginTop: 2 }}
          variant="outlined"
          type="submit"
          startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
          disabled={isSubmitting}
        >
          Сохранить
        </Button>
      </form>
    </SidebarWrap>
  );
}
export default ChangeOrder;
