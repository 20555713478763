import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Container,
  Table,
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Typography,
  SwipeableDrawer,
  Popover,
  CircularProgress,
  Button,
  IconButton,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import SourceService from "../../services/Source";
import SourceStore from "../../store/source";
import { observer } from "mobx-react-lite";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

function SourceItem({ data }) {
  const { enqueueSnackbar } = useSnackbar();
  let [isEdit, setEdit] = useState(false);
  let [name, setName] = useState(data.name);

  function changeNameHandler() {
    SourceService.change({ name, _id: data._id }).then((r) => {
      setEdit(false);
      enqueueSnackbar("Источник изменён!", { variant: "success" });
      SourceStore.changeSource(r.data);
    });
  }

  function deleteNameHandler() {
    if (window.confirm("Удалить?")) {
      SourceService.delete(data._id).then(() => {
        setEdit(false);
        enqueueSnackbar("Источник удалён!", { variant: "success" });
        SourceStore.deleteSource(data._id);
      });
    }
  }

  return (
    <Box sx={{ w: "100%", display: "flex", alignItems: "center" }}>
      <TextField
        value={name}
        onChange={(e) => setName(e.target.value)}
        sx={{ flexGrow: 1 }}
        size="small"
        disabled={!isEdit}
        variant="outlined"
      />
      {!isEdit && !data.mobile && (
        <Box sx={{ ml: 1, display: "flex" }}>
          <IconButton onClick={() => setEdit(true)}>
            <EditIcon />
          </IconButton>
          <IconButton sx={{ ml: 1 }} onClick={deleteNameHandler}>
            <DeleteForeverIcon />
          </IconButton>
        </Box>
      )}
      {isEdit && !data.mobile && (
        <Box sx={{ ml: 1, display: "flex" }}>
          <IconButton onClick={changeNameHandler}>
            <SaveIcon />
          </IconButton>
          <IconButton sx={{ ml: 1 }} onClick={() => setEdit(false)}>
            <CancelIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}

function AddSourcePopup({ addSourceItem, closeHandler }) {
  const { enqueueSnackbar } = useSnackbar();
  let [name, setName] = useState("");
  let [error, setError] = useState(null);
  let [isSubmitting, setSubmiting] = useState(false);

  function changeNameHandler(e) {
    setError(null);
    setName(e.target.value);
  }

  function onSubmitHandler() {
    if (name.length < 4) {
      setError("Мин. 4 символа");
      return;
    }
    setSubmiting(true);
    SourceService.create({ name })
      .then((res) => {
        let r = res.data;
        enqueueSnackbar("Источник создан!", { variant: "success" });
        SourceStore.addSource(r);
        closeHandler();
      })
      .catch((e) => {
        let message = e.response.data.message;
        setError(message);
      })
      .finally(() => setSubmiting(false));
  }

  return (
    <Box sx={{ p: 1 }}>
      <TextField
        value={name}
        onChange={changeNameHandler}
        fullWidth
        size="small"
        variant="outlined"
        error={!!error}
        helperText={error}
      />
      <Button
        sx={{ mt: 1 }}
        size="small"
        variant="outlined"
        fullWidth
        startIcon={
          isSubmitting ? <CircularProgress size="1rem" /> : <SaveIcon />
        }
        disabled={isSubmitting}
        onClick={onSubmitHandler}
      >
        Сохранить
      </Button>
    </Box>
  );
}

function SourceList() {
  const [anchorAdd, setAnchorAdd] = useState(null);

  return (
    <Card sx={{ p: 1.5, mt: 6 }}>
      <Popover
        id={anchorAdd}
        open={!!anchorAdd}
        anchorEl={anchorAdd}
        onClose={() => setAnchorAdd(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <AddSourcePopup closeHandler={() => setAnchorAdd(null)} />
      </Popover>
      <Typography sx={{ mb: 2 }} variant="body1" gutterBottom>
        Источник
      </Typography>
      <Button
        sx={{mb: 2 }}
        size="small"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={(e) => setAnchorAdd(e.currentTarget)}
      >
        Добавить
      </Button>

        <Grid container spacing={2}>
        {SourceStore.list.map((s) => (
          <Grid item key={s._id}  xs={12} md={6} lg={6}>
            <SourceItem data={s} />
          </Grid>
        ))}
      </Grid>
    </Card>
  );
}

export default observer(SourceList);
