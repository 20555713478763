import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Select,
  MenuItem,
  Button,
  CircularProgress,
} from "@mui/material";
import { useSnackbar } from "notistack";

import SidebarWrap from "../SidebarWrap";
import InputWrap from "../InputWrap";
import ChoiceVisitDays from "../ChoiceVisitDays";
import GroupStore from "../../store/group";
import ClubStore from "../../store/club";
import groupGetFullInfo from "../../common/groupGetFullInfo";
import MembershipService from "../../services/ClientMembership";

// import getTotalSum from "./methods/getTotalSum";

export default function Edit({ closeHandler, data }) {
  const { enqueueSnackbar } = useSnackbar();
  let [isSubmiting, setSubmiting] = useState(false);
  let currentClubId = ClubStore.currentClubId;
  let groupList = [...GroupStore.list].filter((g) => g.club == currentClubId);
  let [memb, setMemb] = useState(
    data?.membership || { group: "", visitDays: {} }
  );

  function changeDataHandler(name, value) {
    let newData = Object.assign({}, memb, { [name]: value });
    setMemb(newData);
  }

  function changeToggleDay(name) {
    let oldValue = memb.visitDays[name];
    changeDataHandler(
      "visitDays",
      Object.assign({}, memb.visitDays, { [name]: !oldValue })
    );
  }

  let selectedGroup = groupList.find((g) => g._id == memb.group);

  let groupId = selectedGroup?._id;

  if (!selectedGroup) {
    changeDataHandler("group", groupList[0]._id);
  }

  useEffect(() => {
    if (groupId) {
      let r = {};
      selectedGroup.visitingDays.forEach((d) => (r[d] = true));
      changeDataHandler("visitDays", r);
    }
  }, [groupId]);

  function createHandler() {
    let visitDays = [];
    for (let v in memb.visitDays) {
      if (memb.visitDays[v]) {
        visitDays.push(v);
      }
    }

    let fetchData = {
      visitDays,
      id: memb._id,
      group: memb.group,
    };

    setSubmiting(true);
    MembershipService.edit(fetchData)
      .then((r) => {
        // let d = r.data;
        // EventBus.$emit("update_client_window");
        enqueueSnackbar("Абонемент изменён!", { variant: "success" });
        closeHandler();
      })
      .catch((e) => {
        // let errorMessage = e.response.data.message;
        // enqueueSnackbar(errorMessage, { variant: "error" });
        // console.log(e.response);
      })
      .finally(() => {
        setSubmiting(false);
      });
  }

  return (
    <SidebarWrap title="Изменить абонемент" closeHandler={closeHandler}>
      <InputWrap id="group" label="Группа" wrapStyle={{ my: 1 }}>
        <Select
          value={memb.group + ""}
          fullWidth
          id="group"
          size="small"
          onChange={(e) =>
            setMemb(
              Object.assign({}, memb, {
                group: e.target.value + "",
                visitDays: null,
              })
            )
          }
        >
          {groupList.map((d) => (
            <MenuItem key={d._id} value={d._id}>
              {groupGetFullInfo(d)}
            </MenuItem>
          ))}
        </Select>
      </InputWrap>
      <ChoiceVisitDays
        days={memb.visitDays}
        changeToggleDay={changeToggleDay}
      />
      <Button
        fullWidth
        sx={{ marginTop: 2 }}
        size="medium"
        variant="outlined"
        onClick={createHandler}
        startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
        disabled={isSubmiting}
      >
        Сохранить
      </Button>
    </SidebarWrap>
  );
}
