import React, { useState } from "react";
import {
  TextField,
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ButtonGroup,
  CircularProgress,
  Paper,
  Typography,
  IconButton,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useForm, Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import moment from "moment";
import "moment/locale/ru";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import ClientService from "../../services/Client";
import InputWrap from "../InputWrap";
import districtListData from "../../data/districtList.json";
import ChildData from "./ChildData";
import SidebarWrap from "../SidebarWrap";
import SourceStore from "../../store/source";
import { observer } from "mobx-react-lite";
import { EventBus } from "../../event";

let initInfoData = {
  birthday: moment(new Date()).utc().format().split("T")[0],
  childBirthday: moment(new Date()).utc().format().split("T")[0],
  district: districtListData[0],
  source: "",
};

function EditClient({ closeHandler, clientData }) {
  let isNew = false;
  if (!clientData) {
    isNew = true;
  } else {
    isNew = !Object.keys(clientData).length;
  }

  let sourceList = SourceStore.list;
  const { enqueueSnackbar } = useSnackbar();
  let [isSubmiting, setSubmiting] = useState(false);
  let [isChild, setIsChild] = useState(!isNew ? clientData.isChild : false);

  if (!isNew) {
    initInfoData = {
      birthday: clientData.birthday,
      childBirthday: clientData.child.birthday,
      district: clientData.district,
      source: clientData.source,
    };
  }

  let [infoData, setInfoData] = useState(initInfoData);

  console.log(infoData.birthday, 'birthday')

  function changeInfoData(name, value) {
    let newInfoData = Object.assign({}, infoData, { [name]: value });
    setInfoData(newInfoData);
  }

  if (!infoData.source && sourceList.length !== 0) {
    changeInfoData("source", sourceList[0]._id);
  }

  const {
    register,
    handleSubmit,
    control,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: !isNew ? clientData.name : "",
      surname: !isNew ? clientData.surname : "",
      patronymic: !isNew ? clientData.patronymic : "",
      phone: !isNew ? clientData.phone : "",
      childName: !isNew ? clientData.child.name : "",
      childSurname: !isNew ? clientData.child.surname : "",
      childPatronymic: !isNew ? clientData.child.patronymic : "",
    },
  });

  const onSubmit = handleSubmit((d) => {
    let phoneNumber = d.phone.replace(/\D/g, "");

    if (phoneNumber.length < 11) {
      setError("phone", {
        type: "custom",
        message: "Не верный номер телефона",
      });
      return;
    }

    let fetchData = {
      name: d.name,
      surname: d.surname,
      patronymic: d.patronymic,
      phone: phoneNumber,
      source: infoData.source,
      district: infoData.district,
      birthday: infoData.birthday,
    };

    if (isChild) {
      fetchData.isChild = isChild;
      fetchData.child = {
        name: d.childName,
        surname: d.childSurname,
        patronymic: d.childPatronymic,
        birthday: infoData.childBirthday,
      };
    }
    if (!isNew) {
      fetchData._id = clientData._id;
    }

    let fetchFunc = isNew ? ClientService.create : ClientService.change;

    setSubmiting(true);
    fetchFunc(fetchData)
      .then((r) => {
        let data = r.data;

        if (isNew) {
          // EventBus.$emit("client_list_add", data);
          enqueueSnackbar("Клиент создан!", { variant: "success" });
        } else {
          EventBus.$emit("update_client_window");
          enqueueSnackbar("Клиент изменён!", { variant: "success" });
        }
        closeHandler();
      })
      .catch((e) => {
        enqueueSnackbar("Ошибка сервера!", { variant: "error" });
      })
      .finally(() => setSubmiting(false));

    //
    // ClientService.create(fetchData)
    //   .then((res) => {
    //     let data = res.data;

    //
    //   })
  });

  return (
    <SidebarWrap
      title={isNew ? "Создание клиента" : "Изменение клиента"}
      closeHandler={closeHandler}
    >
      <ButtonGroup sx={{ my: 1 }} fullWidth variant="contained">
        <Button
          size="small"
          onClick={() => setIsChild(false)}
          variant={!isChild ? "contained" : "outlined"}
        >
          Взрослый
        </Button>
        <Button
          size="small"
          onClick={() => setIsChild(true)}
          variant={isChild ? "contained" : "outlined"}
        >
          Ребёнок
        </Button>
      </ButtonGroup>

      <form noValidate onSubmit={onSubmit}>
        <Controller
          name={"surname"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputWrap
              wrapStyle={{ my: 1 }}
              id="surname"
              label="Фамилия Клиента"
            >
              <TextField
                id="surname"
                label=""
                error={Boolean(errors.surname?.message)}
                {...register("surname", {
                  required: "Фамилия Клиента не может быть пустым",
                  minLength: {
                    message: "Мин. 2 символа",
                    value: 2,
                  },
                  maxLength: 200,
                })}
                fullWidth
                margin="normal"
                helperText={errors.surname?.message}
                sx={{ m: 0 }}
                size="small"
                variant="outlined"
                name="surname"
                type="text"
                onChange={onChange}
                value={value}
              />
            </InputWrap>
          )}
        />
        <Controller
          name={"name"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputWrap wrapStyle={{ my: 1 }} id="name" label="Имя Клиента">
              <TextField
                id="name"
                label=""
                error={Boolean(errors.name?.message)}
                {...register("name", {
                  required: "Имя Клиента не может быть пустым",
                  minLength: {
                    message: "Мин. 2 символа",
                    value: 2,
                  },
                  maxLength: 200,
                })}
                fullWidth
                margin="normal"
                helperText={errors.name?.message}
                sx={{ m: 0 }}
                size="small"
                variant="outlined"
                name="name"
                type="text"
                onChange={onChange}
                value={value}
              />
            </InputWrap>
          )}
        />

        <Controller
          name={"patronymic"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputWrap
              wrapStyle={{ my: 1 }}
              id="patronymic"
              label="Отчество Клиента"
            >
              <TextField
                id="patronymic"
                label=""
                error={Boolean(errors.patronymic?.message)}
                {...register("patronymic", {
                  required: "Отчество Клиента не может быть пустым",
                  minLength: {
                    message: "Мин. 2 символа",
                    value: 2,
                  },
                  maxLength: 200,
                })}
                fullWidth
                margin="normal"
                helperText={errors.patronymic?.message}
                sx={{ m: 0 }}
                size="small"
                variant="outlined"
                name="patronymic"
                type="text"
                onChange={onChange}
                value={value}
              />
            </InputWrap>
          )}
        />
        <InputWrap
          id="birthday"
          label="Дата рождения Клиента"
          wrapStyle={{ my: 1 }}
        >
          <LocalizationProvider dateAdapter={AdapterMoment} locale="ru">
            <DesktopDatePicker
              inputFormat="DD.MM.YYYY"
              value={infoData.birthday}
              onChange={(d) => {
                changeInfoData("birthday", d ? d.valueOf(): '');
              }}
              renderInput={(params) => (
                <TextField size="small" id="birthday" fullWidth {...params} />
              )}
            />
          </LocalizationProvider>
        </InputWrap>
        {isNew && (
          <Controller
            name={"phone"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputWrap
                wrapStyle={{ my: 1 }}
                id="self-phone"
                label="Номер телефона"
              >
                <InputMask
                  error={Boolean(errors.phone?.message)}
                  helperText={errors.phone?.message}
                  {...register("phone", {
                    required: "Введите номер телефона",
                  })}
                  required
                  label=""
                  sx={{ m: 0 }}
                  size="small"
                  variant="outlined"
                  mask="+7 (999) 999-99-99"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  name="phone"
                  type="text"
                >
                  {(e) => <TextField {...e} />}
                </InputMask>
              </InputWrap>
            )}
          />
        )}

        {isChild && (
          <ChildData
            infoData={infoData}
            setInfoData={setInfoData}
            hookFormData={{ control, errors, register }}
          />
        )}

        <InputWrap id="source" label="Источник" wrapStyle={{ mb: 2 }}>
          <Select
            value={infoData.source + ""}
            fullWidth
            size="small"
            onChange={(e) => changeInfoData("source", e.target.value)}
          >
            {sourceList.map((so) => (
              <MenuItem key={so._id} value={so._id}>
                {so.name}
              </MenuItem>
            ))}
          </Select>
        </InputWrap>
        <InputWrap id="district" label="Район" wrapStyle={{ mb: 2 }}>
          <Select
            value={infoData.district + ""}
            fullWidth
            id="district"
            size="small"
            onChange={(e) => changeInfoData("district", e.target.value)}
          >
            {districtListData.map((d) => (
              <MenuItem key={d} value={d}>
                {d}
              </MenuItem>
            ))}
          </Select>
        </InputWrap>

        <Button
          fullWidth
          sx={{ marginTop: 2 }}
          size="large"
          variant="outlined"
          type="submit"
          startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
          disabled={isSubmiting}
        >
          {isNew ? "Создать" : "Сохранить"}
        </Button>
      </form>
    </SidebarWrap>
  );
}

export default observer(EditClient);
