import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ButtonGroup,
  CircularProgress,
  Paper,
  Typography,
  Grid,
  IconButton,
  Divider,
  Tabs,
  Tab,
} from "@mui/material";
import FeedbackService from "../../../services/Feedback";
import FeedbackTable from "../../FeedbackTable/FeedbackTable";
import { socket } from "../../../socket";

export default function Feedback({ clientId }) {
  let [list, setList] = useState([]);
  useEffect(() => {
    FeedbackService.getByClient(clientId).then((r) => setList(r.data));
    socket.on("update_feedback", (feedback) => {
      updateFeedbackHandler(feedback);
    });
    return () => {
      socket.off("update_feedback");
    };
  }, []);

  function updateFeedbackHandler(feedback) {
    setList((list) => list.map((f) => (f._id == feedback._id ? feedback : f)));
  }

  console.log(list, "list");

  return (
    <Box>
      <FeedbackTable list={list} hideUser />
    </Box>
  );
}
