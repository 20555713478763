import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TrainerService from "../../services/Trainer";
import "moment/locale/ru";
import Avatar from "@mui/material/Avatar";
import { API_IMAGES } from "../../config";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

function GroupInfo({ data, changeGroup }) {
  let [trainers, setTrainers] = useState([]);
  useEffect(() => {
    const fetchTrainers = async () => {
      try {
        let res = await TrainerService.getTrainers();
        let trainers = res.data;
        setTrainers(trainers);
      } catch (e) {
        console.error(e);
      }
    };
    fetchTrainers();
  }, []);
  let [group, setGroup] = useState(data);

  function submitHandler() {
    changeGroup(group);
  }

  function changeField(e) {
    let name = e.target.name;
    let value = e.target.value;
    setGroup(Object.assign({}, group, { [name]: value }));
  }

  const changeAge = (event, newValue) => {
    let from = newValue[0];
    let to = newValue[1];
    setGroup(Object.assign({}, group, { age: { from, to } }));
  };

  function selectedTrainerHandler(e) {
    let val = e.target.value;
    console.log(val, "val");
    setGroup(Object.assign({}, group, { trainer: val }));
  }

  function dayWeekPicker() {
    let dataFormatted = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];
    dataFormatted = dataFormatted.map((d, i) => {
      return { name: d, enabled: group.classWeekDays[i + 1] };
    });

    function toggleDay(i) {
      let newClassWeekDays = Object.assign({}, group.classWeekDays);
      newClassWeekDays[i] = !newClassWeekDays[i];
      setGroup(Object.assign({}, group, { classWeekDays: newClassWeekDays }));
    }

    return (
      <Box sx={{ marginTop: 2 }}>
        {dataFormatted.map((d, i) => {
          return (
            <Button
              size="small"
              key={i}
              sx={{ margin: "5px" }}
              variant={d.enabled ? "contained" : "outlined"}
              onClick={() => toggleDay(i + 1)}
            >
              {d.name}
            </Button>
          );
        })}
      </Box>
    );
  }

  return (
    <>
      <TextField
        value={group.name}
        fullWidth
        margin="dense"
        label="Название"
        size="small"
        variant="filled"
        name="name"
        type="string"
        onChange={changeField}
      />
      <FormControl fullWidth sx={{ minWidth: 80, marginTop: 2 }}>
        <InputLabel>Тренер</InputLabel>
        <Select
          // labelId="demo-simple-select-autowidth-label"
          // id="demo-simple-select-autowidth"
          value={group.trainer}
          onChange={selectedTrainerHandler}
          // sx={{ backgroundColor: "red" }}
          fullWidth
          label="Тренер"
        >
          {trainers.map((t) => {
            return (
              <MenuItem value={t._id} key={t._id}>
                <TrainerCard name={t.name} img={t.photo} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <TextField
        sx={{ marginTop: 2 }}
        value={group.maxPlaces}
        fullWidth
        margin="dense"
        label="Мест"
        size="small"
        variant="filled"
        name="maxPlaces"
        type="number"
        onChange={changeField}
      />
      <Box sx={{ marginTop: 2 }}>
        <Typography variant="h6" component="h6">
          Возраст
        </Typography>
        <Box
          sx={{
            display: "flex",
            alingItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Typography variant="subtitle1" component="h6">
            От: {group.age.from}
          </Typography>
          <Typography variant="subtitle1" component="h6">
            До: {group.age.to}
          </Typography>
        </Box>
        <Slider
          getAriaLabel={() => "Возраст"}
          value={[group.age.from, group.age.to]}
          onChange={changeAge}
          step={1}
          valueLabelDisplay="off"
          marks
          min={5}
          max={30}
          getAriaValueText={() => {}}
        />
      </Box>
      {dayWeekPicker()}

      <LocalizationProvider dateAdapter={AdapterMoment} locale="ru">
        <TimePicker
          label="Начало занятия"
          value={group.startTime}
          onChange={(newValue) =>
            setGroup(Object.assign({}, group, { startTime: newValue }))
          }
          renderInput={(params) => (
            <TextField sx={{ marginTop: 2, width: "100%" }} {...params} />
          )}
        />
      </LocalizationProvider>

      <TextField
        value={group.desc}
        onChange={changeField}
        fullWidth
        margin="dense"
        name="desc"
        label="О группе"
        multiline
        rows={10}
        variant="filled"
      />

      <Button
        fullWidth
        sx={{ marginTop: 2 }}
        size="large"
        variant="outlined"
        type="submit"
        onClick={submitHandler}
        // disabled={this.props.loading}
      >
        Сохранить
      </Button>
    </>
  );
}

function TrainerCard({ img, name }) {
  const imageUrl = API_IMAGES + img;
  return (
    <Box
      sx={{
        padding: 1,
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <Avatar sx={{ marginRight: 1 }} alt={name} src={imageUrl} />
      <Typography variant="subtitle1" component="p">
        {name}
      </Typography>
    </Box>
  );
}

export default GroupInfo;
