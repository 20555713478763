import {
  Box,
  Typography,
  Container,
  Button,
  Grid,
  Popover,
  Card,
  TextField,
  InputAdornment,
  styled,
  Select,
  MenuItem,
} from "@mui/material";

import InputWrap from "../../components/InputWrap";
import moment from "moment";
import "moment/locale/ru";

// Icons
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

// Utils
import getDateRangeText from "../../common/getDateRangeText";
import SearchIcon from "@mui/icons-material/Search";


function Filter({
  categories,
  category,
  setCategory,
  period,
  setPeriod,
  openPopover,
  findHandler
}) {
  let categoryList = [
    {
      name: "Все",
      _id: "",
    },

    ...categories,
  ];

  return (
    <Card sx={{ p: 1, mb: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={6} md={4}>
          <InputWrap id="category" label="Категория">
            <Select
              value={category + ""}
              fullWidth
              id="month"
              size="small"
              onChange={(e) => {
                console.log(e.target.value, "val");
                setCategory(e.target.value);
              }}
            >
              {categoryList.map((d) => (
                <MenuItem key={d._id} value={d._id}>
                  {d.name}
                </MenuItem>
              ))}
            </Select>
          </InputWrap>
        </Grid>
        <Grid item xs={6} md={4}>
          <InputWrap id="period" label="Период">
            <TextField
              id="period"
              label=""
              fullWidth
              sx={{ m: 0 }}
              size="small"
              variant="outlined"
              name="period"
              onClick={openPopover}
              type="text"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarMonthIcon />
                  </InputAdornment>
                ),
              }}
              value={getDateRangeText(period.from, period.to)}
            />
          </InputWrap>
        </Grid>
      </Grid>

      <Button
        variant="contained"
        size="small"
        sx={{mt: 2}}
        startIcon={<SearchIcon />}
        onClick={findHandler}
      >
        Применить
      </Button>
    </Card>
  );
}

export default Filter;
