import axios from "axios";

class ClientMembership {
  getByClient(id) {
    return axios.get(`/membership/by-client`, { params: { id } });
  }
  getRecentPurchases(data) {
    return axios.get(`/membership/recent-purchases`, { params: data });
  }
  renew(data) {
    return axios.post(`/membership/renew`, data);
  }
  create(data) {
    return axios.post(`/membership`, data);
  }

  edit(data) {
    return axios.put(`/membership/edit`, data);
  }
  changeStage(data) {
    return axios.put(`/membership/stage`, data);
  }
  changeMonth(data) {
    return axios.put(`/membership/month`, data);
  }
}

export default new ClientMembership({});
