import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import GroupService from "../../services/Group";
import Typography from "@mui/material/Typography";
import GroupInfo from "./GroupInfo";
import { useSnackbar } from "notistack";
import { observer } from "mobx-react-lite";


function Group() {
  const { enqueueSnackbar } = useSnackbar();
  let [loading, setLoading] = useState(true);
  let [group, setGroup] = useState(null);
  
  let { id } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      try {
        let res = await GroupService.getOne(id);
        setGroup(res.data);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  async function changeGroup(data) {
    try {
      let res = await GroupService.change(id, data);
      const newGroup = res.data;
      setGroup(newGroup);
      enqueueSnackbar("Группа измененна!", { variant: "success" });
    } catch (e) {
      console.error(e);
    }
  }

  if (loading) return <Loader />;

  return (
    <Container sx={{ marginTop: "20px" }} maxWidth="lg">
      <Typography align="center" variant="h4" gutterBottom component="div">
        {group.name}
      </Typography>
      <Paper sx={{ padding: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <GroupInfo changeGroup={changeGroup} data={group} />
          </Grid>
          <Grid item xs={12} sm={6} md={8} lg={8}>
            users
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

export default observer(Group);
