import axios from "axios";

class Question {
  getAll() {
    return axios.get(`/question`);
  }
  create(data) {
    return axios.post(`/question`, data);
  }
  delete(id) {
    return axios.delete(`/question/${id}`);
  }
}

export default new Question({});
