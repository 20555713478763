import getCalculateAmountVisit from "../../../common/getCalculateAmountVisit";
import clubStore from "../../../store/club";
export default function getTotalSum(startVisit, days, groupClubId, hd, ds) {
  console.log({groupClubId})
  let clubs = clubStore.list;
  let club = clubs.find((c) => c._id == groupClubId);

  let visitDays = [];
  let enabledAmountDays = 0;
  for (let i in days) {
    if (days[i]) {
      visitDays.push(+i);
      enabledAmountDays += 1;
    }
  }

  let oneLessonSum = club.prices.membership[enabledAmountDays - 1];

  let countVisitDays = getCalculateAmountVisit(
    new Date(startVisit),
    new Date(
      new Date(startVisit).getFullYear(),
      new Date(startVisit).getMonth() + 1,
      0
    ),
    visitDays
  );

  let r = oneLessonSum * countVisitDays;

  if (hd) {
    r -= ds;
  }

  return r;
}
