import React, { useEffect, useState } from "react";
import {
  TextField,
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ButtonGroup,
  CircularProgress,
  Paper,
  Typography,
  Grid,
  IconButton,
  Divider,
  Modal,
} from "@mui/material";
import { EventBus } from "../../event";
import CloseIcon from "@mui/icons-material/Close";
import PushNotify from "./PushNotify";
import ShowFeedback from "./ShowFeedback";
import ShowPush from "./ShowPush";
import SmsSend from "./SmsSend";
import ShowSms from "./ShowSms";
import TelegramSend from "./TelegramSend";
import ShowTelegram from "./ShowTelegram";


export default function ClientAction() {
  const [actionOpen, setActionOpen] = useState("");
  const [actionData, setActionData] = useState(null);

  useEffect(() => {
    EventBus.$on("modal_client_action", (d) => {
      setActionOpen(d.action);
      setActionData(d.data);
    });
    return () => {
      EventBus.$off("modal_client_action");
    };
  }, []);

  function handleCloseAction() {
    setActionOpen("");
  }

  function view() {
    switch (actionOpen) {
      case "push-notify":
        return (
          <PushNotify data={actionData} closeHandler={handleCloseAction} />
        );
      case "show-feedback":
        return (
          <ShowFeedback data={actionData} closeHandler={handleCloseAction} />
        );
      case "show-push":
        return <ShowPush data={actionData} closeHandler={handleCloseAction} />;

      case "sms-send":
        return <SmsSend data={actionData} closeHandler={handleCloseAction} />;
      case "show-sms":
        return <ShowSms data={actionData} closeHandler={handleCloseAction} />;
      case "telegram-send":
        return (
          <TelegramSend data={actionData} closeHandler={handleCloseAction} />
        );
      case "show-telegram":
        return <ShowTelegram data={actionData} closeHandler={handleCloseAction} />;

      default:
        return "";
    }
  }

  return (
    <Modal open={!!actionOpen} onClose={handleCloseAction}>
      <Paper sx={style}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography variant="h6" component="h2">
            {getTitle(actionOpen)}
          </Typography>
          <IconButton sx={{ m: 0, ml: 2 }} onClick={handleCloseAction}>
            <CloseIcon />
          </IconButton>
        </Box>
        {view()}
      </Paper>
    </Modal>
  );
}

function getTitle(action_name) {
  switch (action_name) {
    case "push-notify":
      return "Пуш уведомление";
    case "show-feedback":
      return "Обратная связь";
    case "show-push":
      return "Статус Пуш-уведомления";
    case "sms-send":
      return "Отправить SMS-сообщение";
    case "telegram-send":
      return "Отправить Telegram-сообщение";
    case "show-sms":
      return "SMS-сообщение";
    case "show-telegram":
      return "Telegram-сообщение";
    default:
      return "";
  }
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 1,
  minWidth: { xs: "90vw", sm: "500px" },
};
