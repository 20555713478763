import { makeObservable, makeAutoObservable, observable, action } from "mobx";

class Club {
  list = [];
  currentClubId = localStorage.getItem("club") || "";

  constructor() {
    makeObservable(this, {
      currentClubId: observable,
      list: observable,
      setCurrentClubId: action,
      setList: action,
      addItem: action,
    });
  }

  setCurrentClubId(id) {
    localStorage.setItem("club", id);
    this.currentClubId = id;
  }
  setList(data) {
    this.list = data;
  }
  addItem(data) {
    this.list = [data, ...this.list];
  }
  changeItem(data) {
    this.list = this.list.map((i) => (i._id == data._id ? data : i));
  }
}

const clubStore = new Club();

export default clubStore;
