import { useState } from "react";
import {
  Box,
  Modal,
  Typography,
  Card,
  List,
  ListItem,
  Button,
  CircularProgress,
  ListItemText,
  TextField,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import InputWrap from "../InputWrap";
import { useSnackbar } from "notistack";

import ClientService from "../../services/Client";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "2px solid #000",
  boxShadow: 24,
  p: 1.5,
};
export default function AdditionalNumbersModalAdd({
  open,
  handleClose,
  clientId,
  numbers,
  setNumbers,
}) {
  const { enqueueSnackbar } = useSnackbar();

  let [isSubmiting, setSubmiting] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      phone: "",
    },
  });

  const onSubmit = handleSubmit((d) => {
    let phoneNumber = d.phone.replace(/\D/g, "");

    if (phoneNumber.length != 11) {
      setError("phone", {
        message: "Не верный номер телефона!",
      });
      return;
    }

    setSubmiting(true);

    ClientService.changeAddedNumbers({
      action: "add",
      phone: phoneNumber,
      id: clientId,
    })
      .then(() => {
        setNumbers([phoneNumber, ...numbers]);
        enqueueSnackbar("Номер добавлен!", { variant: "success" });
        handleClose();
      })
      .catch((e) => {
        let message = e?.response?.data?.message;
        if (message) {
          enqueueSnackbar(message, { variant: "error" });
        }
      })
      .finally(() => setSubmiting(false));
  });

  return (
    <Modal open={open} onClose={handleClose}>
      <Card sx={style}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Добавить номер
        </Typography>

        <Box noValidate component="form" onSubmit={onSubmit}>
          <Controller
            name={"phone"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputWrap wrapStyle={{ my: 1 }} label="Номер телефона">
                <InputMask
                  error={Boolean(errors.phone?.message)}
                  helperText={errors.phone?.message}
                  {...register("phone", {
                    required: "Введите номер телефона",
                  })}
                  required
                  label=""
                  sx={{ m: 0 }}
                  size="small"
                  variant="outlined"
                  mask="+7 (999) 999-99-99"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  name="phone"
                  type="text"
                >
                  {(e) => <TextField {...e} />}
                </InputMask>
              </InputWrap>
            )}
          />
          <Button
            fullWidth
            sx={{ mt: 2 }}
            size="small"
            variant="contained"
            type="submit"
            startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmiting}
          >
            Добавить
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}
