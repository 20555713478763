import React, {useState, useEffect} from "react";
import {
  TextField,
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ButtonGroup,
  Tab,
  Tabs,
  CircularProgress,
  Paper,
  Typography,
  Grid,
  IconButton,
  Divider,
  CardActionArea,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  // makeStyles,
} from "@mui/material";
import getDayNameByNumer from "../../../common/getDayNameByNumer";
import groupGetFullInfo from "../../../common/groupGetFullInfo";

import firstLetterUppercase from "../../../common/firstLetterUppercase";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import moment from "moment";
import "moment/locale/ru";
import TrainerStore from "../../../store/trainer";
import GroupStore from "../../../store/group";
import SelectMonth from "../../../components/FiltersUI/SelectMonth";
import ClientMembershipService from "../../../services/ClientMembership";
import { useSnackbar } from "notistack";

// import

export default function MembershipCard({ data }) {
  const { enqueueSnackbar } = useSnackbar();
  console.log(data, "data");
  let trainerList = TrainerStore.list;
  let groupList = GroupStore.list;

  let group = groupList.find((g) => g._id == data.group);
  let [monthData, setMonthData] = useState(moment(data.month).startOf("month").valueOf())
  useEffect(() => {
    console.log(data)
    console.log(monthData)
    if (moment(data.month).startOf("month").valueOf() !== monthData) {
      console.log('change month')
      ClientMembershipService.changeMonth({id:data._id,month:monthData}).then(()=>{
        console.log('success')
        enqueueSnackbar("Абонемент изменён!", { variant: "success" });
      })
    }
  }, [monthData]);
  if (!group) return null;
  console.log(group, 'group card member')

  let trainer = trainerList.find((t) => t._id == group.trainer);

  let monthName = firstLetterUppercase(moment(data.month).format("MMMM YYYY"));




  function generateMonthList() {
    let result = [];
    let currentDate = moment();

    let max = 4;

    for (let p = -1; p <= max; p++) {
      let month = moment(currentDate).add(p, "month").startOf("month");

      let name = month.format("MMMM");
      name = name.charAt(0).toUpperCase() + name.slice(1);
      result.push({
        num: month.valueOf(),
        name,
      });
    }
    return result;
  }

  let monthList = generateMonthList();




  return (
    <Accordion sx={{ mb: 1 }}>
      <AccordionSummary disableGutters={true} expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">{`${group.name} (${trainer.name}) | ${monthName} | ${data.paymentAmount} руб.`}</Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Typography sx={{ mt: 1 }} variant="body1">
          {group.name} |
          {data.visitDays.map((g) => getDayNameByNumer(+g)).join(",")} |
          {group.visitingTime}
        </Typography>
        <Typography sx={{ mt: 1 }} variant="body1">
          {trainer.name}
        </Typography>
        <Typography sx={{ mt: 1 }} variant="body1" fontWeight="medium">
          <div style={{width:'300px'}}>
            <SelectMonth
                date={monthData}
                setDate={(d) => setMonthData(d)}
            />
          </div>
        </Typography>
        <Typography sx={{ mt: 1 }} variant="body1" fontWeight="medium">
          {data.paymentAmount} руб.
        </Typography>

        <Typography sx={{ mt: 1 }} variant="body1" fontWeight="medium">
          Дата оплаты:
          {moment(data.datePayment).format("DD.MMMM.YYYY ,в HH:mm")}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );

}
