export default function getClientStatusName(s) {
  switch (s) {
    case "new":
      return "Новый";
    case "open":
      return "Открыт";
    case "close":
      return "Закрыт";
    case "block":
      return "Заблокирован";
  }
}
