import { makeObservable, observable, action, computed } from "mobx";

class FunnelStage {
  list = [];
  constructor() {
    makeObservable(this, {
      list: observable,
      setStageList: action,
      addStage: action,
      changeStage: action,
      orderStages: computed,
      membershipStages: computed,
      deleteStage: action,
    });
  }
  get orderStages() {
    return this.list
      .filter((s) => s.mode == "order")
      .sort((a, b) => a.displayOrder - b.displayOrder);
  }
  get membershipStages() {
    return this.list
      .filter((s) => s.mode == "membership")
      .sort((a, b) => a.displayOrder - b.displayOrder);
  }
  setStageList(data) {
    this.list = data;
  }
  addStage(s) {
    this.list = [s, ...this.list];
  }
  changeStage(st) {
    this.list = this.list.map((s) => {
      if (st._id == s._id) {
        return st;
      } else {
        return s;
      }
    });
  }
  deleteStage(id) {
    this.list = this.list.filter((s) => s._id !== id);
  }
}

const funnelStageStore = new FunnelStage();

export default funnelStageStore;
