import axios from "axios";

class News {
  add(data) {
    return axios.post(`/news`, data);
  }
  getAll() {
    return axios.get(`/news`);
  }
  delete(id) {
    return axios.delete(`/news`, { data: { id } });
  }
  changeNews(data) {
    return axios.put(`/news`, data);
  }
  // deleteNews(newsId) {
  //   return axios.delete(`/news`, { data: { newsId } });
  // }
}

export default new News({});
