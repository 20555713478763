import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import ClientMembershipService from "../../services/ClientMembership";
import NewSellMembership from "./NewSellMembership";
import RenewSellMembership from "./RenewSellMembershipc";


export default function SellMembership({ closeHandler, data }) {
  let [d, setD] = useState(data);
  let [mode, setMode] = useState(null);
  let [memberships, setMemberships] = useState([]);
  console.log(d, 'd')

  useEffect(() => {
    console.log("use effetct");
    ClientMembershipService.getByClient(data.clientId).then((r) => {
      let d = r.data.filter((m) => m.isPayment);
      setMemberships(r.data.filter((m) => m.isPayment));
      let mode = "";
      if (d.length == 0) {
        mode = "new";
      } else {
        mode = "renew";
      }
      setMode(mode);
    });
  }, []);

 

  function viewSection() {
    switch (mode) {
      case "new":
        return (
          <NewSellMembership
            closeHandler={closeHandler}
            clientId={d.clientId}
          />
        );
      case "renew":
        return (
          <RenewSellMembership
          memberships={memberships}
            closeHandler={closeHandler}
            clientId={d.clientId}
            balance={d.balance}
          />
        );
      default:
        return null;
    }
  }

  function getTitle(mode) {
    switch (mode) {
      case "new":
        return "Продажа абонемента";
      case "renew":
        return "Продление абонемента";
      default:
        break;
    }
  }

  return (
    <Box
      sx={{
        minWidth: { xs: "100vw", sm: "500px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: "10px",
        }}
      >
        <Typography variant="h6"> {getTitle(mode)} </Typography>
        <IconButton sx={{ m: 0 }} onClick={closeHandler}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ p: "10px" }}>{viewSection()}</Box>
    </Box>
  );
}
