import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Card,
  TableContainer,
  Grid,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  TableHead,
} from "@mui/material";
import TrainerWageService from "../../services/TrainerWage";
import SaveIcon from "@mui/icons-material/Save";
import { useSnackbar } from "notistack";
import TrainerStore from "../../store/trainer";

function TrainerWageCard({ trainer, wage }) {
  let [percent, setPercent] = useState(wage.percent);
  const { enqueueSnackbar } = useSnackbar();

  function saveDataHandler() {
    // saveDataHandler
    TrainerWageService.change(Object.assign({}, wage, { percent })).then(
      (r) => {
        enqueueSnackbar("Процент изменён!", { variant: "success" });
      }
    );
  }

  return (
    <Card
      sx={{
        display: "flex",
        p: 1,
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <Typography variant="h6">{trainer.name}</Typography>
      <Box sx={{ minWidth: "max-content" }}>
        <TextField
          value={percent}
          onChange={(e) => setPercent(e.target.value)}
          sx={{ maxWidth: "70px" }}
          size="small"
          variant="outlined"
          type="number"
        />
        <IconButton onClick={saveDataHandler}>
          <SaveIcon />
        </IconButton>
      </Box>
    </Card>
  );
}

export default function TrainerWage() {
  let [list, setList] = useState([]);
  let trainerList = TrainerStore.list;

  useEffect(() => {
    TrainerWageService.getAll().then((r) => setList(r.data));
  }, []);

  if (!list.length) return;
  return (
    <Card sx={{ p: 1.5, mt: 6 }}>
      <Typography sx={{ mb: 4 }} variant="body1" gutterBottom>
        Процент ЗП
      </Typography>

      {/* <Grid */}

      <Grid spacing={2} container>
        {trainerList.map((t) => (
          <Grid item xs={12} md={12}>
            <TrainerWageCard
              trainer={t}
              wage={list.find((w) => w.trainer == t._id)}
            />
          </Grid>
        ))}
      </Grid>

      {/*  */}
    </Card>
  );
}
