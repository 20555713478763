import React from "react";
import { Box, Typography, Alert } from "@mui/material";
import UploadImage from "../UploadImage/UploadImage";

export default function Preview({ setImage, url, error, setError }) {
  function changeImageHandler(img) {
    setError("");
    setImage(img);
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Фото
      </Typography>
      {error && (
        <Alert severity="error" sx={{ mb: 1 }}>
          {error}
        </Alert>
      )}

      <UploadImage url={url} setImage={changeImageHandler} />
    </Box>
  );
}
