import React, { useState, useCallback, useEffect } from "react";
import { Box } from "@mui/material";
import { EventBus } from "../event";
import IV from "react-simple-image-viewer";

function ImageViewer(props) {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    EventBus.$on("show_image_viewer", (images) => {
      setImages(images);
      setIsViewerOpen(true);
    });
    return () => {
      EventBus.$off("show_image_viewer");
    };
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <Box sx={{ position: "relative" }}>
      {isViewerOpen && (
        <Box
          sx={{ position: "absolute", zIndex: 10000000, top: 0, bottom: 0, left: 0, right: 0 }}
        >
          <IV
            src={images}
            currentIndex={currentImage}
            onClose={closeImageViewer}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)",
            }}
            closeOnClickOutside={true}
          />
        </Box>
      )}
    </Box>
  );
}

export default ImageViewer;
