import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Card,
  TableContainer,
  Grid,
  TableRow,
  TableCell,
  Button,
  Paper,
  Dialog,
  CircularProgress,
  TableBody,
  TableHead,
  ListItem,
  List,
  ListItemText,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import InputWrap from "../../components/InputWrap";
import { useSnackbar } from "notistack";
import moment from "moment";
import "moment/locale/ru";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import CostService from "../../services/Cost";

import { useForm, Controller } from "react-hook-form";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

// function CostCategory() {
//   const { enqueueSnackbar } = useSnackbar();
//   const [showAdd, setShowAdd] = useState(false);
//   const [changeData, setChangeData] = useState(null);
//   const [list, setList] = useState([]);

//   useEffect(() => {
//     CostService.getAllCategory().then((r) => setList(r.data));
//   }, []);

//   function addCategory(category) {
//     setList((l) => [category, ...l]);
//   }
//   function deleteCategory(id) {
//     CostService.deleteCategory(id).then((r) => {
//       setList((l) => l.filter((i) => i._id !== id));
//       enqueueSnackbar("Категория удалена!", { variant: "success" });
//     });
//   }
//   function changeCategory(data) {
//     setList((l) => l.map((i) => (i._id == data._id ? data : i)));
//     enqueueSnackbar("Категория измененна!", { variant: "success" });
//   }

//   const toggleShowAdd = () => {
//     setShowAdd(!showAdd);
//     setChangeData(null);
//   };
//   return (
//     <Card sx={{ p: 1.5, mt: 6 }}>
//       <AddCoastCategory
//         data={changeData}
//         addCategory={addCategory}
//         show={showAdd}
//         toggleShowAdd={toggleShowAdd}
//         changeCategory={changeCategory}
//       />
//       <Typography sx={{ mb: 2 }} variant="body1" gutterBottom>
//         Категории расходов
//       </Typography>
//       <Button
//         sx={{ mb: 2 }}
//         size="small"
//         variant="contained"
//         startIcon={<AddIcon />}
//         onClick={toggleShowAdd}
//       >
//         Добавить
//       </Button>
//       <List>
//         {list.map((c) => {
//           return (
//             <ListItem
//               secondaryAction={
//                 <Box>
//                   <IconButton
//                     onClick={() => {
//                       setChangeData(c);
//                       setShowAdd(true);
//                     }}
//                     aria-label="comment"
//                   >
//                     <EditIcon />
//                   </IconButton>
//                   <IconButton
//                     onClick={() => deleteCategory(c._id)}
//                     aria-label="comment"
//                   >
//                     <DeleteIcon />
//                   </IconButton>
//                 </Box>
//               }
//             >
//               <ListItemText>{c.name}</ListItemText>
//             </ListItem>
//           );
//         })}
//       </List>
//     </Card>
//   );
// }

function AddCost({ show, toggleShowAdd, addCategory, addHandler, categories }) {
  const [category, setCategory] = useState(null);
  const [date, setDate] = useState(moment().format("DD.MM.YYYY"));

  const { enqueueSnackbar } = useSnackbar();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: "",
      sum: 0,
    },
  });

  const onSubmit = handleSubmit((d) => {
    CostService.addCost({
      name: d.name,
      sum: d.sum,
      category,
      date,
    }).then((r) => {
      addHandler(r.data);
      enqueueSnackbar("Расход добавлен!", { variant: "success" });
      toggleShowAdd();
    });
  });

  return (
    <Box>
      <Dialog
        open={show}
        onClose={toggleShowAdd}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{ p: 1.5, mb: 2 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" gutterBottom>
              Добавить расход
            </Typography>
            <IconButton sx={{ ml: 3 }} onClick={toggleShowAdd}>
              <CloseIcon />
            </IconButton>
          </Box>

          <form noValidate onSubmit={onSubmit}>
            <Controller
              name={"name"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputWrap wrapStyle={{ my: 1 }} id="name" label="Название">
                  <TextField
                    id="surname"
                    label=""
                    error={Boolean(errors.name?.message)}
                    {...register("name", {
                      required: "Название не может быть пустым",
                      minLength: {
                        message: "Мин. 4 символов",
                        value: 4,
                      },
                      maxLength: 200,
                    })}
                    fullWidth
                    margin="normal"
                    helperText={errors.name?.message}
                    sx={{ m: 0 }}
                    size="small"
                    variant="outlined"
                    name="name"
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                </InputWrap>
              )}
            />
            <Controller
              name={"sum"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputWrap wrapStyle={{ my: 1 }} id="sum" label="Сумма">
                  <TextField
                    id="sum"
                    label=""
                    error={Boolean(errors.sum?.message)}
                    {...register("sum", {
                      required: "Сумма обязательна!",
                      min: {
                        value: 1,
                        message: "Минимум 1 рубль.",
                      },
                    })}
                    fullWidth
                    margin="normal"
                    helperText={errors.sum?.message}
                    sx={{ m: 0 }}
                    size="small"
                    variant="outlined"
                    name="sum"
                    type="number"
                    onChange={onChange}
                    value={value}
                  />
                </InputWrap>
              )}
            />
            {categories.length && (
              <InputWrap id="category" label="Категория" wrapStyle={{ my: 1 }}>
                <Select
                  fullWidth
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={category}
                  label=""
                  defaultValue={categories[0]._id}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  {categories.map((c) => (
                    <MenuItem value={c._id}>{c.name}</MenuItem>
                  ))}
                </Select>
              </InputWrap>
            )}
            <InputWrap id="birthday" label="Дата" wrapStyle={{ my: 1 }}>
              <LocalizationProvider dateAdapter={AdapterMoment} locale="ru">
                <DesktopDatePicker
                  inputFormat="DD.MM.YYYY"
                  value={date}
                  onChange={(d) => {
                    setDate(d.valueOf());
                  }}
                  renderInput={(params) => (
                    <TextField size="small" id="date" fullWidth {...params} />
                  )}
                />
              </LocalizationProvider>
            </InputWrap>

            <Button fullWidth sx={{ mt: 2 }} variant="contained" type="submit">
              Добавить
            </Button>
          </form>
        </Box>
      </Dialog>
    </Box>
  );
}

export default AddCost;
