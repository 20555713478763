import { makeObservable, observable, action } from "mobx";
import sortGroupByVisitTime from "../common/sortGroupByVisitTime";

class Group {
  list = [];
  constructor() {
    makeObservable(this, {
      list: observable,
      setGroupList: action,
      addGroup: action,
      changeGroup: action,
    });
  }
  setGroupList(data) {
    this.list = sortGroupByVisitTime(data);
  }
  addGroup(group) {
    this.list = [group, ...this.list];
  }
  changeGroup(group) {
    this.list = this.list.map((g) => {
      if (group._id == g._id) {
        return group;
      } else {
        return g;
      }
    });
  }
}

const groupStore = new Group();

export default groupStore;
