import React from "react";
import {
  Box,
  TextField,
  InputLabel,
  Typography,
  IconButton,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import InputWrap from "./InputWrap";
import getDayNameByNumer from "../common/getDayNameByNumer";

export default function ChoiceVisitDays({ days, changeToggleDay }) {
  console.log(days, "days");
  // let []

  let generatedBtns = [];

  for (let d in days) {
    let name = d;

    let value = +days[d];

    generatedBtns.push({
      num: name,
      name: getDayNameByNumer(+name),
      isActive: value,
    });
  }

  return (
    <Box>
      <InputWrap id="visitDays" label="Дни посещения">
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {generatedBtns.map((d) => {
            return (
              <Button
                onClick={() => changeToggleDay(d.num)}
                sx={{ mr: 1 }}
                variant={d.isActive ? "contained" : "outlined"}
                key={d.num}
              >
                {d.name}
              </Button>
            );
          })}
        </Box>
      </InputWrap>
    </Box>
  );
}
