import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Typography,
  CircularProgress,
  SwipeableDrawer,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Tabs,
  Tab,
} from "@mui/material";
import FeedbackService from "../../../services/Feedback";
import { socket } from "../../../socket";
import FeedbackTable from "../../../components/FeedbackTable/FeedbackTable";

// Store
import ClubStore from "../../../store/club";
import { observer } from "mobx-react-lite";

function Feedback() {
  let clubId = ClubStore.currentClubId;
  let [list, setList] = useState([]);
  useEffect(() => {
    socket.on("update_feedback", (feedback) => {
      updateFeedbackHandler(feedback);
    });
    return () => {
      socket.off("update_feedback");
    };
  }, []);

  useEffect(() => {
    FeedbackService.getAll().then((r) => setList(r.data));
  }, [clubId]);

  function updateFeedbackHandler(feedback) {
    setList((list) => list.map((f) => (f._id == feedback._id ? feedback : f)));
  }

  return (
    <Box>
      <FeedbackTable list={list} />
    </Box>
  );
}

export default observer(Feedback);
