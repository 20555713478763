import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  InputLabel,
  CircularProgress,
  Typography,
  IconButton,
  Select,
  Checkbox,
  FormControlLabel,
  MenuItem,
  ButtonGroup,
  Button,
  Grid,
} from "@mui/material";
import { useSnackbar } from "notistack";
import ChoiceVisitDays from "../ChoiceVisitDays";
import GroupService from "../../services/Group";
import getDayNameByNumer from "../../common/getDayNameByNumer";
import groupGetFullInfo from "../../common/groupGetFullInfo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import InputWrap from "../InputWrap";
import moment from "moment";
import "moment/locale/ru";
import CalculateSum from "./CalculateSum";
import ClientMembership from "../../services/ClientMembership";
// import getTotalSum from "./methods/getTotalSum";

import { EventBus } from "../../event";

import ClubStore from "../../store/club";
import GroupStore from "../../store/group";
import TrainerStore from "../../store/trainer";
import Discount from "./Discount";

import getCalculateAmountVisit from "../../common/getCalculateAmountVisit";

// let monthList = [

// ]

function generateMonthList() {
  let result = [];
  let currentDate = moment();

  let max = 4;

  for (let p = -1; p <= max; p++) {
    let month = moment(currentDate).add(p, "month").startOf("month");

    let name = month.format("MMMM");
    name = name.charAt(0).toUpperCase() + name.slice(1);
    result.push({
      num: month.valueOf(),
      name,
    });
  }
  return result;
}

export default function RenewSellMembership({
  clientId,
  balance,
  closeHandler,
  memberships,
}) {
  // States
  let [asBonus, setAsBonus] = useState(false);
  let [notFullMonth, setNotFullMonth] = useState(false);
  let [isRecalc, setIsRecalc] = useState(false);

  let [hasDiscount, setHasDiscount] = useState(false);
  let [discountSum, setDiscountSum] = useState(0);

  let [isSubmiting, setSubmiting] = useState(false);
  let [data, setData] = useState({
    group: null,
    visitDays: null,
    bonuses: 0,
    startVisit: "",
    startRecalc: "",
    endRecalc: "",
  });

  console.log(memberships, "memberships");

  // Handlers
  function changeDataHandler(name, value) {
    console.log({ name, value });
    let updObj = {
      [name]: value,
    };

    if (name == "startRecalc") {
      updObj.endRecalc = moment(data.endRecalc)
        .set("month", moment(value).get("month"))
        .valueOf();
    } else if (name == "endRecalc") {
      updObj.startRecalc = moment(data.startRecalc)
        .set("month", moment(value).get("month"))
        .valueOf();
    }

    if (name == "group") {
      updObj.recalcGroup = value;
    }

    if (name == "startVisit") {
      updObj.startRecalc = moment(value)
        .subtract(1, "month")
        .startOf("month")
        .valueOf();
      updObj.endRecalc = moment(value)
        .subtract(1, "month")
        .endOf("month")
        .valueOf();
    }

    let newData = Object.assign({}, data, updObj);
    setData(newData);
  }

  function changeToggleDay(name) {
    let oldValue = data.visitDays[name];
    changeDataHandler(
      "visitDays",
      Object.assign({}, data.visitDays, { [name]: !oldValue })
    );
  }

  function bonusesBlur() {
    if (data.bonuses >= balance) {
      changeDataHandler("bonuses", balance);
    }
  }

  // Calc
  let currentClubId = ClubStore.currentClubId;
  let groups = [...GroupStore.list].filter((g) => g.club == currentClubId);

  let selectedGroup = groups.find((g) => g._id == data.group);

  if (!selectedGroup) {
    selectedGroup = groups[0];
    changeDataHandler("group", groups[0]._id);
  }

  let groupId = selectedGroup?._id;
  let groupClubId = selectedGroup?.club;

  let monthList = generateMonthList();
  if (!data.startVisit) {
    changeDataHandler("startVisit", monthList[0].num);
  }

  let recalcMembership;
  if (data.startRecalc) {
    recalcMembership = memberships.find((m) => {
      return (
        moment(m.month).startOf("month").valueOf() ==
        moment(data.startRecalc).startOf("month").valueOf()
      );
    });
  }

  let recalcGroup = groups.find((g) => g._id == recalcMembership?.group);

  // Hooks
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (groupId) {
      let r = {};
      selectedGroup.visitingDays.forEach((d) => (r[d] = true));
      changeDataHandler("visitDays", r);
    }
  }, [groupId]);

  let recalcSum =
    isRecalc && recalcMembership && recalcGroup
      ? getTotalRecalcSum(
          data.startRecalc,
          data.endRecalc,
          recalcMembership.visitDays,
          recalcGroup.club
        )
      : 0;

  let totalResult = getTotalSum(
    data.startVisit,
    moment(data.startVisit).endOf("month").valueOf(),
    data.visitDays,
    groupClubId,
    hasDiscount ? discountSum : 0
  );

  let totalSum = totalResult.result;
  // let recalcSum = isRecalc ? totalResult.recalcs : 0;
  totalSum = data.bonuses ? totalSum - data.bonuses : totalSum;

  totalSum = recalcSum ? totalSum - recalcSum : totalSum;

  function createHandler() {
    let visitDays = [];
    for (let v in data.visitDays) {
      if (data.visitDays[v]) {
        visitDays.push(v);
      }
    }
    let fetchData = Object.assign({}, data, {
      client: clientId,
      visitDays,
      totalSum: !isRecalc ? totalResult.result : totalSum,
      asBonus: asBonus && totalSum < 3200,
      notFullMonth,
      isRecalc,
      recalcSum,
    });

    setSubmiting(true);
    ClientMembership.renew(fetchData)
      .then((r) => {
        EventBus.$emit("update_client_window");
        enqueueSnackbar("Абонемент продан!", { variant: "success" });
        closeHandler();
      })
      .catch((e) => {
        let errorMessage = e.response.data.message;
        enqueueSnackbar(errorMessage, { variant: "error" });
        console.log(e.response);
      })
      .finally(() => {
        setSubmiting(false);
      });
  }
  return (
    <Box>
      <InputWrap id="group" label="Группа" wrapStyle={{ my: 1 }}>
        <Select
          value={data.group + ""}
          fullWidth
          id="group"
          size="small"
          onChange={(e) =>
            setData(
              Object.assign({}, data, {
                group: e.target.value + "",
                visitDays: null,
              })
            )
          }
        >
          {groups.map((d) => (
            <MenuItem key={d._id} value={d._id}>
              {groupGetFullInfo(d)}
            </MenuItem>
          ))}
        </Select>
      </InputWrap>
      {selectedGroup && data.visitDays && (
        <ChoiceVisitDays
          days={data.visitDays}
          changeToggleDay={changeToggleDay}
        />
      )}
      <Box sx={{ display: "flex", my: 1 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={notFullMonth}
              onChange={() => setNotFullMonth(!notFullMonth)}
            />
          }
          label="Не полный месяц"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isRecalc}
              onChange={() => setIsRecalc(!isRecalc)}
            />
          }
          label="Перерасчет"
        />
      </Box>

      {!notFullMonth && (
        <SelectMonth
          monthList={monthList}
          value={data.startVisit + ""}
          onChange={(e) => changeDataHandler("startVisit", +e.target.value)}
        />
      )}

      {notFullMonth && (
        <SelectDate
          id="startVisit"
          label={"Дата начала посещения"}
          value={data.startVisit}
          onChange={(d) =>
            changeDataHandler("startVisit", d.startOf("day").valueOf())
          }
        />
      )}

      {isRecalc && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SelectDate
              id="startRecalc"
              label={"Дата начала пропусков"}
              value={data.startRecalc}
              onChange={(d) =>
                changeDataHandler("startRecalc", d.startOf("day").valueOf())
              }
            />
          </Grid>
          <Grid item xs={6}>
            <SelectDate
              id="endRecalc"
              label={"Дата окончания пропусков"}
              value={data.endRecalc}
              onChange={(d) =>
                changeDataHandler("endRecalc", d.endOf("day").valueOf())
              }
            />
          </Grid>
        </Grid>
      )}

      {isRecalc && !recalcMembership && (
        <Typography sx={{ mt: 1 }} color="error" variant="body1">
          Не найдена группа посещения
        </Typography>
      )}
      {isRecalc && recalcMembership && (
        <Typography sx={{ mt: 1 }} variant="body1">
          Группа перерасчета:{" "}
          {recalcMembership.visitDays
            .map((g) => getDayNameByNumer(+g))
            .join(",")}
          {recalcGroup
            ? ` | ${recalcGroup.name} | ${recalcGroup.visitingTime}`
            : ""}
        </Typography>
      )}

      <FormControlLabel
        control={
          <Checkbox
            checked={hasDiscount}
            onChange={() => setHasDiscount(!hasDiscount)}
          />
        }
        label="Скидка"
      />
      {hasDiscount && (
        <Discount sum={discountSum} setSum={(v) => setDiscountSum(v)} />
      )}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          my: 2,
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body2">Бонусов:</Typography>
        <Typography variant="h6" fontWeight="medium">
          {balance} Б
        </Typography>
      </Box>

      {balance > 0 && !isRecalc && (
        <InputWrap id="bonuses" label="Изпользовать бонусов">
          <TextField
            id="bonuses"
            label=""
            fullWidth
            sx={{ m: 0 }}
            size="small"
            variant="outlined"
            onBlur={bonusesBlur}
            name="bonuses"
            type="number"
            onChange={(e) => changeDataHandler("bonuses", e.target.value)}
            value={data.bonuses}
          />
        </InputWrap>
      )}

      {totalSum < 3200 && (
        <FormControlLabel
          control={
            <Checkbox checked={asBonus} onChange={() => setAsBonus(!asBonus)} />
          }
          label="Начислить бонусы"
        />
      )}

      {isRecalc && recalcSum && (
        <CalculateSum label="Сумма перерасчета" totalSum={recalcSum} />
      )}
      {data.visitDays && data.startVisit && (
        <CalculateSum totalSum={totalSum} />
      )}

      <Button
        fullWidth
        sx={{ marginTop: 2 }}
        size="medium"
        variant="contained"
        onClick={createHandler}
        startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
        disabled={isSubmiting || !totalSum}
      >
        Оплатить
      </Button>
    </Box>
  );
}

function SelectMonth({ monthList, value, onChange }) {
  return (
    <InputWrap id="month" label="Месяц оплаты" wrapStyle={{ my: 1 }}>
      <Select
        value={value + ""}
        fullWidth
        id="month"
        size="small"
        onChange={onChange}
      >
        {monthList.map((d) => (
          <MenuItem key={d.num} value={d.num}>
            {d.name}
          </MenuItem>
        ))}
      </Select>
    </InputWrap>
  );
}

function SelectDate({ label, value, onChange, id }) {
  return (
    <InputWrap id={id} label={label}>
      <LocalizationProvider dateAdapter={AdapterMoment} locale="ru">
        <DesktopDatePicker
          inputFormat="DD.MM.YYYY"
          value={value}
          onChange={onChange}
          renderInput={(params) => (
            <TextField
              size="small"
              id={id}
              placeholder="ДД.ММ.ГГГГ"
              fullWidth
              {...params}
            />
          )}
        />
      </LocalizationProvider>
    </InputWrap>
  );
}
function getTotalSum(startVisit, endVisit, days, groupClubId, discountSum) {
  let clubs = ClubStore.list;
  let club = clubs.find((c) => c._id == groupClubId);

  let visitDays = [];
  let enabledAmountDays = 0;
  for (let i in days) {
    if (days[i]) {
      visitDays.push(+i);
      enabledAmountDays += 1;
    }
  }

  let oneLessonSum = club.prices.membership[enabledAmountDays - 1];

  let countVisitDays = getCalculateAmountVisit(
    new Date(startVisit),
    new Date(endVisit),
    visitDays
  );

  let result = oneLessonSum * countVisitDays;

  if (discountSum) {
    result -= discountSum;
  }

  return {
    result: result,
  };
}

function getTotalRecalcSum(startVisit, endVisit, days, groupClubId) {

  // days

  let formattedDays = {};

  days.forEach((d) => {
    formattedDays[d] = true;
  });

  // days.

  let clubs = ClubStore.list;
  let club = clubs.find((c) => c._id == groupClubId);

  let visitDays = [];
  let enabledAmountDays = 0;
  for (let i in formattedDays) {
    if (formattedDays[i]) {
      visitDays.push(+i);
      enabledAmountDays += 1;
    }
  }

  let oneLessonSum = club.prices.membership[enabledAmountDays - 1];

  let countVisitDays = getCalculateAmountVisit(
    new Date(startVisit),
    new Date(endVisit),
    visitDays
  );

  let result = oneLessonSum * countVisitDays;

  return result;
}
