import { useState } from "react";
import {
  Box,
  Modal,
  Typography,
  Card,
  List,
  ListItem,
  Button,
  ListItemText,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import AdditionalNumbersModalAdd from "./AdditionalNumbersModalAdd";

import ClientService from "../../services/Client";
import { useSnackbar } from "notistack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "2px solid #000",
  boxShadow: 24,
  p: 1.5,
};

export default function AdditionalNumbersModal({
  open,
  handleClose,
  numbers,
  clientId,
  setNumbers,
}) {
  const { enqueueSnackbar } = useSnackbar();
  let [showAdd, setShowAdd] = useState(false);

  const toggleShowAdd = () => setShowAdd(!showAdd);

  function deleteNumberHandler(phoneNumber) {
    ClientService.changeAddedNumbers({
      action: "delete",
      phone: phoneNumber,
      id: clientId,
    }).then(() => {
      setNumbers((numbers) => numbers.filter((n) => n == phoneNumber));
      enqueueSnackbar("Номер удален!", { variant: "success" });
    });
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Card sx={style}>
        <AdditionalNumbersModalAdd
          clientId={clientId}
          numbers={numbers}
          setNumbers={setNumbers}
          open={showAdd}
          handleClose={toggleShowAdd}
        />
        <Typography variant="h6" sx={{ mb: 2 }}>
          Дополнительные номера
        </Typography>
        <List>
          {numbers.map((n) => (
            <ListItem
              secondaryAction={
                <IconButton
                  onClick={() => deleteNumberHandler(n)}
                  edge="end"
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemText>{n}</ListItemText>
            </ListItem>
          ))}
        </List>
        <Button
          fullWidth
          sx={{ mt: 2 }}
          size="small"
          variant="contained"
          endIcon={<AddIcon />}
          onClick={toggleShowAdd}
        >
          Добавить
        </Button>
      </Card>
    </Modal>
  );
}
