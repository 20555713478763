import React, { useState } from "react";
import {
  TextField,
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ButtonGroup,
  CircularProgress,
  Paper,
  Typography,
  Grid,
  IconButton,
  Divider,
  Tabs,
  Tab,
} from "@mui/material";
import Feedback from "./Feedback";
import Push from "./Push";
import Sms from "./Sms";
import Telegram from "./Telegram";
import Chat from "./Chat";

function Communication({ clientId }) {
  const [currentTab, setCurrentTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  function viewSection(n) {
    switch (n) {
      case 0:
        return <Chat clientId={clientId} />;
      case 1:
        return <Feedback clientId={clientId} />;
      case 2:
        return <Push clientId={clientId} />;
      case 3:
        return <Sms clientId={clientId} />;
      case 4:
        return <Telegram clientId={clientId} />;
      default:
        return null;
    }
  }

  return (
    <Box>
      <Tabs value={currentTab} onChange={handleChangeTab}>
        <Tab label="Чат" />
        <Tab label="Обратная связь" />
        <Tab label="Уведомления" />
        <Tab label="SMS" />
        <Tab label="Telegram" />
      </Tabs>
      <Box sx={{ p: 1 }}>{viewSection(currentTab)}</Box>
    </Box>
  );
}

export default Communication;
