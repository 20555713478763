import axios from "axios";

class Order {
  create(data) {
    return axios.post(`/order`, data);
  }
  getAll(data) {
    return axios.get(`/order`, { params: data });
  }
  change(data) {
    return axios.put(`/order`, data);
  }
  getClientOrders(id) {
    return axios.get(`/order/client`, { params: { id } });
  }
  changeStage(data) {
    return axios.put(`/order/stage`, data);
  }
}

export default new Order({});
