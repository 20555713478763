import React, {useState, useEffect} from "react";
import {
    Box,
    Typography,
} from "@mui/material";

import AppSettingsService from "../../services/AppSettings";
import { useSnackbar } from "notistack";

function AppSettings() {
    const { enqueueSnackbar } = useSnackbar();
    const [version, setVersion] = useState(1)
    const [android, setAndroid] = useState('')
    const [ios, setIos] = useState('')
    const [huawei, setHuawei] = useState('')
    useEffect(() => {
        AppSettingsService.get().then(r => {
            console.log(r.data)
            setVersion(r.data.version)
            setAndroid(r.data.android)
            setIos(r.data.ios)
            setHuawei(r.data.huawei)
        })
    }, []);

    function saveSettings() {
        AppSettingsService.save({version,android,ios,huawei}).then(()=>{

            enqueueSnackbar("Настройки сохранены!", { variant: "success" });
        }).catch(e=>{
            enqueueSnackbar("Одна ошибка и ты ошибся", { variant: "error" });
        })
    }

    return <Box>
        <Typography variant="h5" gutterBottom component="div">
            Настройки приложения
        </Typography>
        <div>
            <div>
                <h5>Версия:</h5>
                <input value={version} onChange={e=>setVersion(e.target.value)} type={'number'} />
            </div>
            <div>
                <h5>Android:</h5>
                <input style={{width:'700px'}} value={android} onChange={e=>setAndroid(e.target.value)} />
            </div>
            <div>
                <h5>IOS:</h5>
                <input style={{width:'700px'}} value={ios} onChange={e=>setIos(e.target.value)} />
            </div>
            <div>
                <h5>Huawei:</h5>
                <input style={{width:'700px'}} value={huawei} onChange={e=>setHuawei(e.target.value)} />
            </div>
            <button onClick={()=>saveSettings()}>Сохранить</button>
        </div>
    </Box>;
}

export default AppSettings;
