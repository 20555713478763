import { createTheme } from "@mui/material/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#FF0009",
    },
    background: {
      default: "#1C1C1E",
    },
    text: {
      primary: "#fff",
    },
  },

  typography: {
    caption: {
      "font-size": "14px !important",
      color: "cornsilk",
      "&:hover": {
        "text-decoration": "underline",
      },
    },
  },
  components: {
    // Name of the component
    MuiPaper: {
      root: {
        padding: "10px",
        marginBottom: "10px",
        backgroundColor: "#434242",
        borderRadius: "10px",
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&.MuiIconButton-root  .MuiSvgIcon-root": {
            color: "white",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // backgroundColor: "black",
          // color: 'white'
        },
        contained: {
          backgroundColor: "#FF0009",
          // border: '1px solid white'
        },
        outlined: {
          border: "1px solid #434242",
          color: "white",
          backgroundColor: "#434242",
        },
      },
    },
   
    MuiTable: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderCollapse: "separate",
          borderSpacing: "0px 8px",
          td: { border: "solid 1px #000" },
          "tr td:first-child ,th:first-child": {
            "border-top-left-radius": "10px",
            "border-bottom-left-radius": "10px",
            "border-right": "none",
          },
          "tr td:last-child, tr th:last-child, ": {
            "border-top-right-radius": "10px",
            "border-bottom-right-radius": "10px",
            "border-left": "none",
          },
          "tr td": {
            border: "1px solid #434242",
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          // "border-bottom": "1px solid #e1e6ef",
          // "border-top": "1px solid #e1e6ef",
          "& th": {
            "font-size": "14px",
            // color: "#151b1e",
            "font-weight": "bold",
            "text-align": "center",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#434242",
          boxShadow: "none",
          borderRadius: "5px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "#1C1C1E",
          border: "1px solid #434242",
          "& .MuiSvgIcon-root": {
            color: "white",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent !important",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: "#6b6b6b #ACACAC",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#ACACAC",
            borderRadius: 8,
            width: "10px",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#6b6b6b",
            minHeight: 24,
            width: "8px",
            border: "3px solid #ACACAC",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "#959595",
            },
        },
      },
    },
    MuiPickerDay: {
      root: {
        backgroundColor: "transparent",
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          "& .MuiDayPicker-monthContainer .MuiButtonBase-root": {
            backgroundColor: "transparent",
          },
          "& .MuiPickersCalendarHeader-root .MuiButtonBase-root, & .MuiDayPicker-header .MuiTypography-root":
            {
              color: "white",
            },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          // backgroundColor: "#434242",
          // border: '1px solid #434242'
          color: "white",
        },
      },
    },
    // MuiTableBody: {
    //   borderSpacing:'0 1em'
    // },
    MuiTableRow: {
      styleOverrides: {
        root: {
          // border: "none",
          // border: "1px solid #1C1C1E",
          marginBottom: "10px",
          lineHeight: "10px",
          backgroundColor: "#434242",
          borderRadius: "10px",
          overflow: "hidden",

          // '-moz-border-radius': '10px 0 0 10px',
          // '-webkit-border-radius': '10px 0 0 10px'
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderRadius: "5px",
          backgroundColor: "#434242",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: "white",
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          border: "none",

          padding: "10px",
          textAlign: "center",
          backgroundColor: "transparent",
          lineHeight: "15px",
        },
      },
    },
    MuiBut: {
      styleOverrides: {
        root: {
          border: "none",

          // borderBottom: "1px solid #1C1C1E",
          padding: "10px",
          textAlign: "center",
          backgroundColor: "transparent",
        },
      },
    },
  },
});

// import { createTheme } from "@mui/material/styles";
// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#269c76",
//     },
//     background: {
//       default: "#f2f4f8",
//     },
//     text: {
//       primary: "#151b1e",
//     },
//   },

//   typography: {
//     caption: {
//       "font-size": "14px !important",
//       color: "#269c76",
//       "&:hover": {
//         "text-decoration": "underline",
//       },
//     },
//   },
//   components: {
//     // Name of the component
//     MuiPaper: {
//       root: {
//         padding: "10px",
//         marginBottom: "10px",
//         backgroundColor: "red",
//       },
//     },
//     MuiButton: {
//       styleOverrides: {
//         root: {
//           // backgroundColor: "black",
//           // color: 'white'
//         },
//       },
//     },
//     MuiTable: {
//       styleOverrides: {
//         root: {
//           boxShadow: "none",
//         },
//       },
//     },
//     MuiTableHead: {
//       styleOverrides: {
//         root: {
//           "border-bottom": "1px solid #e1e6ef",
//           "border-top": "1px solid #e1e6ef",
//           "& th": {
//             "font-size": "14px",
//             color: "#151b1e",
//             "font-weight": "bold",
//             "text-align": "center",
//           },
//         },
//       },
//     },
//     MuiTableRow: {
//       styleOverrides: {
//         root: {
//           "border-bottom": "1px solid #e1e6ef",
//         },
//       },
//     },
//     MuiPaper: {
//       styleOverrides: {
//         root: {
//           backgroundColor: "#fff",
//           border: "1px solid #e1e6ef",
//           boxShadow: "none",
//           borderRadius: "0px",
//         },
//       },
//     },
//     MuiTableCell: {
//       styleOverrides: {
//         root: {
//           // border: "1px solid gray",
//           padding: "10px",
//           textAlign: "center",
//         },
//       },
//     },
//   },
// });

export default theme;
