import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  TextField,
  InputLabel,
  Typography,
  IconButton,
  Select,
  MenuItem,
  ButtonGroup,
  Button,
} from "@mui/material";

export default function CalculateSum({ label = "Всего к оплате", totalSum }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        my: 2,
        justifyContent: "space-between",
      }}
    >
      <Typography variant="body1">{label}:</Typography>
      <Typography variant="h6" fontWeight="medium">
        {totalSum} руб.
      </Typography>
    </Box>
  );
}
