import { API_PRIVATE_IMAGES } from "../../config";

export default function getPrivateImageUri(image) {
  return (
    API_PRIVATE_IMAGES +
    image +
    "?access_token=" +
    localStorage.getItem("token")
  );
}
