import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Typography,
  IconButton,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import SidebarWrap from "../SidebarWrap";
import Preview from "./Preview";
import InputWrap from "../InputWrap";
import { useSnackbar } from "notistack";
import Gallery from "./Gallery";

import NewsService from "../../services/News";

export default function NewsEdit({ closeHandler, data }) {
  const { enqueueSnackbar } = useSnackbar();

  let [isSubmiting, setSubmiting] = useState(false);
  let [previewImage, setPreviewImage] = useState(null);
  let [previewError, setPreviewError] = useState(null);
  let [gallery, setGallery] = useState([]);
  let [galleryError, setGalleryError] = useState(null);
  let isEdit = data && Object.keys(data).length;

  let idNews = data?._id;
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      title: isEdit ? data.title : "",
      body: isEdit ? data.body : "",
    },
  });

  if (!data) return null;

  const onSubmit = handleSubmit((data) => {
    if (!isEdit) {
      if (!previewImage) {
        setPreviewError("Добавьте превью!");
        return;
      }
      if (!gallery.length) {
        setGalleryError("Добавьте изображения!");
        return;
      }

      let fetchData = JSON.stringify(Object.assign({}, data, { gallery }));
      const form = new FormData();
      form.append("preview", previewImage);
      form.append("data", fetchData);
      setSubmiting(true);
      NewsService.add(form)
        .then(() => {
          enqueueSnackbar("Новость добавлена!", { variant: "success" });
          closeHandler();
        })
        .finally(() => setSubmiting(false));
    } else {
      if (!gallery.length) {
        setGalleryError("Добавьте изображения!");
        return;
      }

      let fetchData = JSON.stringify(
        Object.assign({}, data, { gallery, id: idNews })
      );
      const form = new FormData();
      if (previewImage) {
        form.append("preview", previewImage);
      }
      form.append("data", fetchData);
      setSubmiting(true);
      NewsService.changeNews(form)
        .then(() => {
          enqueueSnackbar("Новость измененна!", { variant: "success" });
          closeHandler();
        })
        .finally(() => setSubmiting(false));
    }
  });

  return (
    <SidebarWrap
      title={`${isEdit ? "Изменить" : "Добавить"} новость`}
      closeHandler={closeHandler}
    >
      <Preview
        url={isEdit ? data.preview : ""}
        setImage={setPreviewImage}
        error={previewError}
        setError={setPreviewError}
      />
      <Gallery
        setGallery={setGallery}
        error={galleryError}
        initialState={isEdit ? data.gallery : null}
      />

      <form noValidate onSubmit={onSubmit}>
        <Controller
          name={"title"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputWrap wrapStyle={{ my: 1 }} id="title" label="Заголовок">
              <TextField
                id="title"
                label=""
                error={Boolean(errors.title?.message)}
                {...register("title", {
                  required: "Мин. 4 символов",
                  minLength: {
                    message: "Мин. 4 символов",
                    value: 4,
                  },
                  maxLength: 200,
                })}
                fullWidth
                margin="normal"
                helperText={errors.title?.message}
                sx={{ m: 0 }}
                size="small"
                variant="outlined"
                name="title"
                type="text"
                onChange={onChange}
                value={value}
              />
            </InputWrap>
          )}
        />
        <Controller
          name={"body"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputWrap wrapStyle={{ my: 1 }} id="body" label="Текст">
              <TextField
                id="body"
                label=""
                error={Boolean(errors.body?.message)}
                {...register("body", {
                  required: "Мин. 4 символов",
                  minLength: {
                    message: "Мин. 4 символов",
                    value: 4,
                  },
                })}
                fullWidth
                margin="normal"
                helperText={errors.body?.message}
                sx={{ m: 0 }}
                size="small"
                multiline
                rows={10}
                variant="outlined"
                name="body"
                type="text"
                onChange={onChange}
                value={value}
              />
            </InputWrap>
          )}
        />
        <Button
          fullWidth
          sx={{ marginTop: 4 }}
          size="small"
          variant="contained"
          type="submit"
          startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
          disabled={isSubmiting}
        >
          {!isEdit ? "Добавить" : "Сохранить"}
        </Button>
      </form>
    </SidebarWrap>
  );
}
