import React from "react";
import { Box, InputLabel } from "@mui/material";

export default function InputWrap({ id, label, children, wrapStyle = {} }) {
  return (
    <Box sx={wrapStyle}>
      <InputLabel shrink size="small" sx={{ display: "block" }} htmlFor={id}>
        {label}
      </InputLabel>

      {children}

      {/* <TextField
      id={id}
      
      {...props}
      label=""
      
    /> */}
    </Box>
  );
}
