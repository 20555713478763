import { WSS } from "./config";
import io from "socket.io-client";

let socket;

function connect() {
  socket = io(WSS, {
    transports: ["websocket", "polling", "flashsocket"],
    reconnection: true,
  });
  // socket.on("connect_error", (err) => console.log(err));
  socket.on("connect_failed", (err) => console.log(err));
}

function authenticate() {
  console.log("authenticate admin");
  const token = localStorage.getItem("token");
  socket.emit("authenticate_admin", token);
}

export { authenticate, connect, socket };

export default socket;
