import React, { useEffect, useState } from "react";
import {
  TextField,
  FormControlLabel,
  Box,
  Card,
  Checkbox,
  Container,
  Table,
  TableBody,
  TableCell,
  MenuList,
  TableContainer,
  TableHead,
  Collapse,
  TableRow,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ButtonGroup,
  Avatar,
  CircularProgress,
  Paper,
  List,
  ListItem,
  Popover,
  ListItemButton,
  ListItemText,
  Typography,
  Menu,
  IconButton,
  Popper,
  Chip,
  Grid,
  Modal,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useForm, Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import CloseIcon from "@mui/icons-material/Close";
import InputWrap from "../InputWrap";
import ClientService from "../../services/Client";

export default function ChangePhoneNumber({ showModal, setShowModal, id }) {
  const { enqueueSnackbar } = useSnackbar();

  const [forAll, setForAll] = useState(false);
  const [dataTransfer, setDataTransfer] = useState(false);
  const [isSubmiting, setSubmiting] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      phone: "",
    },
  });

  const onSubmit = handleSubmit((d) => {
    let phoneNumber = d.phone.replace(/\D/g, "");

    setSubmiting(true);
    ClientService.changePhone({
      id,
      forAll,
      dataTransfer,
      phone: phoneNumber,

    })
      .then(() => {
        enqueueSnackbar("Номер изменён!", { variant: "success" });
        setShowModal(false);
      })
      .finally(() => setSubmiting(false));
  });
  return (
    <Modal
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
    >
      <Card
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            // mb: 2,
            px: 1,
          }}
        >
          <Typography variant="h6" component="h2">
            Изменение номера
          </Typography>
          <IconButton sx={{ m: 0, ml: 2 }} onClick={() => setShowModal(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box noValidate component="form" onSubmit={onSubmit} sx={{ p: 2 }}>
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={forAll}
                onChange={(e) => setForAll(e.target.checked)}
              />
            }
            label="Для всех привязанных"
          /> */}
          <FormControlLabel
            control={
              <Checkbox
                checked={dataTransfer}
                onChange={(e) => setDataTransfer(e.target.checked)}
              />
            }
            label="Перенос данных с номера ниже на этот"
          />
          <Controller
            name={"phone"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputWrap wrapStyle={{ my: 1 }} label="Номер телефона">
                <InputMask
                  error={Boolean(errors.phone?.message)}
                  helperText={errors.phone?.message}
                  {...register("phone", {
                    required: "Введите номер телефона",
                  })}
                  required
                  label=""
                  sx={{ m: 0 }}
                  size="small"
                  variant="outlined"
                  mask="+7 (999) 999-99-99"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  name="phone"
                  type="text"
                >
                  {(e) => <TextField {...e} />}
                </InputMask>
              </InputWrap>
            )}
          />
          <Button
            fullWidth
            sx={{ marginTop: 2 }}
            size="large"
            variant="outlined"
            type="submit"
            startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmiting}
          >
            Сохранить
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}
