import React from "react";
import {
  Box,
  TextField,
  InputLabel,
  CircularProgress,
  Typography,
  IconButton,
  Select,
  MenuItem,
  ButtonGroup,
  Button,
} from "@mui/material";
import InputWrap from "../InputWrap";

export default function Discount({ sum, setSum }) {
  return (
    <Box>
      <InputWrap id="discount" label="Сумма скидки">
        <TextField
          id="discount"
          label=""
          fullWidth
          sx={{ m: 0 }}
          size="small"
          variant="outlined"
          // onBlur={bonusesBlur}
          name="discount"
          type="number"
          onChange={(e) => setSum(e.target.value)}
          value={sum}
        />
      </InputWrap>
    </Box>
  );
}
