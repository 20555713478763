import React from "react";
import InputWrap from "../../components/InputWrap";
import {
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Typography,
  SwipeableDrawer,
  Button,
  TextField,
  IconButton,
  FormControl,
  Select,
  Popover,
  MenuItem,
  InputLabel,
  InputAdornment,
  Grid,
} from "@mui/material";

export default function BirthdayRange({ from, to, changeHandler }) {
  return (
    <InputWrap id="clientAge" label="Возраст клиента">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="clientAgeFrom"
            label=""
            fullWidth
            sx={{ m: 0 }}
            size="small"
            pla
            variant="outlined"
            name="clientAgeFrom"
            type="number"
            placeholder="От"
            onChange={(e) => changeHandler({ from: e.target.value, to })}
            value={from}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="clientAgeTo"
            label=""
            fullWidth
            sx={{ m: 0 }}
            size="small"
            pla
            variant="outlined"
            name="clientAgeTo"
            type="number"
            placeholder="До"
            onChange={(e) => changeHandler({ from, to: e.target.value })}
            value={to}
          />
        </Grid>
      </Grid>
    </InputWrap>
  );
}
