import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  ButtonGroup,
  Button,
} from "@mui/material";
import MemebershipTable from "./MembershipTable";

import ClientMembershipService from "../../services/ClientMembership";

// Store
import ClubStore from "../../store/club";
import { observer } from "mobx-react-lite";

function Purchases() {
  let clubId = ClubStore.currentClubId;
  let [list, setList] = useState([]);
  let [paginData, setPaginData] = useState({
    page: 0,
    rowsPerPage: 5,
    countRows: 10,
  });

  useEffect(() => {
    fetchMemberships();
  }, [clubId]);

  useEffect(() => {
    fetchMemberships();
  }, [paginData.page, paginData.rowsPerPage]);

  function changePaginData(name, value) {
    let newPaginData = Object.assign({}, paginData, { [name]: value });
    setPaginData(newPaginData);
  }
  const handleChangePage = (event, newPage) => {
    changePaginData("page", newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let val = parseInt(event.target.value, 10);
    setPaginData(
      Object.assign({}, paginData, {
        page: 0,
        rowsPerPage: val,
      })
    );
  };
  function fetchMemberships() {
    ClientMembershipService.getRecentPurchases(paginData).then((res) => {
      let data = res.data;
      setList(data.list);
      changePaginData("countRows", data.pagin.countRows);
      // setSubmit(false);
    });
  }

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Оплаты
      </Typography>
      <MemebershipTable
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        list={list}
        paginData={paginData}
      />
    </Box>
  );
}

export default observer(Purchases);
