import React, { useState } from "react";
import SidebarWrap from "../SidebarWrap";
import { TextField, Grid, Button, CircularProgress } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import UploadImage from "../../components/UploadImage/UploadImage";
import InputWrap from "../InputWrap";
import ClubService from "../../services/Club";
import { useSnackbar } from "notistack";
import clubStore from "../../store/club";
import LessonPrice from "./LessonPrice";

export default function ClubEdit({ closeHandler, data }) {
  const { enqueueSnackbar } = useSnackbar();
  let [isSubmiting, setSubmiting] = useState(false);
  let [prices, setPrices] = useState(data?.prices);

  const [newImage, setNewImage] = useState(null);
  let isEdit = data && Object.keys(data).length;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: isEdit ? data.name : "",
      address: isEdit ? data.address : "",
      phone: isEdit ? data.phone : "",
      email: isEdit ? data.email : "",
      workingMode: isEdit ? data.workingMode : "",
      about: isEdit ? data.about : "",
    },
  });

  const onSubmit = handleSubmit((d) => {
    if (!isEdit) {
      const form = new FormData();
      if (newImage) {
        form.append("photo", newImage);
      }
      form.append("data", JSON.stringify(d));
      setSubmiting(true);
      ClubService.add(form)
        .then((r) => {
          clubStore.addItem(r.data);
          enqueueSnackbar("Клуб добавлен!", { variant: "success" });
          closeHandler();
        })
        .finally(() => setSubmiting(false));
    } else {
      const form = new FormData();
      if (newImage) {
        form.append("photo", newImage);
      }
      let de = Object.assign({}, data, d, { prices });
      form.append("data", JSON.stringify(de));
      setSubmiting(true);
      ClubService.changeClub(form)
        .then((r) => {
          clubStore.changeItem(r.data);
          enqueueSnackbar("Клуб изменён!", { variant: "success" });
          closeHandler();
        })
        .finally(() => setSubmiting(false));
    }
  });

  function changePrice(prices) {
    setPrices(prices);
  }

  return (
    <SidebarWrap
      large
      title={!isEdit ? "Добавить Клуб" : "Изменить Клуб"}
      closeHandler={closeHandler}
    >
      <form noValidate onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <UploadImage setImage={setNewImage} url={data?.photo} />
            <Controller
              name={"name"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputWrap
                  wrapStyle={{ my: 1 }}
                  id="name"
                  label="Название клуба"
                >
                  <TextField
                    id="name"
                    label=""
                    error={Boolean(errors.name?.message)}
                    {...register("name", {
                      required: "Название клуба не может быть пустым",
                      minLength: {
                        message: "Мин. 4 символов",
                        value: 4,
                      },
                      maxLength: 200,
                    })}
                    fullWidth
                    margin="normal"
                    helperText={errors.name?.message}
                    sx={{ m: 0 }}
                    size="small"
                    variant="outlined"
                    name="name"
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                </InputWrap>
              )}
            />
            <Controller
              name={"address"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputWrap wrapStyle={{ my: 1 }} id="address" label="Адрес">
                  <TextField
                    id="address"
                    label=""
                    error={Boolean(errors.address?.message)}
                    {...register("address", {
                      required: "Адрес не может быть пустым",
                      minLength: {
                        message: "Мин. 4 символов",
                        value: 4,
                      },
                      maxLength: 200,
                    })}
                    fullWidth
                    margin="normal"
                    helperText={errors.address?.message}
                    sx={{ m: 0 }}
                    size="small"
                    variant="outlined"
                    name="address"
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                </InputWrap>
              )}
            />
            <Controller
              name={"phone"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputWrap
                  wrapStyle={{ my: 1 }}
                  id="phone"
                  label="Номер телефона"
                >
                  <TextField
                    id="phone"
                    label=""
                    error={Boolean(errors.phone?.message)}
                    {...register("phone", {
                      required: "Номер телефона не может быть пустым",
                      minLength: {
                        message: "Мин. 4 символов",
                        value: 4,
                      },
                      maxLength: 200,
                    })}
                    fullWidth
                    margin="normal"
                    helperText={errors.phone?.message}
                    sx={{ m: 0 }}
                    size="small"
                    variant="outlined"
                    name="phone"
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                </InputWrap>
              )}
            />
            <Controller
              name={"email"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputWrap wrapStyle={{ my: 1 }} id="email" label="E-mail">
                  <TextField
                    id="email"
                    label=""
                    error={Boolean(errors.email?.message)}
                    {...register("email", {
                      required: "E-mail не может быть пустым",
                      minLength: {
                        message: "Мин. 4 символов",
                        value: 4,
                      },
                      maxLength: 200,
                    })}
                    fullWidth
                    margin="normal"
                    helperText={errors.email?.message}
                    sx={{ m: 0 }}
                    size="small"
                    variant="outlined"
                    name="email"
                    type="email"
                    onChange={onChange}
                    value={value}
                  />
                </InputWrap>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <Controller
              name={"workingMode"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputWrap
                  wrapStyle={{ my: 1 }}
                  id="workingMode"
                  label="Режим работы"
                >
                  <TextField
                    id="workingMode"
                    label=""
                    error={Boolean(errors.workingMode?.message)}
                    {...register("workingMode", {
                      required: "Режим работы не может быть пустым",
                      minLength: {
                        message: "Мин. 4 символов",
                        value: 4,
                      },
                      maxLength: 200,
                    })}
                    fullWidth
                    margin="normal"
                    rows={4}
                    multiline
                    helperText={errors.workingMode?.message}
                    sx={{ m: 0, height: "100%" }}
                    size="small"
                    variant="outlined"
                    name="workingMode"
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                </InputWrap>
              )}
            />

            <Controller
              name={"about"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputWrap wrapStyle={{ my: 1 }} id="about" label="О нас">
                  <TextField
                    id="about"
                    label=""
                    multiline
                    rows={10}
                    error={Boolean(errors.about?.message)}
                    {...register("about", {
                      required: "О нас не может быть пустым",
                      minLength: {
                        message: "Мин. 4 символов",
                        value: 4,
                      },
                      maxLength: 200,
                    })}
                    fullWidth
                    margin="normal"
                    helperText={errors.about?.message}
                    sx={{ m: 0 }}
                    size="small"
                    variant="outlined"
                    name="about"
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                </InputWrap>
              )}
            />
            {prices && isEdit && (
              <LessonPrice prices={prices} changePrice={changePrice} />
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <Button
              fullWidth
              sx={{ mt: 3 }}
              type="submit"
              variant="contained"
              startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
              disabled={isSubmiting}
            >
              {!isEdit ? "Добавить" : "Сохранить"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </SidebarWrap>
  );
}
