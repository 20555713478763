import React, { useState } from "react";
import {
  TextField,
  Box,
  Chip,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ButtonGroup,
  CircularProgress,
  Paper,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import SidebarWrap from "../../components/SidebarWrap";
import { useForm, Controller } from "react-hook-form";
import { TwitterPicker } from "react-color";
import FunnelStageStore from "../../store/funnelStage";
import FunnelStageService from "../../services/FunnelStage";
import { useSnackbar } from "notistack";
import InputWrap from "../../components/InputWrap";

function StageWindow({ id, closeHandler, mode }) {
  const { enqueueSnackbar } = useSnackbar();
  let [isSubmiting, setSubmiting] = useState(false);
  let [showColorPicker, setShowColorPicker] = useState("");

  let stages = FunnelStageStore.list;
  let isNew = id == "new";

  let stage = {
    name: "",
    displayOrder: 10,
    backgroundColor: "#FF6900",
    textColor: "#000000",
  };
  console.log(stage, "stage");

  if (!isNew && id) {
    stage = stages.find((s) => s._id == id);
  }

  const {
    register,
    handleSubmit,
    control,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: stage.name,
      displayOrder: stage.displayOrder,
      backgroundColor: stage.backgroundColor,
      textColor: stage.textColor,
    },
  });
  let { textColor, backgroundColor } = watch();

  const onSubmit = handleSubmit((d) => {
    let fetchData = d;

    if (!isNew) {
      fetchData = Object.assign({}, d, { _id: id });
    } else {
      fetchData = Object.assign({}, d, { mode });
    }

    setSubmiting(true);

    const fetchFunc = isNew
      ? FunnelStageService.create
      : FunnelStageService.change;

    fetchFunc(fetchData)
      .then((r) => {
        let s = r.data;
        if (isNew) {
          FunnelStageStore.addStage(s);
          enqueueSnackbar("Этап добавлен!", { variant: "success" });
        } else {
          FunnelStageStore.changeStage(s);
          enqueueSnackbar("Этап изменён!", { variant: "success" });
        }
        closeHandler();
      })
      .finally(() => setSubmiting(false));
  });

  return (
    <SidebarWrap
      closeHandler={closeHandler}
      title={isNew ? "Создать этап" : "Изменить этап"}
    >
      <form noValidate onSubmit={onSubmit}>
        <Controller
          name={"name"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputWrap wrapStyle={{ mb: 2 }} id="name" label="Наименование">
              <TextField
                error={Boolean(errors.name?.message)}
                {...register("name", {
                  required: "Название не может быть пустым",
                  minLength: {
                    message: "Мин. 4 символов",
                    value: 4,
                  },
                  maxLength: 200,
                })}
                id="name"
                label=""
                fullWidth
                helperText={errors.name?.message}
                sx={{ m: 0 }}
                size="small"
                name="name"
                variant="outlined"
                type="text"
                onChange={onChange}
                value={value}
              />
            </InputWrap>
          )}
        />
        <Controller
          name={"displayOrder"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputWrap
              wrapStyle={{ mb: 2 }}
              id="displayOrder"
              label="Порядок показа"
            >
              <TextField
                error={Boolean(errors.displayOrder?.message)}
                {...register("displayOrder", {
                  required: "Порядок может быть пустым",
                })}
                id="displayOrder"
                label=""
                fullWidth
                helperText={errors.displayOrder?.message}
                sx={{ m: 0 }}
                size="small"
                name="displayOrder"
                variant="outlined"
                type="number"
                onChange={onChange}
                value={value}
              />
            </InputWrap>
          )}
        />
        <Controller
          name={"backgroundColor"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <React.Fragment>
              <InputWrap
                wrapStyle={{ mb: 2 }}
                id="backgroundColor"
                label="Цвет фона"
              >
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box
                          sx={{
                            minWidth: "20px",
                            minHeight: "20px",
                            bgcolor: value,
                          }}
                        ></Box>
                      </InputAdornment>
                    ),
                  }}
                  onFocus={() => setShowColorPicker("background")}
                  error={Boolean(errors.backgroundColor?.message)}
                  {...register("backgroundColor", {
                    required: "Цвет не может быть пустым",
                  })}
                  id="backgroundColor"
                  label=""
                  fullWidth
                  helperText={errors.backgroundColor?.message}
                  sx={{ m: 0 }}
                  size="small"
                  name="backgroundColor"
                  variant="outlined"
                  type="text"
                  onChange={onChange}
                  value={value}
                />
              </InputWrap>
              {showColorPicker == "background" && (
                <TwitterPicker
                  color={value}
                  onChangeComplete={(e) => {
                    onChange(e.hex);
                    setShowColorPicker(false);
                  }}
                  width="200px"
                />
              )}
            </React.Fragment>
          )}
        />
        <Controller
          name={"textColor"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <React.Fragment>
              <InputWrap
                wrapStyle={{ mb: 2 }}
                id="textColor"
                label="Цвет текста"
              >
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box
                          sx={{
                            minWidth: "20px",
                            minHeight: "20px",
                            bgcolor: value,
                          }}
                        ></Box>
                      </InputAdornment>
                    ),
                  }}
                  onFocus={() => setShowColorPicker("text")}
                  error={Boolean(errors.textColor?.message)}
                  {...register("textColor", {
                    required: "Цвет не может быть пустым",
                  })}
                  id="textColor"
                  label=""
                  fullWidth
                  helperText={errors.textColor?.message}
                  sx={{ m: 0 }}
                  size="small"
                  name="textColor"
                  variant="outlined"
                  type="text"
                  onChange={onChange}
                  value={value}
                />
              </InputWrap>
              {showColorPicker == "text" && (
                <TwitterPicker
                  color={value}
                  onChangeComplete={(e) => {
                    onChange(e.hex);
                    setShowColorPicker(false);
                  }}
                  width="200px"
                />
              )}
            </React.Fragment>
          )}
        />
        <Box sx={{ display: "flex", mt: 4, alignItems: "center" }}>
          <Typography variant="body1" sx={{ mr: 2 }}>
            Пример:
          </Typography>

          <Chip
            label="Пример текста"
            sx={{ bgcolor: backgroundColor, color: textColor }}
          />
        </Box>
        <Button
          fullWidth
          sx={{ marginTop: 4 }}
          size="small"
          variant="contained"
          type="submit"
          startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
          disabled={isSubmiting}
        >
          {isNew ? "Добавить" : "Сохранить"}
        </Button>
      </form>
    </SidebarWrap>
  );
}

export default StageWindow;
