import TrainerService from "./services/Trainer";
import GroupService from "./services/Group";
import FunnelStageService from "./services/FunnelStage";
import SourceService from "./services/Source";
import ClubService from "./services/Club";

///

import ClubStore from "./store/club";
import GroupStore from "./store/group";
import TrainerStore from "./store/trainer";
import FunnelStageStore from "./store/funnelStage";
import SourceStore from "./store/source";

export default function loadMainData() {
  let stages = FunnelStageService.getAll();
  let groups = GroupService.getAll();
  let trainers = TrainerService.getTrainers();
  let sources = SourceService.getAll();
  let clubs = ClubService.getClub();

  return new Promise((res, rej) => {
    Promise.all([stages, groups, trainers, sources, clubs]).then((values) => {
      FunnelStageStore.setStageList(values[0].data);
      GroupStore.setGroupList(values[1].data);
      TrainerStore.setTrainerList(values[2].data);
      SourceStore.setList(values[3].data);
      ClubStore.setList(values[4].data);
      res();
    });
  });
}
