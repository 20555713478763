import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Avatar,
  TableRow,
  IconButton,
  Button,
  Paper,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import NewsService from "../../services/News";
import AddIcon from "@mui/icons-material/Add";
import { useSnackbar } from "notistack";
import { EventBus } from "../../event";
import { API_IMAGES } from "../../config";

// Store
import { observer } from "mobx-react-lite";
import ClubStore from "../../store/club";

function News() {
  const { enqueueSnackbar } = useSnackbar();
  let [list, setList] = useState([]);

  let currentClubId = ClubStore.currentClubId;

  useEffect(() => {
    NewsService.getAll().then((r) => setList(r.data));
    return () => setList([]);
  }, [currentClubId]);

  function deleteHandler(id) {
    if (window.confirm("Удалить новость?")) {
      NewsService.delete(id).then(() => {
        setList((list) => list.filter((n) => n._id !== id));
        enqueueSnackbar("Группа удалена!", { variant: "success" });
      });
    }
  }

  return (
    <Box>
      <Typography variant="h5" gutterBottom component="div">
        Новости
      </Typography>
      <Button
        sx={{ mb: 2 }}
        size="small"
        variant="contained"
        onClick={() => EventBus.$emit("open_edit_news", {})}
        startIcon={<AddIcon />}
      >
        Добавить
      </Button>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Превью</TableCell>
              <TableCell>Заголовок</TableCell>
              <TableCell>Действие</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((n) => {
              const photoUrl = API_IMAGES + n.preview;
              return (
                <TableRow key={n._id}>
                  <TableCell>
                    <Avatar
                      variant="square"
                      alt={n.title}
                      src={photoUrl}
                      sx={{ width: 100, height: 100, m: "0 auto" }}
                    />
                  </TableCell>

                  <TableCell>{n.title}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => EventBus.$emit("open_edit_news", n)}
                      sx={{ mr: 1 }}
                      aria-label="Изменить"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => deleteHandler(n._id)}
                      aria-label="Удалить"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default observer(News);
