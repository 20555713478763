import axios from "axios";

class Cashback {
  chargeSum(data) {
    return axios.post(`/cashback`, data);
  }
  //   getAll(query = {}) {
  //     return axios.get(`/client`, { params: query });
  //   }
  //   search(query = {}) {
  //     return axios.get(`/client/search`, { params: query });
  //   }
  //   getOne(id) {
  //     return axios.get(`/client/one`, { params: { id } });
  //   }
}

export default new Cashback({});
