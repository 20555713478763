let devUrl = "https://api.cobra.exise.ru/";
let prodUrl = "https://api.cobrafightclub.ru/";
//devUrl = prodUrl;
const config = {
  API:
    process.env.NODE_ENV === "development"
      ? devUrl + "api/crm"
      : prodUrl + "api/crm",
  API_IMAGES:
    process.env.NODE_ENV === "development"
      ? devUrl + "api/images/"
      : prodUrl + "api/images/",
  API_PRIVATE_IMAGES:
    process.env.NODE_ENV === "development"
      ? devUrl + "api/crm/private-files/images/"
      : prodUrl + "api/crm/private-files/images/",

  SERVER:
    process.env.NODE_ENV === "development"
      ? devUrl
      : prodUrl,
  WSS:
    process.env.NODE_ENV === "development"
      ? devUrl
      : prodUrl,
};

export const { API, API_IMAGES, API_PRIVATE_IMAGES, SERVER, WSS } = config;
