import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Select,
  MenuItem,
  Menu,
  FormControl,
  InputLabel,
  ButtonGroup,
  CircularProgress,
  Paper,
  Collapse,
  Typography,
  Autocomplete,
  IconButton,
  ListItemButton,
  List,
  ListItem,
  ListItemText,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import InputWrap from "../InputWrap";
import CloseIcon from "@mui/icons-material/Close";
import GroupStore from "../../store/group";
import TrainerStore from "../../store/trainer";
import ClubStore from "../../store/club";

import moment from "moment";
import getDayNameByNumer from "../../common/getDayNameByNumer";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import "moment/locale/ru";
moment.locale("ru");

// function dayWeekPicker(days) {

// }

// function getGroupById(list, id) {
//   return list.find((g) => g._id == id);
// }

export default function SelectGroup({
  groupId,
  setGroupId,
  visitDate,
  setVisitDate,
  hasError,
}) {
  let currentClubId = ClubStore.currentClubId;
  let groupList = [...GroupStore.list].filter((g) => g.club == currentClubId);
  let trainerList = TrainerStore.list;

  let currentGroup = groupList.find((g) => g._id == groupId);

  function disableWeekends(date) {
    let dayNum = date.day();
    if (!dayNum) {
      dayNum = 7;
    }
    return !currentGroup.visitingDays.find((n) => dayNum == +n);
  }

  return (
    <Paper sx={{ p: 1, mt: 1 }} variant="outlined">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="subtitle1" fontWeight="normal">
          Выбор группы
        </Typography>
      </Box>
      {hasError && <FormHelperText error>{hasError}</FormHelperText>}
      <InputWrap id="group" label="Группа" wrapStyle={{ my: 1 }}>
        <Select
          value={groupId + ""}
          fullWidth
          size="small"
          onChange={(e) => setGroupId(e.target.value + "")}
        >
          {groupList.map((g) => {
            let visitingDays = g.visitingDays
              .map((n) => getDayNameByNumer(n))
              .join(",");
            return (
              <MenuItem key={g._id} value={g._id}>
                {g.name} | {g.visitingTime} | {visitingDays}
              </MenuItem>
            );
          })}
        </Select>
      </InputWrap>
      {currentGroup && (
        <LocalizationProvider dateAdapter={AdapterMoment} locale="ru">
          <DesktopDatePicker
            label=""
            size="small"
            fullWidth
            disablePast
            inputFormat="DD.MM.YYYY"
            shouldDisableDate={disableWeekends}
            value={visitDate}
            onChange={(newValue) =>
              setVisitDate(newValue.utc().format().split("T")[0])
            }
            renderInput={(params) => (
              <InputWrap
                id="group"
                label="День посещения"
                wrapStyle={{ my: 1 }}
              >
                <TextField sx={{ width: "100%" }} {...params} />
              </InputWrap>
            )}
          />
        </LocalizationProvider>
      )}
    </Paper>
  );
}
