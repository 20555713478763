import React, { useEffect, useState } from "react";
import {
  TextField,
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ButtonGroup,
  CircularProgress,
  Paper,
  Typography,
  Grid,
  IconButton,
  Divider,
} from "@mui/material";
import OrderService from "../../../services/Order";
import OrderCard from "./OrderCard";
import { socket } from "../../../socket";

export default function OrderList({ clientId }) {
  let [list, setList] = useState([]);

  useEffect(() => {
    OrderService.getClientOrders(clientId).then((r) => setList(r.data));

    socket.on("add_order", (order) => {
      addOrderHandler(order);
    });
    socket.on("update_order", (order) => {
      updateOrderHandler(order);
    });

    return () => {
      socket.off("add_order");
      socket.off("update_order");
    };
  }, []);

  function addOrderHandler(order) {
    console.log(order, clientId, "add order");
    if (order.client._id + "" == clientId) {
      setList((orders) => {
        return [order, ...orders];
      });
    }
  }

  function updateOrderHandler(order) {
    setList((orders) => {
      let newOrders = [...orders];
      return newOrders.map((o) => {
        let oId = o._id + "";
        let ordId = order._id + "";
        if (oId == ordId) {
          return Object.assign({}, order);
        } else {
          return Object.assign({}, o);
        }
      });
    });
  }

  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        <Grid xs={12} md={6}>
          {list.map((o) => (
            <OrderCard key={o._id + ""} data={o} />
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}
