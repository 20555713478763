import React, { useState } from "react";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Fab from "@mui/material/Fab";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

import { API_IMAGES } from "../../config";

export default function UploadImage({ url, setImage }) {
  const [selectedFile, setSelectedFile] = useState(false);

  const imageUrl = API_IMAGES + url;

  function changeImageHandler(event) {
    var file = event.target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onloadend = function (e) {
      setSelectedFile([reader.result]);
    };

    createImage(file);

    function createImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }

  const viewSelectedImg = (
    <CardActionArea>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="contained-button-file"
        multiple
        type="file"
        onChange={changeImageHandler}
      />
      <label htmlFor="contained-button-file">
        <CardMedia
          component="img"
          sx={{ height: "300px" }}
          image={selectedFile + ""}
          alt="Сменить изображение"
        />
      </label>
    </CardActionArea>
  );

  const viewUrlImg = (
    <CardActionArea sx={{ width: "100%" }}>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="contained-button-file"
        multiple
        type="file"
        onChange={changeImageHandler}
      />
      <label htmlFor="contained-button-file">
        <CardMedia
          component="img"
          sx={{ width: "100%", objectFit: "contain" }}
          image={imageUrl}
          alt="Сменить изображение"
        />
      </label>
    </CardActionArea>
  );

  const viewNoneImg = (
    <CardContent>
      <Grid container justify="center" alignItems="center">
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="contained-button-file"
          multiple
          type="file"
          onChange={changeImageHandler}
        />
        <label
          style={{
            display: "flex",
            "justify-content": "center",
            width: "100%",
          }}
          htmlFor="contained-button-file"
        >
          <Fab component="span" sx={{ margin: 10 }}>
            <AddPhotoAlternateIcon fontSize="large" />
          </Fab>
        </label>
      </Grid>
    </CardContent>
  );

  function renderState() {
    if (selectedFile) {
      return viewSelectedImg;
    }

    if (url) {
      return viewUrlImg;
    } else {
      return viewNoneImg;
    }
  }

  let content = renderState();
  return <Card sx={{ maxWidth: "100%" }}>{content}</Card>;
}
