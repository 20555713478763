import React, { useEffect, useState } from "react";

import {
  Box,
  Typography,
  Container,
  Button,
  Grid,
  Popover,
  TextField,
  InputAdornment,
  styled,
} from "@mui/material";

// icons
import AddIcon from "@mui/icons-material/Add";

import CostService from "../../services/Cost";
import { useSnackbar } from "notistack";

//
import CostTable from "./CostTable";
import AddCost from "./AddCost";

//
import Filter from "./Filter";

// utils
import moment from "moment";
import "moment/locale/ru";

//
import DateRangePicker from "../../components/DateRangePicker";

// Store
import ClubStore from '../../store/club'
import {observer} from 'mobx-react-lite';

function Cost() {
  let clubId = ClubStore.currentClubId;
  const { enqueueSnackbar } = useSnackbar();
  let [showAdd, setShowAdd] = useState(false);
  let [list, setList] = useState([]);
  let [categories, setCategories] = useState([]);
  let [category, setCategory] = useState("");
  let [allSum, setAllSum] = useState(0);
  let [period, setPeriod] = useState({
    from: moment().startOf("month").valueOf(),
    to: moment().endOf("month").valueOf(),
  });
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    CostService.getAllCategory().then((r) => setCategories(r.data));
  }, []);

  // let [category, setCategory] = useState()

  let [paginData, setPaginData] = useState({
    page: 0,
    rowsPerPage: 5,
    countRows: 10,
  });

  useEffect(() => {
    fetchCosts();
  }, [clubId]);

  useEffect(() => {
    fetchCosts();
  }, [paginData.page, paginData.rowsPerPage]);

  function changePaginData(name, value) {
    let newPaginData = Object.assign({}, paginData, { [name]: value });
    setPaginData(newPaginData);
  }
  const handleChangePage = (event, newPage) => {
    changePaginData("page", newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let val = parseInt(event.target.value, 10);
    setPaginData(
      Object.assign({}, paginData, {
        page: 0,
        rowsPerPage: val,
      })
    );
  };
  function fetchCosts() {
    CostService.getAllCost({
      pagin: paginData,
      filter: {
        period,
        category,
      },
    }).then((res) => {
      let data = res.data;
      setList(data.list);
      changePaginData("countRows", data.pagin.countRows);
      setAllSum(data.allSum);
    });
  }

  function findHandler() {
    fetchCosts();
  }

  const toggleShowAdd = () => {
    setShowAdd(!showAdd);
  };

  function deleteHandler(id) {
    CostService.deleteCost(id).then((res) => {
      setList((l) => l.filter((c) => c._id !== id));
      enqueueSnackbar("Расход удален!", { variant: "success" });
    });
  }

  function addHandler(d) {
    setList((l) => [d, ...l]);
  }

  const openPopover = (event, name) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <DateRangePicker
          startDate={period.from}
          endDate={period.to}
          setDate={(a, b) =>
            setPeriod({
              from: a,
              to: b,
            })
          }
        />
      </Popover>
      <AddCost
        addHandler={addHandler}
        show={showAdd}
        toggleShowAdd={toggleShowAdd}
        categories={categories}
      />
      <Typography variant="h5" gutterBottom component="div">
        Расходы
      </Typography>

      <Button
        onClick={() => toggleShowAdd(true)}
        sx={{ mb: 2 }}
        variant="contained"
        type="submit"
      >
        Добавить
      </Button>

      <Typography variant="h6" gutterBottom component="div">
        Фильтр
      </Typography>

      <Filter
        findHandler={findHandler}
        openPopover={openPopover}
        categories={categories}
        category={category}
        setCategory={setCategory}
        period={period}
        setPeriod={setPeriod}
      />

      {!!allSum && (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Typography variant="h5" gutterBottom component="div">
            {allSum} ₽
          </Typography>
        </Box>
      )}

      <CostTable
        deleteHandler={deleteHandler}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        list={list}
        paginData={paginData}
      />
    </Box>
  );
}

export default observer(Cost);
