import React, { useState, useCallback, useEffect } from "react";
import {
  TextField,
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Avatar,
  TableRow,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ButtonGroup,
  CircularProgress,
  Paper,
  Typography,
  Grid,
  IconButton,
  Divider,
  Modal,
  Alert,
} from "@mui/material";
import { EventBus } from "../../event";
import moment from "moment";
import "moment/locale/ru";

export default function SmsTable({ list, hideUser = false }) {
  return (
    <Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {!hideUser && (
                <React.Fragment>
                  <TableCell>Телефон</TableCell>
                  <TableCell>ФИО Клиента</TableCell>
                </React.Fragment>
              )}
              <TableCell>Сообщение</TableCell>
              <TableCell>Дата</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((r) => {
              let client = r.client;
              if(!client) return null;
              let phone = `+${client.phone}`;
              let fullName = `${client.surname} ${client.name} ${client.patronymic}`;

              return (
                <TableRow
                  key={r._id}
                  onClick={(e) => {
                    EventBus.$emit("modal_client_action", {
                      action: "show-sms",
                      data: r,
                    });
                  }}
                >
                  {!hideUser && (
                    <React.Fragment>
                      <TableCell>{phone}</TableCell>
                      <TableCell
                        onClick={(e) => {
                          e.stopPropagation();
                          EventBus.$emit("open_client_window", client._id);
                        }}
                        sx={{
                          cursor: "pointer",
                          position: "sticky",
                          bgcolor: "#434242",
                          left: 0,
                        }}
                      >
                        <Typography variant="caption">{fullName}</Typography>
                      </TableCell>
                    </React.Fragment>
                  )}

                  <TableCell>{r.message}</TableCell>
                  <TableCell>
                    {moment(r.created_at).format("HH:mm, DD.MM.YYYY")}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
