export default function getTheme(theme) {
  switch (theme) {
    case "wishes":
      return "Пожелания";
    case "recalculation":
      return "Перерасчёт";
    case "other":
      return "Другое";
    default:
      return "";
  }
}
