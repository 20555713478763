import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Chip,
  Popover,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ButtonGroup,
  CircularProgress,
  Paper,
  Typography,
  Grid,
  IconButton,
  Divider,
} from "@mui/material";
import moment from "moment";
import "moment/locale/ru";
import GroupStore from "../../../store/group";
import TrainerStore from "../../../store/trainer";
import StagesStore from "../../../store/funnelStage";
import EditIcon from "@mui/icons-material/Edit";
import { EventBus } from "../../../event";
import groupGetFullInfo from "../../../common/groupGetFullInfo";
import StageCardChange from "../../../pages/Orders/StageCardChange";

export default function OrderCard({ data }) {
  let [order, setOrder] = useState(data);
  useEffect(() => {
    EventBus.$on("order_list_change", (ord) => {
      setOrder((o) => (o._id == ord._id ? ord : o));
    });
    return () => {
      EventBus.$off("order_list_change");
    };
  }, []);

  const [stageCardData, setStageCardData] = useState(null);
  const [anchorStageCard, setAnchorStageCard] = useState(null);
  let group = GroupStore.list.find((g) => g._id == order.group);
  if(!group) return null;
  let trainer = TrainerStore.list.find((t) => t._id == group.trainer);
  let st = StagesStore.list.find((s) => s._id == order.stage);

  const handleOpenStageCard = (anc, order) => {
    let isSuccess = order.isSuccess;

    if (isSuccess) {
      return;
    } else {
      setStageCardData(order);
      setAnchorStageCard(anc);
    }
  };

  const handleCloseStageCard = () => {
    setAnchorStageCard(null);
    setStageCardData(null);
  };

  return (
    <Card sx={{ p: 1 }} key={data._id}>
      <Popover
        id={anchorStageCard}
        open={!!anchorStageCard && !!stageCardData}
        anchorEl={anchorStageCard}
        onClose={handleCloseStageCard}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          sx: {
            borderRadius: "10px",
            overflow: "hidden",
          },
        }}
      >
        <StageCardChange
          closeHandler={handleCloseStageCard}
          changeStageOrder={(o) => setOrder(o)}
          data={stageCardData}
        />
      </Popover>
      <Typography gutterBottom variant="h6">
        {order.name}
      </Typography>
      <Typography gutterBottom variant="h6">
        {groupGetFullInfo(group)}
      </Typography>
      <Typography gutterBottom variant="body1">
        {trainer.name}
      </Typography>
      <Typography gutterBottom variant="body1">
        {`Дата посещения: ${moment(order.visitDate)
          .set("hour", group.visitingTime.split(":")[0])
          .set("minute", group.visitingTime.split(":")[1])
          .format("DD.MM.YYYY, в HH:mm")}`}
      </Typography>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "space-between",
          alingItems: "center",
        }}
      >
        <Box
          sx={{
            maxWidth: "max-content",
          }}
          onClick={(e) => handleOpenStageCard(e.currentTarget, order)}
        >
          {st && (
            <Chip
              sx={{
                borderRadius: "5px",
                color: st.textColor,
                bgcolor: st.backgroundColor,
              }}
              label={st.name}
            />
          )}
        </Box>
        {!order.isSuccess && (
          <IconButton
            onClick={() => EventBus.$emit("open_edit_order_window", order)}
          >
            <EditIcon />
          </IconButton>
        )}
      </Box>
    </Card>
  );
}
