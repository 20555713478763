import { React, useState, Fragment } from "react";
import {
  Box,
  AppBar as MuiAppBar,
  Toolbar,
  IconButton,
  useTheme,
  Typography,
  styled,
  Drawer as MuiDrawer,
  List,
  ListItem,
  Divider,
  ListItemText,
  Paper,
  useMediaQuery,
  ListItemIcon,
  Collapse,
  MenuItem,
  Menu,
  Avatar,
} from "@mui/material";
import { Link } from "react-router-dom";
import linkList from './list';



const styles = {
  listItem: {
    p: 1,
    pt: {
      xs: 0,
      md:1
    },

    // px: 2,
    display: "flex",
    justifyContent: "center",
    height: {
      xs: '100%',
      md: 'auto'
    }
  },
  listItemBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  listItemIcon: {
    color: "white",
  },
  listItemName: {
    mt: {
      xs: 1,
      md: 0
    },
    fontSize: "10px",
    color: "white",
  },
};

export default function DrawerMenu({ children }) {
  let [showSubMenu, setShowSubMenu] = useState("");
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.up("xs"));
  let isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  if (isDesktop) {
    isMobile = false;
  }

  console.log({ isMobile });

  function changeOpenMenu(name) {
    setShowSubMenu(showSubMenu === name ? "" : name);
  }



  return (
    <Box sx={{
      display: "flex", minHeight: "100vh",
      maxHeight: "100vh",
      position: {
        xs: 'relative',
        md: 'static'
      },
      flexDirection: {
        xs: 'column-reverse',
        md: 'row'
      }
    }}>

      <List
        sx={{
          bgcolor: "#434242",
          borderRadius: "0px",
          display: {
            xs: "flex",
            md: "block",
          },
          height: {
            xs: '90px',
            md: '100vh'
          },
          minHeight: {
            xs: '90px',
            md: '100vh'
          },

          width: {
            xs: '100vw',
            md: '90px'
          },
          minWidth: {
            xs: '100vw',
            md: '90px'
          },
          p: {
            xs: 0,
            md: 'auto'
          },
          position: {
            sx: 'fixed',
            md: 'static'
          },
          bottom: 0,
          left: 0,
          right: 0,
          // maxWidth: {
          //   xs: '200px',
          //   md: '100%'
          // },
          // minWidth: {
          //   xs: '200px',
          //   md: '100%'
          // },
          // minHeight: {
          //   xs: "auto",
          //   md: "100vh",
          // },
          // maxHeight: {
          //   xs: "100px",
          //   md: "100vh",
          // },
          overflowX: {
            xs: 'scroll',
            md: 'auto'
          },
          overflowY: {
            xs: 'auto',
            md: 'scroll'
          },
          alignItems: {xs: 'flex-start'}
          // scrollY: "auto",
          // overflowX: "auto",

        }}
      >
        {linkList.map((i) => {
          if (i.submenu) {
            return (
              <Fragment>
                <ListItem
                  component={Link}
                  onClick={() => changeOpenMenu(i.submenu)}
                  sx={styles.listItem}
                  button
                >
                  <Box sx={styles.listItemBox}>
                    <i.icon sx={styles.listItemIcon} />
                    <Typography
                      variant="body2"
                      sx={styles.listItemName}
                      align="center"
                    >
                      {i.title}
                    </Typography>
                  </Box>
                </ListItem>
                {showSubMenu === i.submenu && <Fragment> <Divider 
                  sx={{bgcolor: 'white'}}
                  orientation={isDesktop ?'horizontal': 'vertical'}
                
                />
                  <List sx={{ display: { xs: "flex", md: "block" },
                  alignItems: {xs: 'flex-start'}
                }}>
                    {i.list.map((r) => {
                      return (
                        <ListItem
                          component={Link}
                          to={r.link}ß
                          sx={styles.listItem}
                          button
                        >
                          <Box sx={styles.listItemBox}>
                            <r.icon sx={styles.listItemIcon} />
                            <Typography
                              variant="body2"
                              sx={styles.listItemName}
                              align="center"
                            >
                              {r.title}
                            </Typography>
                          </Box>
                        </ListItem>
                      );
                    })}
                  </List>
                  <Divider  orientation={isDesktop ?'horizontal': 'vertical'} sx={{bgcolor: 'white'}}/>

                </Fragment>}




              </Fragment>
            );
          } else {
            return (
              <ListItem
                component={Link}
                to={i.link}
                sx={styles.listItem}
                button
              >
                <Box sx={styles.listItemBox}>
                  <i.icon sx={styles.listItemIcon} />
                  <Typography
                    variant="body2"
                    sx={styles.listItemName}
                    align="center"
                  >
                    {i.title}
                  </Typography>
                </Box>
              </ListItem>
            );
          }
        })}
      </List>
      <Box sx={{
        flexGrow: 1, p: 3,
        // overflowX: 'scroll',
        overflowY: 'scroll',
        pb: 4

      }}>{children}</Box>
    </Box>
  );
}
