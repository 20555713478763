import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Container,
  Button,
  Grid,
  Popover,
  TextField,
  InputAdornment,
  styled,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DateRangePicker from "../../components/DateRangePicker";
import InputWrap from "../../components/InputWrap";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import "moment/locale/ru";
import getDateRangeText from "../../common/getDateRangeText";
import DescService from "../../services/Desc";
import Trainers from "./Trainers";
import Board from "./Board";

// Store
import ClubStore from "../../store/club";
import { observer } from "mobx-react-lite";

import Chart from "./Chart";

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    background-color: #434242;
  }
`;

function Home() {
  let clubId = ClubStore.currentClubId;
  const [anchorEl, setAnchorEl] = useState(null);
  let [stat, setStat] = useState(null);
  let [range, setRange] = useState({
    startDate: moment().startOf("month").valueOf(),
    endDate: moment().endOf("month").valueOf(),
  });

  useEffect(() => {
    fetchHandler();
  }, [clubId]);

  function fetchHandler() {
    DescService.getAll(range).then((r) => setStat(r.data));
  }

  const openPopover = (event, name) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom component="div">
        Рабочий стол
      </Typography>
      <Popover
        id={anchorEl}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <DateRangePicker
          startDate={range.startDate}
          endDate={range.endDate}
          setDate={(a, b) =>
            setRange(Object.assign({}, range, { startDate: a, endDate: b }))
          }
        />
      </Popover>

      <Grid alignItems="flex-end" container spacing={1}>
        <Grid item xs={12} md={2}>
          <InputWrap id="" label="Месяц">
            <StyledTextField
              onClick={openPopover}
              id="date"
              label=""
              fullWidth
              // sx={{ m: 0}}
              // className={classes.root}
              size="small"
              variant="outlined"
              name="month"
              type="text"
              value={getDateRangeText(range.startDate, range.endDate)}
            />
          </InputWrap>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            variant="contained"
            size="large"
            sx={{ py: "6.5px" }}
            startIcon={<SearchIcon />}
            onClick={fetchHandler}
          >
            Применить
          </Button>
        </Grid>
      </Grid>
      {stat && (
        <Box>
          <Board data={stat.board} s />
          <Chart chart={stat.chart} />
          <Grid sx={{ mt: 3 }} container spacing={2}>
            <Grid item xs={12} md={5}>
              <Typography variant="h6" gutterBottom>
                Топ тренеров
              </Typography>
              <Trainers list={stat.topTrainers} />
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}

export default observer(Home);
