import React, { useState, useEffect } from "react";
import { Box, SwipeableDrawer } from "@mui/material";
import SellMembership from "../SellMembership/SellMembership";
import EditMembership from "../SellMembership/Edit";

import { EventBus } from "../../event";
import ClientInfo from "../ClientInfo/ClientInfo";
import HistoryPaymentMembership from "../HistoryPaymentMembershipList";
import HistoryCashbackList from "../HistoryCashbackList";
import TrainerInfo from "../TrainerInfo";
import GroupInfo from "../GroupInfo";
import CreateOrder from "../Order/Create";
import ChangeOrder from "../Order/Change";

import ChargeClientBonus from "../ChargeClientBonus";
import EditClient from "../EditClient/EditClient";
import NewsEdit from "../NewsEdit";
import ClubEdit from "../ClubEdit/ClubEdit";
import MessageEdit from "../MessageEdit";
import Chat from "../Chat";

export default function Drawers() {
  let [editMembership, setEditMembership] = useState(null);
  let [editOrder, setEditOrder] = useState(null);
  let [showEditClient, setShowEditClient] = useState(null);
  let [showClientBonus, setShowClientBonus] = useState(null);
  let [showGroup, setShowGroup] = useState(null);
  let [showCreateOrder, setShowCreateOrder] = useState(false);
  let [showTrainer, setShowTrainer] = useState(null);
  let [showCreateSellSubscripe, setShowCreateSellSubscripe] = useState(null);
  let [showClientInfo, setShowClientInfo] = useState(null);
  let [showHistoryPaymentMembership, setShowHistoryPaymentMembership] =
    useState(null);
  let [showCashbackHistory, setShowCashbackHistory] = useState(null);
  let [showNewsEdit, setShowNewsEdit] = useState(null);
  let [showMessageEdit, setShowMessageEdit] = useState(null);
  let [showChat, setShowChat] = useState(null);
  let [showClubEdit, setShowClubEdit] = useState(null);

  useEffect(() => {
    EventBus.$on("open_edit_club", (d) => {
      setShowClubEdit(d);
    });
    EventBus.$on("open_edit_news", (d) => {
      setShowNewsEdit(d);
    });
    EventBus.$on("open_edit_message", (d) => {
      setShowMessageEdit(d);
    });
    EventBus.$on("open_chat", (d) => {
      setShowChat(d);
    });
    EventBus.$on("open_edit_memb_window", (d) => {
      setEditMembership(d);
    });
    EventBus.$on("open_edit_order_window", (order) => {
      setEditOrder(order);
    });
    EventBus.$on("open_edit_client_window", (client) => {
      setShowEditClient(client);
    });
    EventBus.$on("open_client_bonus_window", (id) => {
      setShowClientBonus(id);
    });
    EventBus.$on("open_create_order_window", () => {
      setShowCreateOrder(true);
    });
    EventBus.$on("open_group_window", (id) => {
      setShowGroup(id);
    });
    EventBus.$on("open_trainer_window", (id) => {
      setShowTrainer(id);
    });
    EventBus.$on("open_client_window", (id) => {
      setShowClientInfo(id);
    });
    EventBus.$on("open_create_subscripe_window", (data) => {
      setShowCreateSellSubscripe(data);
    });
    EventBus.$on("open_history_payment_membership_window", (id) => {
      setShowHistoryPaymentMembership(id);
    });
    EventBus.$on("open_history_cashback_window", (id) => {
      setShowCashbackHistory(id);
    });

    return () => {
      EventBus.$off("open_edit_club");
      EventBus.$off("open_chat");
      EventBus.$off("open_edit_news");
      EventBus.$off("open_edit_message");
      EventBus.$off("open_edit_memb_window");
      EventBus.$off("open_edit_order_window");
      EventBus.$off("open_edit_client_window");
      EventBus.$off("open_client_bonus_window");
      EventBus.$off("open_create_order_window");
      EventBus.$off("open_group_window");
      EventBus.$off("open_trainer_window");
      EventBus.$off("open_client_window");
      EventBus.$off("open_create_subscripe_window");
      EventBus.$off("open_history_payment_membership_window");
      EventBus.$off("open_history_cashback_window");
    };
  }, []);

  return (
    <Box>
      <SwipeableDrawer
        anchor="right"
        open={!!showClubEdit}
        onClose={() => {
          setShowClubEdit(null);
        }}
      >
        <ClubEdit
          data={showClubEdit}
          closeHandler={() => setShowClubEdit(null)}
        />
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor="right"
        open={!!showNewsEdit}
        onClose={() => {
          setShowNewsEdit(null);
        }}
      >
        <NewsEdit
          data={showNewsEdit}
          closeHandler={() => setShowNewsEdit(null)}
        />
      </SwipeableDrawer>

        <SwipeableDrawer
            anchor="right"
            open={!!showMessageEdit}
            onClose={() => {
                setShowMessageEdit(null);
            }}
        >
            <MessageEdit
                data={showMessageEdit}
                closeHandler={() => setShowMessageEdit(null)}
            />
        </SwipeableDrawer>

        <SwipeableDrawer
            anchor="right"
            open={!!showChat}
            onClose={() => {
                setShowChat(null);
            }}
        >
            <Chat
                data={showChat}
                closeHandler={() => setShowChat(null)}
            />
        </SwipeableDrawer>

      <SwipeableDrawer
        anchor="right"
        open={!!editMembership}
        onClose={() => {
          setEditMembership(null);
        }}
      >
        <EditMembership
          data={editMembership}
          closeHandler={() => setEditMembership(null)}
        />
      </SwipeableDrawer>
      <SwipeableDrawer
        anchor="right"
        open={!!showEditClient}
        onClose={() => {
          setShowEditClient(null);
        }}
      >
        <EditClient
          clientData={showEditClient}
          closeHandler={() => setShowEditClient(null)}
        />
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor="right"
        open={!!editOrder}
        onClose={() => {
          setEditOrder(null);
        }}
      >
        <ChangeOrder data={editOrder} closeHandler={() => setEditOrder(null)} />
      </SwipeableDrawer>
      <SwipeableDrawer
        anchor="right"
        open={!!showClientBonus}
        onClose={() => {
          setShowClientBonus(null);
        }}
      >
        <ChargeClientBonus
          id={showClientBonus}
          closeHandler={() => setShowClientBonus(null)}
        />
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor="right"
        open={!!showCreateOrder}
        onClose={() => {
          setShowCreateOrder(null);
        }}
      >
        <CreateOrder closeHandler={() => setShowCreateOrder(null)} />
      </SwipeableDrawer>
      <SwipeableDrawer
        anchor="right"
        open={!!showGroup}
        onClose={() => {
          setShowGroup(null);
        }}
      >
        <GroupInfo id={showGroup} closeHandler={() => setShowGroup(null)} />
      </SwipeableDrawer>
      <SwipeableDrawer
        anchor="right"
        open={!!showTrainer}
        onClose={() => {
          setShowTrainer(null);
        }}
      >
        <TrainerInfo
          id={showTrainer}
          closeHandler={() => setShowTrainer(null)}
        />
      </SwipeableDrawer>
      <SwipeableDrawer
        anchor="right"
        open={!!showClientInfo}
        onClose={() => {
          setShowClientInfo(null);
        }}
      >
        <ClientInfo
          clientId={showClientInfo}
          closeHandler={() => setShowClientInfo(null)}
          // addClient={addClient}
          // closeHandler={() => setAddClientShow(false)}
        />
        {/* <CreateSellSubscripe /> */}
      </SwipeableDrawer>
      <SwipeableDrawer
        anchor="right"
        open={!!showCreateSellSubscripe}
        onClose={() => {
          setShowCreateSellSubscripe(null);
        }}
      >
        <SellMembership
          data={showCreateSellSubscripe}
          closeHandler={() => setShowCreateSellSubscripe()}
        />
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor="right"
        open={!!showHistoryPaymentMembership}
        onClose={() => {
          setShowHistoryPaymentMembership(null);
        }}
      >
        <HistoryPaymentMembership
          clientId={showHistoryPaymentMembership}
          closeHandler={() => setShowHistoryPaymentMembership(null)}
        />
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor="right"
        open={!!showCashbackHistory}
        onClose={() => {
          setShowCashbackHistory(null);
        }}
      >
        <HistoryCashbackList
          clientId={showCashbackHistory}
          closeHandler={() => setShowCashbackHistory(null)}
        />
      </SwipeableDrawer>
    </Box>
  );
}
