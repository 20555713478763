import axios from "axios";

class Club {
  add(data) {
    return axios.post(`/club`, data);
  }
  getClub(data) {
    return axios.get(`/club`);
  }
  changeClub(data) {
    return axios.put(`/club`, data);
  }
}

export default new Club({});
