import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  Paper,
  TableRow,
} from "@mui/material";
import SidebarWrap from "./SidebarWrap";
import moment from "moment";
import "moment/locale/ru";

import HistoryPayment from "../services/HistoryPayment";

export default function HistoryPaymentMembershipList({
  clientId,
  closeHandler,
}) {
  let [list, setList] = useState([]);
  useEffect(() => {
    HistoryPayment.getAll({ client: clientId, mode: "cashback" }).then((r) => {
      let d = r.data;
      setList(d);
    });
  }, []);


  return (
    <SidebarWrap closeHandler={closeHandler} title="История начислений">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableCell>Название</TableCell>
            <TableCell>Cумма</TableCell>
            <TableCell>Дата оплаты</TableCell>
          </TableHead>
          <TableBody>
            {list.map((r) => {
              return (
                <TableRow key={r._id}>
                  <TableCell>{r.name}</TableCell>
                  <TableCell>{r.amount}</TableCell>
                  <TableCell>
                    {moment(r.createdAt).format("DD.MM.YYYY, в HH:mm")}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </SidebarWrap>
  );
}
