import React, { useState } from "react";
import {
  TextField,
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ButtonGroup,
  Tab,
  Tabs,
  CircularProgress,
  Paper,
  Typography,
  Grid,
  IconButton,
  Divider,
} from "@mui/material";
import OrderList from "./OrderList/OrderList";
import MembershipList from "./MembershipList/MembershipList";
import PaymentHistory from "./PaymentHistory/PaymentHistory";
import Communication from "./Communication/Communication";

export default function TabsSection({ clientId }) {
  const [currentTab, setCurrentTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  function viewSection(n) {
    switch (n) {
      case 0:
        return <MembershipList clientId={clientId} />;
      case 1:
        return <OrderList clientId={clientId} />;
      case 2:
        return <PaymentHistory clientId={clientId} />;
      case 3:
        return <Communication clientId={clientId} />;
    }
  }

  return (
    <Box>
      <Tabs value={currentTab} onChange={handleChangeTab}>
        <Tab label="Абонементы" />
        <Tab label="Заявки" />
        <Tab label="Финансы" />
        <Tab label="Коммуникация" />
      </Tabs>
      <Box sx={{ p: 1 }}>{viewSection(currentTab)}</Box>
    </Box>
  );
}
