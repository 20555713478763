import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Typography,
  CircularProgress,
  SwipeableDrawer,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Tabs,
  Tab,
  Grid,
} from "@mui/material";
import Info from "./Info";
import SmsTable from "./Table";

// import PushService from "../../../services/Push";
// import PushTable from "../../../components/PushTable/PushTable";

function Push() {
  //   let [list, setList] = useState([]);
  //   useEffect(() => {
  //     PushService.getAll().then((r) => setList(r.data));
  //   }, []);
  return (
    <Box sx={{ p: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Info />
        </Grid>
        <Grid item xs={12} md={9}>
          <SmsTable />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Push;
