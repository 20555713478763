import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import PushService from "../../../services/Push";
import PushTable from "../../PushTable/PushTable";
import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
import { EventBus } from "../../../event";

export default function Push({ clientId }) {
  let [list, setList] = useState([]);
  useEffect(() => {
    PushService.getByClient(clientId).then((r) => setList(r.data));
  }, []);

  return (
    <Box>
      <Button
        size="small"
        color="secondary"
        sx={{ mb: 2 }}
        variant="contained"
        startIcon={<MarkChatUnreadIcon size="large" />}
        onClick={(e) => {
          EventBus.$emit("modal_client_action", {
            action: "push-notify",
            data: [clientId],
          });
        }}
      >
        Отправить
      </Button>
      <PushTable list={list} hideUser />
    </Box>
  );
}
