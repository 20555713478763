import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Container,
  Table,
  CircularProgress,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Typography,
  SwipeableDrawer,
  Button,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import StageFilter from "../../components/StageFilter";
import OrderTable from "./OrderTable";
import Filter from "./Filter";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import moment from "moment";
import "moment/locale/ru";

import OrderService from "../../services/Order";
import FunnelStage from "../../services/FunnelStage";
import OrderSourceService from "../../services/Source";
import TrainerService from "../../services/Trainer";
import GroupService from "../../services/Group";
import { EventBus } from "../../event";
import { socket } from "../../socket";

// Store
import ClubStore from "../../store/club";
import { observer } from "mobx-react-lite";

let initFilterData = {
  name: "",
  clientName: "",
  childName: "",
  phone: "",
  trainer: [],
  group: [],
  stage: [],
  created: {
    startDate: moment().subtract(1, "month").startOf("month").valueOf(),
    endDate: moment().endOf("month").valueOf(),
  },
  visitDay: {
    startDate: moment().subtract(1, "month").startOf("month").valueOf(),
    endDate: moment().endOf("month").valueOf(),
  },
};

function Orders() {
  let [isSubmit, setSubmit] = useState(true);
  let [orderList, setOrderList] = useState([]);

  let [showFilter, setShowFilter] = useState(true);
  let [stat, setStat] = useState({});
  let [filterData, setFilterData] = useState(initFilterData);
  let [paginData, setPaginData] = useState({
    page: 0,
    rowsPerPage: 5,
    countRows: 10,
  });

  let clubId = ClubStore.currentClubId;

  useEffect(() => {
    fetchHandler();
  }, [clubId]);

  useEffect(() => {
    fetchHandler();
    socket.on("add_order", (order) => {
      addOrderHandler(order);
    });

    socket.on("update_order", (order) => {
      updateOrderHandler(order);
    });

    socket.on("update_client", (client) => {
      updateClientHandler(client);
    });

    return () => {
      socket.off("add_order");
      socket.off("update_order");
      socket.off("update_client");
    };
  }, [paginData.page, paginData.rowsPerPage]);

  function addOrderHandler(order) {
    console.log(order, "order add");
    setOrderList((orders) => {
      return [order, ...orders];
    });
  }

  function updateOrderHandler(order) {
    setOrderList((orders) => {
      let newOrders = [...orders];
      return newOrders.map((o) => {
        let oId = o._id + "";
        let ordId = order._id + "";
        if (oId == ordId) {
          return Object.assign({}, order);
        } else {
          return Object.assign({}, o);
        }
      });
    });
  }

  function updateClientHandler(c) {
    setOrderList((orders) => {
      let newOrders = [...orders];
      return newOrders.map((o) => {
        if (o.client._id == c._id) {
          return Object.assign({}, o, { client: c });
        } else {
          return Object.assign({}, o);
        }
      });
    });
  }

  function changePaginData(name, value) {
    let newPaginData = Object.assign({}, paginData, { [name]: value });
    setPaginData(newPaginData);
  }
  const handleChangePage = (event, newPage) => {
    changePaginData("page", newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let val = parseInt(event.target.value, 10);
    setPaginData(
      Object.assign({}, paginData, {
        page: 0,
        rowsPerPage: val,
      })
    );
  };

  function changeFilterDataHandler(name, value) {
    let newFilterData = Object.assign({}, filterData, { [name]: value });
    setFilterData(newFilterData);
  }

  function fetchHandler(f) {
    setSubmit(true);
    OrderService.getAll({ filter: f || filterData, pagin: paginData }).then(
      (res) => {
        let data = res.data;
        setOrderList(data.orders);
        setStat(data.stat);
        changePaginData("countRows", data.pagin.countRows);
        setSubmit(false);
      }
    );
  }

  function changeStageOrder(upd) {
    let newOrderList = [...orderList];
    newOrderList = newOrderList.map((o) => {
      if (o._id == upd._id) {
        return upd;
      } else {
        return o;
      }
    });
    setOrderList(newOrderList);
  }

  function clearFilterHandler() {
    setFilterData(initFilterData);
  }

  function findHandler() {
    fetchHandler();
  }

  function setFilterHandler(id) {
    let obj = Object.assign({}, initFilterData, { stage: [id] });
    setFilterData(obj);
    fetchHandler(obj);
  }

  return (
    <Box>
      {/* <SwipeableDrawer
        anchor="right"
        open={showOrder}
        onClose={closeOrderHandler}
      >
        <CreateOrder addOrder={addOrder} closeHandler={closeOrderHandler} />
      </SwipeableDrawer> */}
      <Typography variant="h5" gutterBottom>
        Заявки
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
        <Button
          size="small"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => EventBus.$emit("open_create_order_window")}
        >
          Создать
        </Button>
        <Button
          size="small"
          sx={{ ml: 2 }}
          variant="outlined"
          startIcon={<FilterAltIcon />}
          onClick={() => setShowFilter(!showFilter)}
        >
          Фильтр
        </Button>
      </Box>

      {showFilter && (
        <Filter
          filterData={filterData}
          changeFilterHandler={changeFilterDataHandler}
          clearFilterHandler={clearFilterHandler}
          findHandler={findHandler}
        />
      )}

      {stat && (
        <StageFilter
          mode="order"
          setFilterHandler={setFilterHandler}
          stat={stat}
        />
      )}

      {isSubmit && (
        <Box sx={{ mt: 4 }}>
          <CircularProgress
            size={60}
            sx={{ m: "20px auto", display: "block" }}
            disableShrink
          />
        </Box>
      )}

      {!isSubmit && (
        <OrderTable
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          paginData={paginData}
          list={orderList}
          changeStageOrder={changeStageOrder}
        />
      )}
    </Box>
  );
}

export default observer(Orders);
