import React, { useState, useEffect } from "react";
import { Card, Box, Typography, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { EventBus } from "../../event";
import List from "./List";
import clubStore from "../../store/club";
import { observer } from "mobx-react-lite";

function Club() {
  let list = clubStore.list;

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Клубы
      </Typography>
      <Button
        sx={{ marginBottom: "20px" }}
        size="small"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => EventBus.$emit("open_edit_club", {})}
      >
        Добавить
      </Button>

      {list.length && <List list={list} />}
    </Box>
  );
}

export default observer(Club);
