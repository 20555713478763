import React  from "react";
import {

  TableContainer,
  Paper,
  Table,
  Avatar,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { EventBus } from "../../event";

import { API_IMAGES } from "../../config";

export default function List({ list }) {
  return (
    <TableContainer >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Название</TableCell>
            <TableCell>Адрес</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((t) => (
            <TableRow key={t._id}>
              <TableCell>
                <Avatar
                  src={API_IMAGES + t.photo}
                  style={{ margin: "0px auto" }}
                />
              </TableCell>
              <TableCell>{t.name}</TableCell>
              <TableCell>{t.address}</TableCell>
              <TableCell align="right">
                <IconButton onClick={() => EventBus.$emit("open_edit_club", t)}>
                  <EditIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
