import { makeObservable, observable, action } from "mobx";

class Trainer {
  list = [];
  constructor() {
    makeObservable(this, {
      list: observable,
      setTrainerList: action,
      addTrainer: action,
      changeTrainer: action,
    });
  }
  setTrainerList(data) {
    this.list = data;
  }
  addTrainer(trainer) {
    this.list = [trainer, ...this.list];
  }
  changeTrainer(trainer) {
    this.list = this.list.map((t) => {
      if (t._id == trainer._id) {
        return trainer;
      } else {
        return t;
      }
    });
  }
}

const trainerStore = new Trainer();

export default trainerStore;
