import axios from "axios";

class Trainer {
  getTrainers() {
    return axios.get(`/trainer`);
  }
  createTrainer(data) {
    return axios.post(`/trainer`, data);
  }
  changeTrainer(data) {
    return axios.put(`/trainer`, data);
  }
  changeHide(data) {
    return axios.put(`/trainer/hide`, data);
  }
  deleteTrainer(data) {
    return axios.post(`/trainer/delete`, data);
  }
}

export default new Trainer({});
