import React from "react";
import {
  TextField,
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ButtonGroup,
  CircularProgress,
  Paper,
  Typography,
  Grid,
  IconButton,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AboutSection from "./AboutSection";
import Tabs from "./Tabs";
import SidebarWrap from "../SidebarWrap";

function ClientInfo({ clientId, closeHandler }) {
  return (
    <SidebarWrap title="О клиенте" large closeHandler={closeHandler}>
      <Grid spacing={2} container>
        <Grid item xs={12} md={3}>
          <AboutSection id={clientId} closeHandler={closeHandler} />
        </Grid>
        <Grid item xs={12} md={9}>
          <Tabs clientId={clientId} />
        </Grid>
      </Grid>
    </SidebarWrap>
  );
}
export default ClientInfo;
