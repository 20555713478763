import axios from "axios";

class FunnelStage {
  getAll() {
    return axios.get(`/funnel-stage`);
  }
  create(data) {
    return axios.post(`/funnel-stage`, data);
  }
  change(data) {
    return axios.put(`/funnel-stage`, data);
  }
  delete(id) {
    return axios.delete(`/funnel-stage`, { data: { id } });
  }
}

export default new FunnelStage({});
