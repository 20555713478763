import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Grid,
  TextField,
  Popover,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import InputWrap from "../../components/InputWrap";
import DateRangePicker from "../../components/DateRangePicker";
import getDateRangeText from "../../common/getDateRangeText";
import groupGetFullInfo from "../../common/groupGetFullInfo";

import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import TrainerStore from "../../store/trainer";
import GroupStore from "../../store/group";
import ClubStore from "../../store/club";

function getGroupListByTrainers(trainerId, groupListData) {
  if (!trainerId) return [];
  if (groupListData.length == 0) return [];
  let result = groupListData.filter((g) => g.trainer == trainerId);
  return result;
}

export default function Filter({
  changeFilterData,
  filterData,
  clearFilterHandler,
  findHandler,
}) {
  let currentClubId = ClubStore.currentClubId;
  let trainers = TrainerStore.list;
  let groups = [...GroupStore.list].filter((g) => g.club == currentClubId);
  const [anchorEl, setAnchorEl] = useState(null);
  let [popoverViewName, setPopoverViewName] = useState("");

  const openPopover = (event, name) => {
    setPopoverViewName(name);
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
    setPopoverViewName("");
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  let filterGroup = getGroupListByTrainers(filterData.trainer, groups);

  function viewPopover() {
    switch (popoverViewName) {
      case "month":
        return (
          <DateRangePicker
            startDate={filterData.month.startDate}
            endDate={filterData.month.endDate}
            setDate={(a, b) =>
              changeFilterData("month", { startDate: a, endDate: b })
            }
          />
        );
      default:
        return null;
    }
  }

  return (
    <Box sx={{ mb: 4 }}>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {viewPopover()}
      </Popover>
      <Grid alignItems="flex-end" container spacing={1}>
        <Grid item xs={12} md={2}>
          <InputWrap id="month" label="Месяц">
            <TextField
              onClick={(e) => openPopover(e, "month")}
              id="month"
              label=""
              fullWidth
              sx={{ m: 0 }}
              size="small"
              variant="outlined"
              name="month"
              type="text"
              value={getDateRangeText(
                filterData.month.startDate,
                filterData.month.endDate
              )}
            />
          </InputWrap>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            variant="contained"
            size="large"
            sx={{ py: "6.5px" }}
            startIcon={<SearchIcon />}
            onClick={findHandler}
          >
            Применить
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
