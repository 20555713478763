import moment from "moment";
import "moment/locale/ru";

export default function getCalculateAmountVisit(fromDate, toDate, visitDays) {
  let days = 0;
  let fromDateNumber = moment(fromDate).format("DD");
  let toDateNumber = moment(toDate).format("DD");

  for (let i = fromDateNumber; i <= toDateNumber; i++) {
    let dt = moment(new Date(fromDate)).set("date", i).isoWeekday();
    if (visitDays.includes(dt)) {
      days += 1;
    }
  }

  return days;
}
