import React from "react";
import {
  Box,
  Typography,
  Slider,
  IconButton,
  Button,
  TextField,
  MenuItem,
  Select,
  ListItemIcon,
  Avatar,
  CircularProgress,
} from "@mui/material";

export default function DaysPicker({ days, setDays }) {
  let daysNames = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];

  console.log(days, "days");

  days.sort();

  function changeDays(n) {
    let ind = days.indexOf(n);
    let newDays = [...days];

    if (ind !== -1) {
      newDays.splice(ind, 1);
    } else {
      newDays.push(n);
    }
    setDays(newDays);
  }

  function checkEnabled(n) {
    return days.includes(n);
  }

  return (
    <Box sx={{ mt: 0 }}>
      {daysNames.map((name, i) => {
        return (
          <Button
            size="small"
            key={i}
            sx={{ margin: "5px" }}
            variant={checkEnabled(i + 1) ? "contained" : "outlined"}
            onClick={() => changeDays(i + 1)}
          >
            {name}
          </Button>
        );
      })}
    </Box>
  );
}
