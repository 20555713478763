import React from "react";
import { Box, MenuList, MenuItem } from "@mui/material";
import ClientMembershipService from "../../../services/ClientMembership";
import StageCardInfo from "./StageCardInfo";
import { useSnackbar } from "notistack";
import FunnelStageStore from "../../../store/funnelStage";

export default function StageCard({ data, closeHandler, month, changeMember }) {
  const { enqueueSnackbar } = useSnackbar();

  let stageList = FunnelStageStore.membershipStages;

  if (!data) return "";

  let isPayment = data.isPayment;

  let stage = stageList.find((s) => s._id == data.stage);

  if (!stage) return "-";

  function changeStageHandler(id) {
    ClientMembershipService.changeStage({
      clientId: data.client,
      month,
      membership: data._id,
      stage: id,
    }).then((r) => {
      let m = r.data;
      enqueueSnackbar("Этап изменён!", { variant: "success" });
      changeMember(m);
      closeHandler();
    });
  }

  function MenuStages() {
    return (
      <MenuList sx={{ p: 0 }}>
        {stageList
          .filter((s) => !s.isSuccess)
          .map((s) => (
            <MenuItem
              onClick={() => changeStageHandler(s._id)}
              sx={{
                bgcolor: s.backgroundColor,
                color: s.textColor,
                "&:hover": { bgcolor: s.backgroundColor, opacity: 0.7 },
              }}
            >
              {s.name}
            </MenuItem>
          ))}
      </MenuList>
    );
  }

  return (
    <Box sx={{ minWidth: "100%" }}>
      {isPayment ? <StageCardInfo data={data} /> : <MenuStages />}
    </Box>
  );
}
