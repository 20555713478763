import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Typography,
  CircularProgress,
  SwipeableDrawer,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Tabs,
  Tab,
  Grid,
} from "@mui/material";
import SmsService from "../../../services/Sms";

export default function Info() {
  let [balance, setBalance] = useState(null);
  useEffect(() => {
    SmsService.getBalance().then((r) => setBalance(r.data));
  }, []);

  if (!balance) return null;
  return (
    <Box sx={{ p: 1 }} component={Paper}>
      <Typography variant="body1">Баланс:</Typography>
      <Typography variant="h5" fontWeight="bold">
        {balance} руб.
      </Typography>
    </Box>
  );
}
