import React from "react";
import { Box, Typography, Alert } from "@mui/material";
import UploadChatImage from "../UploadImage/UploadChatImage";

export default function Preview({ setImage, image, url, error, setError }) {
  function changeImageHandler(img) {
    setError("");
    setImage(img);
  }

  return (
    <Box>
      <UploadChatImage url={url} image={image} setImage={changeImageHandler} />
    </Box>
  );
}
