import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Loader from "../../components/Loader/Loader";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSnackbar } from "notistack";

import QuestionService from "../../services/Question";

function Questioning() {
  const { enqueueSnackbar } = useSnackbar();
  let [list, setList] = useState([]);
  let [loading, setLoading] = useState(true);
  let [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let res = await QuestionService.getAll();
        let data = res.data;
        setList(data);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    maxWidth: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "5px 20px 20px 20px",
    maxHeight: "85vh",
    "overflow-y": "scroll",
  };

  function addQuestion(q) {
    setList([q, ...list]);
  }

  async function deleteQuestion(id) {
    try {
      await QuestionService.delete(id);
      enqueueSnackbar("Вопрос удалён!", { variant: "success" });
      let newList = list.filter((q) => q._id !== id);
      setList(newList);
    } catch (e) {
      console.error(e);
    }
  }

  if (loading) return <Loader />;

  return (
    <Container sx={{ marginTop: "20px" }} maxWidth="lg">
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <Box sx={modalStyle}>
          <AddQuestionForm
            addQuestion={addQuestion}
            closeHandler={() => setShowModal(false)}
          />
        </Box>
      </Modal>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Button
            sx={{ marginBottom: "20px" }}
            size="large"
            variant="outlined"
            fullWidth
            startIcon={<AddIcon />}
            onClick={() => setShowModal(true)}
          >
            Добавить
          </Button>
        </Grid>
      </Grid>
      <Box>
        {list.map((q) => {
          return (
            <Accordion key={q._id}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{q.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {q.answers.map((a, i) => {
                  return <Typography key={i}>{`${i + 1}. ${a}`}</Typography>;
                })}
                <IconButton
                  onClick={() => deleteQuestion(q._id)}
                  sx={{ float: "right" }}
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </Container>
  );
}

function AddQuestionForm({ closeHandler, addQuestion }) {
  const { enqueueSnackbar } = useSnackbar();
  let [loader, setLoader] = useState(false);
  let [question, setQuestion] = useState("");
  let [answer1, setAnswer1] = useState("");
  let [answer2, setAnswer2] = useState("");
  let [alert, setAlert] = useState("");

  async function submitHandler() {
    try {
      setLoader(true);
      let reqData = { question, answer1, answer2 };
      let res = await QuestionService.create(reqData);
      let data = res.data;
      addQuestion(data);
      enqueueSnackbar("Вопрос добавлен!", { variant: "success" });
      closeHandler();
    } catch (e) {
      console.error(e);
      const message = e.response && e.response.data && e.response.data.message;
      if (message) {
        setAlert(message);
      }
    } finally {
      setLoader(false);
    }
  }

  return (
    <>
      <TextField
        value={question}
        fullWidth
        margin="dense"
        label="Вопрос"
        size="small"
        variant="filled"
        name="question"
        type="string"
        sx={{ marginBottom: "20px" }}
        onChange={(e) => setQuestion(e.target.value)}
      />
      <TextField
        value={answer1}
        fullWidth
        margin="dense"
        label="Ответ 1"
        size="small"
        variant="filled"
        name="answer1"
        type="string"
        onChange={(e) => setAnswer1(e.target.value)}
      />
      <TextField
        value={answer2}
        fullWidth
        margin="dense"
        label="Ответ 2"
        size="small"
        variant="filled"
        name="answer2"
        type="string"
        onChange={(e) => setAnswer2(e.target.value)}
      />
      {alert && (
        <Alert sx={{ margin: "10px 0px" }} severity="error">
          {alert}
        </Alert>
      )}
      <Button
        sx={{ marginTop: "20px" }}
        size="large"
        variant="outlined"
        fullWidth
        disabled={loader}
        onClick={submitHandler}
      >
        Добавить
      </Button>
    </>
  );
}

export default Questioning;
