import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  TextField,
  InputLabel,
  CircularProgress,
  Typography,
  IconButton,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  ButtonGroup,
  Button,
} from "@mui/material";
import ChoiceVisitDays from "../ChoiceVisitDays";
import GroupService from "../../services/Group";
import InputWrap from "../InputWrap";
import getDayNameByNumer from "../../common/getDayNameByNumer";
import groupGetFullInfo from "../../common/groupGetFullInfo";

import moment from "moment";
import "moment/locale/ru";
import CalculateSum from "./CalculateSum";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ClientMembership from "../../services/ClientMembership";

import { useSnackbar } from "notistack";
import getTotalSum from "./methods/getTotalSum";
import GroupStore from "../../store/group";
import ClubStore from "../../store/club";

import TrainerStore from "../../store/trainer";
import { EventBus } from "../../event";
import Discount from "./Discount";

export default function NewSellMembership({ clientId, closeHandler }) {
  let currentClubId = ClubStore.currentClubId;
  let groupList = [...GroupStore.list].filter((g) => g.club == currentClubId);
  let trainerList = TrainerStore.list;
  const { enqueueSnackbar } = useSnackbar();
  let [asBonus, setAsBonus] = useState(false);

  let [isSubmiting, setSubmiting] = useState(false);
  let [hasDiscount, setHasDiscount] = useState(false);
  let [discountSum, setDiscountSum] = useState(0);
  let [data, setData] = useState({
    group: null,
    visitDays: null,
    startVisit: "",
  });

  function changeDataHandler(name, value) {
    let newData = Object.assign({}, data, { [name]: value });
    setData(newData);
  }

  if (!data.group) {
    changeDataHandler("group", groupList[0]._id);
  }

  function changeToggleDay(name) {
    let oldValue = data.visitDays[name];
    changeDataHandler(
      "visitDays",
      Object.assign({}, data.visitDays, { [name]: !oldValue })
    );
  }

  let selectedGroup = groupList.find((g) => g._id == data.group);
  if (!selectedGroup) {
    selectedGroup = groupList[0];
    changeDataHandler("group", groupList[0]._id);
  }

  // useMeme

  let groupId = selectedGroup?._id;
  let groupClubId = selectedGroup?.club;

  useEffect(() => {
    if (groupId) {
      let r = {};
      selectedGroup.visitingDays.forEach((d) => (r[d] = true));
      changeDataHandler("visitDays", r);
    }
  }, [groupId]);

  // if (!groupClubId) return;

  ///////
  let totalSum = useMemo(
    () =>
      getTotalSum(
        data.startVisit,
        data.visitDays,
        groupClubId || "",
        hasDiscount,
        discountSum
      ),
    [data.startVisit, data.visitDays, hasDiscount, discountSum]
  );

  ////////
  function createHandler() {
    let visitDays = [];
    for (let v in data.visitDays) {
      if (data.visitDays[v]) {
        visitDays.push(v);
      }
    }
    let fetchData = Object.assign({}, data, {
      client: clientId,
      visitDays,
      totalSum,
      asBonus: asBonus && totalSum < 3000,
    });
    console.log(fetchData, "fetchData");
    setSubmiting(true);
    ClientMembership.create(fetchData)
      .then((r) => {
        // let d = r.data;
        EventBus.$emit("update_client_window");
        enqueueSnackbar("Абонемент продан!", { variant: "success" });
        closeHandler();
      })
      .finally(() => {
        setSubmiting(false);
      });
  }

  return (
    <Box>
      <InputWrap id="group" label="Группа" wrapStyle={{ my: 1 }}>
        <Select
          value={data.group + ""}
          fullWidth
          id="group"
          size="small"
          onChange={(e) =>
            setData(
              Object.assign({}, data, {
                group: e.target.value + "",
                visitDays: null,
              })
            )
          }
        >
          {groupList.map((d) => (
            <MenuItem key={d._id} value={d._id}>
              {groupGetFullInfo(d)}
            </MenuItem>
          ))}
        </Select>
      </InputWrap>
      {selectedGroup && data.visitDays && (
        <ChoiceVisitDays
          days={data.visitDays}
          changeToggleDay={changeToggleDay}
        />
      )}

      <InputWrap
        id="startVisit"
        label="Дата начала посещения"
        wrapStyle={{ my: 1 }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment} locale="ru">
          <DesktopDatePicker
            // disablePast
            inputFormat="DD.MM.YYYY"
            value={data.startVisit}
            onChange={(d) =>
              changeDataHandler("startVisit", d.format().split("T")[0])
            }
            renderInput={(params) => (
              <TextField
                size="small"
                id="startVisit"
                placeholder="ДД.ММ.ГГГГ"
                fullWidth
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      </InputWrap>

      <FormControlLabel
        control={
          <Checkbox
            checked={hasDiscount}
            onChange={() => setHasDiscount(!hasDiscount)}
          />
        }
        label="Скидка"
      />
      {hasDiscount && (
        <Discount sum={discountSum} setSum={(v) => setDiscountSum(v)} />
      )}

      {data.visitDays && data.startVisit && (
        <CalculateSum totalSum={totalSum} />
      )}

      {totalSum < 3000 && (
        <FormControlLabel
          control={
            <Checkbox checked={asBonus} onChange={() => setAsBonus(!asBonus)} />
          }
          label="Начислить бонусы"
        />
      )}
      <Button
        fullWidth
        sx={{ marginTop: 2 }}
        size="medium"
        variant="outlined"
        onClick={createHandler}
        startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
        disabled={isSubmiting || !totalSum}
      >
        Оплатить
      </Button>
    </Box>
  );
}
