import moment from "moment";
import "moment/locale/ru";

export default function getDateRangeText(from, to) {
  if (!from || !to) return "";

  from = new Date(from);
  to = new Date(to);

  let fromText = moment(from).format("DD.MM.YYYY");
  let toText = moment(to).format("DD.MM.YYYY");

  return `${fromText} - ${toText}`;
}
