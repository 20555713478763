import axios from "axios";

class Feedback {
  getAll(data) {
    return axios.get(`/feedback`);
  }
  changeViewed(id) {
    return axios.put(`/feedback`, { id });
  }
  getByClient(clientId) {
    return axios.get(`/feedback/one`, { params: { clientId } });
  }
}

export default new Feedback({});
