import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Divider } from "@mui/material";
import HistoryPaymentService from "../../../services/HistoryPayment";
import { EventBus } from "../../../event";

function InfoItem({ name, onClick, value, isBold }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        my: 1,
      }}
      onClick={onClick}
    >
      <Typography variant="body1" fontWeight="medium">
        {name}
      </Typography>
      <Typography variant="body1" fontWeight={isBold ? "bold" : "regular"}>
        {value}
      </Typography>
    </Box>
  );
}

export default function HistoryPayment({ clientId }) {
  let [stat, setStat] = useState();

  useEffect(() => {
    HistoryPaymentService.getClientStat({ client: clientId }).then((r) =>
      setStat(r.data)
    );
  }, []);

  if (!stat) return null;
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <InfoItem
            onClick={() =>
              EventBus.$emit("open_history_payment_membership_window", clientId)
            }
            name="Сумма по абонементам"
            isBold
            value={stat.membership || 0}
          />
          <Divider />
          <InfoItem
            onClick={() =>
              EventBus.$emit("open_history_cashback_window", clientId)
            }
            name="Бонусы"
            isBold
            value={stat.cashback || 0}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
