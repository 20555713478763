import axios from "axios";

class Client {
  create(data) {
    return axios.post(`/client`, data);
  }
  change(data) {
    return axios.put(`/client`, data);
  }
  changeStatus(data) {
    return axios.put(`/client/status`, data);
  }
  changePhone(data) {
    return axios.put(`/client/phone`, data);
  }
  getAll(query = {}) {
    return axios.get(`/client`, { params: query });
  }
  search(query = {}) {
    return axios.get(`/client/search`, { params: query });
  }
  getOne(id) {
    return axios.get(`/client/one`, { params: { id } });
  }
  delete(id) {
    return axios.delete(`/client`, { data: { id } });
  }
  changeAddedNumbers(data) {
    return axios.put(`/client/numbers`, data);
  }
  changeClub(data) {
    return axios.put(`/client/club`, data);
  }
}

export default new Client({});
