import axios from "axios";

class TrainerWage {
  change(data) {
    return axios.put(`/wage`, data);
  }
  getAll() {
    return axios.get(`/wage`);
  }
  getStat(data) {
    return axios.get(`/wage/stat`, { params: data });
  }
}

export default new TrainerWage({});
