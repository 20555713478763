import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Login from "./pages/Login/Login";
import Club from "./pages/Club/Club";
import News from "./pages/News/News";
import Trainers from "./pages/Trainers/Trainers";
import Groups from "./pages/Groups/Groups";
import Group from "./pages/Group/Group";
import Questioning from "./pages/Questioning/Questioning";
import Reviews from "./pages/Reviews/Reviews";
import Home from "./pages/Home/Home";
import Clients from "./pages/Clients/Clients";
import FunnelStages from "./pages/FunnelStages/FunnelStages";
import Orders from "./pages/Orders/Orders";
import GlobalSettings from "./pages/GlobalSettings/GlobalSettings";
import Wage from "./pages/Wage/Wage";
import Purchases from "./pages/Purchases/Purchases";
import Notifications from "./pages/Notifications/Notifications";
import Offers from "./pages/Offers/Offers";
import Communication from "./pages/Сommunication/Сommunication";
import Cost from "./pages/Cost/Cost";
import Support from "./pages/Support/List";
import AppSettings from "./pages/AppSettings";


const authRoutes = [
  {
    path: "/cost",
    component: <Cost />,
  },
  {
    path: "/global-settings",
    component: <GlobalSettings />,
  },
  {
    path: "/purchases",
    component: <Purchases />,
  },
  {
    path: "/notifications",
    component: <Notifications />,
  },
  {
    path: "/orders",
    component: <Orders />,
  },
  {
    path: "/funnel-stages",
    component: <FunnelStages />,
  },
  {
    path: "/clients",
    component: <Clients />,
  },
  {
    path: "/home",
    component: <Home />,
  },
  {
    path: "/reviews",
    component: <Reviews />,
  },
  {
    path: "/my-club",
    component: <Club />,
  },
  {
    path: "/news",
    component: <News />,
  },
  {
    path: "/offers",
    component: <Offers />,
  },
  {
    path: "/trainers",
    component: <Trainers />,
  },
  {
    path: "/groups",
    component: <Groups />,
  },
  {
    path: "/group/:id",
    component: <Group />,
  },
  {
    path: "/questioning",
    component: <Questioning />,
  },
  {
    path: "/wage",
    component: <Wage />,
  },
  {
    path: "/communication",
    component: <Communication />,
  },
  {
    path: "/support",
    component: <Support />,
  },
  {
    path: "/appSettings",
    component: <AppSettings />,
  },
];
const unauthRoutes = [
  {
    path: "/login",
    component: <Login />,
  },
];

function RouterComponent() {
  const isAuth = useSelector((state) => state.auth.isAuth);
  const routes = isAuth ? authRoutes : unauthRoutes;
  return (
    <Routes>
      {routes.map((r) => (
        <Route key={r.path} exact path={r.path} element={r.component} />
      ))}
      {!isAuth ? (
        <Route path="*" element={<Navigate to="/login" replace />} />
      ) : (
        <Route path="*" element={<Navigate to="/home" replace />} />
      )}
    </Routes>
  );
}

export default RouterComponent;
