import React, { useState, useCallback, useEffect } from "react";
import {
  TextField,
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Avatar,
  TableRow,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ButtonGroup,
  CircularProgress,
  Paper,
  Typography,
  Grid,
  IconButton,
  Divider,
  Modal,
  Alert,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import InputWrap from "../../components/InputWrap";
import { useSnackbar } from "notistack";
import getTheme from "../../common/feedback/getTheme";
import getPrivateImageUri from "../../common/file/getPrivateImageUri";
import { EventBus } from "../../event";
import FeedbackService from "../../services/Feedback";

export default function ShowPush({ data }) {
  return (
    <Box>
      <Typography sx={{ fontSize: 14, color: "gray" }}>Прочитано:</Typography>
      <Typography fontWeight="normal" variant="body1">
        {data.isViewed ? <b>Да</b> : <b>Нет</b>}
      </Typography>
      <Typography sx={{ fontSize: 14, color: "gray" }}>Заголовок:</Typography>
      <Typography fontWeight="normal" variant="body1">
        {data.title}
      </Typography>
      <Typography sx={{ fontSize: 14, color: "gray", mt: 2 }}>
        Сообщение:
      </Typography>
      <Typography variant="body1">{data.body}</Typography>
    </Box>
  );
}
