import React from "react";
import {
  Box,
  TablePagination,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Popover,
  Divider,
  Typography,
  SwipeableDrawer,
  Button,
  IconButton,
  Menu,
  MenuList,
  Chip,
  MenuItem,
  ListItemText,
  Checkbox,
  ListItemIcon,
} from "@mui/material";
import moment from "moment";
import "moment/locale/ru";
import { EventBus } from "../../event";
import firstLetterUppercase from "../../common/firstLetterUppercase";

export default function MemebershipTable({
  list,
  paginData,
  handleChangePage,
  handleChangeRowsPerPage,
}) {
  return (
    <Box>
      <TableContainer
        sx={{ maxWidth: "100%", overflowX: "auto" }}
      >
        <Table>
        <TableHead>
          <TableRow>
            <TableCell>ФИО Клиента</TableCell>
            <TableCell>ФИО Ребёнка</TableCell>
            <TableCell>Месяц</TableCell>
            <TableCell>Сумма</TableCell>
            <TableCell>Дата оплаты</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((r) => {
            let client = r.client;
            let clientFullName = `${client.surname} ${client.name} ${client.patronymic}`;
            let childFullName = "-";

            if (client.isChild) {
              childFullName = `${client.child.surname} ${client.child.name} ${client.child.patronymic}`;
            }

            let monthName = moment(r.month).format("MMMM");
            monthName = firstLetterUppercase(monthName);
            let dateText = moment(r.datePayment).format("DD.MM.YYYY, HH:mm");

            return (
              <TableRow key={r._id}>
                <TableCell
                  onClick={() =>
                    EventBus.$emit("open_client_window", client._id)
                  }
                  sx={{
                    cursor: "pointer",
                    position: "sticky",
                    bgcolor: "#434242",
                    left: 0,
                  }}
                >
                  <Typography variant="caption">{clientFullName}</Typography>
                </TableCell>
                <TableCell>{childFullName}</TableCell>
                <TableCell>{monthName}</TableCell>
                <TableCell>{r.paymentAmount}</TableCell>
                <TableCell>{dateText}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{mt: 1}}
        rowsPerPageOptions={[5, 10, 25]}
        component={Paper}
        count={paginData.countRows}
        rowsPerPage={paginData.rowsPerPage}
        page={paginData.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
