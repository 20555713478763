import axios from "axios";

class Message {
    send(data) {
        return axios.post(`/message`, data);
    }
    getAll() {
        return axios.get(`/messages`);
    }
    getChat(data) {
        return axios.post(`/messages/chat`, data);
    }
}

export default new Message({});
