import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import SidebarWrap from "../SidebarWrap";
import InputWrap from "../InputWrap";
import TrainerStore from "../../store/trainer";
import UploadImage from "../UploadImage/UploadImage";
import TrainerService from "../../services/Trainer";
import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from "notistack";

function TrainerInfo({ closeHandler, id }) {
  const { enqueueSnackbar } = useSnackbar();
  let [photo, setPhoto] = useState(null);

  let [isSubmiting, setSubmiting] = useState(false);
  let trainers = TrainerStore.list;
  const isNew = id == "new";
  let trainer = {
    name: "",
    spec: "",
    about: "",
  };
  if (!isNew && id) {
    trainer = trainers.find((t) => t._id == id);
  }
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: trainer.name,
      spec: trainer.spec,
      about: trainer.about,
    },
  });

  const onSubmit = handleSubmit((d) => {
    let fetchData = d;

    if (!isNew) {
      fetchData = Object.assign({}, d, { _id: id });
    }

    const form = new FormData();
    if (photo) {
      form.append("photo", photo);
    }
    form.append("data", JSON.stringify(fetchData));

    setSubmiting(true);

    const fetchFunc = isNew
      ? TrainerService.createTrainer
      : TrainerService.changeTrainer;

    fetchFunc(form)
      .then((r) => {
        let t = r.data;
        if (isNew) {
          TrainerStore.addTrainer(t);
          enqueueSnackbar("Тренер добавлен!", { variant: "success" });
        } else {
          TrainerStore.changeTrainer(t);
          enqueueSnackbar("Тренер изменён!", { variant: "success" });
        }
        closeHandler();
      })
      .finally(() => setSubmiting(false));
  });

  return (
    <SidebarWrap
      title={id == "new" ? "Создать" : "Тренер"}
      closeHandler={closeHandler}
    >
      <form noValidate onSubmit={onSubmit}>
        <UploadImage url={trainer.photo} setImage={setPhoto} />
        <Controller
          name={"name"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputWrap wrapStyle={{ my: 1 }} id="name" label="ФИО">
              <TextField
                id="name"
                label=""
                error={Boolean(errors.name?.message)}
                {...register("name", {
                  required: "Мин. 4 символов",
                  minLength: {
                    message: "Мин. 4 символов",
                    value: 4,
                  },
                  maxLength: 200,
                })}
                fullWidth
                margin="normal"
                helperText={errors.name?.message}
                sx={{ m: 0 }}
                size="small"
                variant="outlined"
                name="name"
                type="text"
                onChange={onChange}
                value={value}
              />
            </InputWrap>
          )}
        />
        <Controller
          name={"spec"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputWrap wrapStyle={{ my: 1 }} id="spec" label="Специализация">
              <TextField
                id="spec"
                label=""
                error={Boolean(errors.spec?.message)}
                {...register("spec", {
                  required: "Мин. 2 символов",
                  minLength: {
                    message: "Мин. 2 символов",
                    value: 2,
                  },
                  maxLength: 200,
                })}
                fullWidth
                margin="normal"
                helperText={errors.spec?.message}
                sx={{ m: 0 }}
                size="small"
                variant="outlined"
                name="spec"
                type="text"
                onChange={onChange}
                value={value}
              />
            </InputWrap>
          )}
        />
        <Controller
          name={"about"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputWrap wrapStyle={{ my: 1 }} id="about" label="О тренере">
              <TextField
                id="about"
                label=""
                error={Boolean(errors.about?.message)}
                {...register("about", {
                  required: "Мин. 4 символов",
                  minLength: {
                    message: "Мин. 4 символов",
                    value: 4,
                  },
                })}
                fullWidth
                margin="normal"
                helperText={errors.about?.message}
                sx={{ m: 0 }}
                size="small"
                multiline
                rows={10}
                variant="outlined"
                name="about"
                type="text"
                onChange={onChange}
                value={value}
              />
            </InputWrap>
          )}
        />
        <Button
          fullWidth
          sx={{ marginTop: 4 }}
          size="small"
          variant="contained"
          type="submit"
          startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
          disabled={isSubmiting}
        >
          {isNew ? "Добавить" : "Сохранить"}
        </Button>
      </form>
    </SidebarWrap>
  );
}
export default TrainerInfo;
