import axios from "axios";
import { API } from "./config";
import { logout } from "./store/slices/auth";
import store from "./store/index";
axios.defaults.baseURL = API;

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  const club = localStorage.getItem("club");
  config.headers.common["token"] = token || "";
  config.headers.common["club"] = club || "";

  return config;
});
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 403) {
      console.log("logout inst");
      store.dispatch(logout());
    }
    return Promise.reject(error);
  }
);
