import React, { useState, Fragment, useEffect } from "react";
import "./App.css";
import Router from "./router";
import { useDispatch, useSelector } from "react-redux";
import AuthService from "./services/Auth";
import { loged } from "./store/slices/auth";
import Loader from "./components/Loader/Loader";
import theme from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import moment from "moment";
import "moment/locale/ru";
import Drawers from "../src/components/Drawers/Drawers";
import { CssBaseline, Box } from "@mui/material";
import DrawerMenu from "../src/components/DrawerMenu/index";
import loadMainData from "./loadMainData";
import { connect, authenticate } from "./socket";
import ClientAction from "./components/ClientAction/ClientAction";
import ImageViewer from "./components/ImageViewer";

import { observer } from "mobx-react-lite";

// Moduelds
import SelectClub from "./modules/SelectClub/SelectClub";

// Store
import ClubStore from "./store/club";

moment.locale("ru");

console.log(moment().utcOffset(), "utc offset");

function App() {
  const dispatch = useDispatch();
  let currentClubId = ClubStore.currentClubId;
  useEffect(() => {
    async function fetchUser() {
      try {
        if (localStorage.getItem("token")) {
          let { data } = await AuthService.getUser();
          dispatch(loged(data));
          await loadMainData();
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
    fetchUser();
  }, []);

  useEffect(() => {
    async function fetchData() {
      await loadMainData();
    }
    fetchData();
  }, [currentClubId]);

  let [loading, setLoading] = useState(true);
  const isAuth = useSelector((state) => state.auth.isAuth);

  useEffect(() => {
    if (isAuth) {
      connect();
      authenticate();
    }
  }, [isAuth]);

  const content = (
    <Fragment>
      {isAuth ? (
        <DrawerMenu>
          <SelectClub />
          <Box>
            <Router />
            <ClientAction />
            <ImageViewer />
          </Box>
        </DrawerMenu>
      ) : (
        <Router />
      )}
    </Fragment>
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        {loading ? <Loader /> : content}
        <Drawers />
      </div>
    </ThemeProvider>
  );
}

export default observer(App);
