import axios from "axios";

class OfferDocument {
  create(data) {
    return axios.post(`/offer-document`, data);
  }
  change(data) {
    return axios.put(`/offer-document`, data);
  }
  getAll(data) {
    return axios.get(`/offer-document`);
  }
  delete(id) {
    return axios.delete(`/offer-document`, { data: { id } });
  }
  //   getAll(data) {
  //     return axios.get(`/order`, { params: data });
  //   }
  //   change(data) {
  //     return axios.put(`/order`, data);
  //   }
  //   getClientOrders(id) {
  //     return axios.get(`/order/client`, { params: { id } });
  //   }
  //   changeStage(data) {
  //     return axios.put(`/order/stage`, data);
  //   }
}

export default new OfferDocument({});
