import React, { useState, useCallback, useEffect } from "react";
import {
  TextField,
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Avatar,
  TableRow,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ButtonGroup,
  CircularProgress,
  Paper,
  Typography,
  Grid,
  IconButton,
  Divider,
  Modal,
  Alert,
} from "@mui/material";
import getTheme from "../../common/feedback/getTheme";
import moment from "moment";
import "moment/locale/ru";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { EventBus } from "../../event";

export default function FeedbackTable({ list, hideUser = false }) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {!hideUser && (
              <React.Fragment>
                <TableCell>Телефон</TableCell>
                <TableCell>ФИО Клиента</TableCell>
              </React.Fragment>
            )}

            <TableCell>Тема</TableCell>
            <TableCell>Сообщение</TableCell>
            <TableCell>Статус</TableCell>
            <TableCell>Дата</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((r) => {
            let client = r?.client;
            if(!client) return null;
            let phone = `+${client.phone}`;
            let fullName = `${client.surname} ${client.name} ${client.patronymic}`;

            return (
              <TableRow
                onClick={(e) => {
                  EventBus.$emit("modal_client_action", {
                    action: "show-feedback",
                    data: r,
                  });
                }}
                key={r._id}
              >
                {!hideUser && (
                  <React.Fragment>
                    <TableCell>{phone}</TableCell>
                    <TableCell
                      onClick={(e) => {
                        e.stopPropagation();
                        EventBus.$emit("open_client_window", client._id);
                      }}
                      sx={{
                        cursor: "pointer",
                        position: "sticky",
                        bgcolor: "#434242",
                        left: 0,
                      }}
                    >
                      <Typography variant="caption">{fullName}</Typography>
                    </TableCell>
                  </React.Fragment>
                )}

                <TableCell>{getTheme(r.theme)}</TableCell>
                <TableCell>{r.message}</TableCell>
                <TableCell>
                  <Box sx={{ color: r.isViewed ? "green" : "red" }}>
                    {r.isViewed ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </Box>
                </TableCell>
                <TableCell>
                  {moment(r.created_at).format("HH:mm, DD.MM.YYYY")}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
