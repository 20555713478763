import React, { useState } from "react";
import {
  Box,
  Typography,
  Slider,
  IconButton,
  Button,
  TextField,
  MenuItem,
  Select,
  ListItemIcon,
  Avatar,
  CircularProgress,
} from "@mui/material";
import SidebarWrap from "../SidebarWrap";
import InputWrap from "../InputWrap";
import GroupStore from "../../store/group";
import ClubStore from "../../store/club";
import { API_IMAGES } from "../../config";
import TrainerStore from "../../store/trainer";
import GroupService from "../../services/Group";
import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from "notistack";
import DaysPicker from "./DaysPicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import "moment/locale/ru";

let initData = {
  trainer: "",
  name: "",
  places: 30,
  age: {
    from: 1,
    to: 40,
  },
  visitingDays: [],
  visitingTime: "6:00",
  duration: 60,
  desc: "",
};

function GroupInfo({ closeHandler, id }) {
  const { enqueueSnackbar } = useSnackbar();
  let [isSubmiting, setSubmiting] = useState(false);
  let groups = GroupStore.list;
  let trainers = TrainerStore.list;
  const isNew = id == "new";
  let group = initData;
  if (!isNew && id) {
    group = groups.find((g) => g._id == id);
  }
  ////
  let [groupData, setGroupData] = useState({
    trainer: group.trainer,
    age: group.age,
    visitingDays: group.visitingDays,
    visitingTime: group.visitingTime,
  });

  console.log({ groupData });

  function changeGroupData(name, value) {
    let newGroupData = Object.assign({}, groupData, { [name]: value });
    setGroupData(newGroupData);
  }

  if (!groupData.trainer) {
    changeGroupData("trainer", trainers[0]._id + "");
  }

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: group.name,
      places: group.places,
      duration: group.duration,
      desc: group.desc,
      // about: trainer.about,
    },
  });

  const onSubmit = handleSubmit((d) => {
    let fetchData = Object.assign({}, d, groupData);

    console.log(fetchData, "fetchData");

    if (!isNew) {
      fetchData = Object.assign({}, fetchData, { _id: id });
    }

    setSubmiting(true);

    const fetchFunc = isNew ? GroupService.create : GroupService.change;

    fetchFunc(fetchData)
      .then((r) => {
        let g = r.data;
        if (isNew) {
          GroupStore.addGroup(g);
          enqueueSnackbar("Группа добавленa!", { variant: "success" });
        } else {
          GroupStore.changeGroup(g);
          enqueueSnackbar("Группа измененна!", { variant: "success" });
        }
        closeHandler();
      })
      .finally(() => setSubmiting(false));
  });

  return (
    <SidebarWrap
      title={isNew ? "Создать группу" : "О группе"}
      closeHandler={closeHandler}
    >
      <Box sx={{ p: 1, pt: 0 }}>
        <form noValidate onSubmit={onSubmit}>
          <Controller
            name={"name"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputWrap wrapStyle={{ mb: 2 }} id="name" label="Название">
                <TextField
                  id="name"
                  label=""
                  error={Boolean(errors.name?.message)}
                  {...register("name", {
                    required: "Мин. 4 символов",
                    minLength: {
                      message: "Мин. 4 символов",
                      value: 4,
                    },
                    maxLength: 200,
                  })}
                  fullWidth
                  margin="normal"
                  helperText={errors.name?.message}
                  sx={{ m: 0 }}
                  size="small"
                  variant="outlined"
                  name="name"
                  type="text"
                  onChange={onChange}
                  value={value}
                />
              </InputWrap>
            )}
          />

          <InputWrap id="trainer" label="Тренер" wrapStyle={{ mb: 2 }}>
            <Select
              value={groupData.trainer + ""}
              fullWidth
              id="trainer"
              size="small"
              onChange={(e) => changeGroupData("trainer", e.target.value + "")}
            >
              {trainers.map((t) => (
                <MenuItem key={t._id} value={t._id + ""}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    <ListItemIcon>
                      <Avatar />
                    </ListItemIcon>
                    <Typography sx={{ ml: 2 }} variant="inherit">
                      {t.name} ({t.spec})
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </InputWrap>
          <Controller
            name={"places"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputWrap wrapStyle={{ mb: 2 }} id="places" label="Макс. мест">
                <TextField
                  id="places"
                  label=""
                  error={Boolean(errors.places?.message)}
                  {...register("places", {
                    required: "Заполните поле",
                  })}
                  fullWidth
                  margin="normal"
                  helperText={errors.places?.message}
                  sx={{ m: 0 }}
                  size="small"
                  variant="outlined"
                  name="places"
                  type="number"
                  onChange={onChange}
                  value={value}
                />
              </InputWrap>
            )}
          />
          <InputWrap wrapStyle={{ mb: 2 }} id="age" label="Возраст">
            <Box
              sx={{
                display: "flex",
                alingItems: "center",
                justifyContent: "space-around",
              }}
            >
              <Typography variant="subtitle1" component="h6">
                От: {groupData.age.from}
              </Typography>
              <Typography variant="subtitle1" component="h6">
                До: {groupData.age.to}
              </Typography>
            </Box>
            <Slider
              getAriaLabel={() => "Возраст"}
              value={[groupData.age.from, groupData.age.to]}
              onChange={(e, v) =>
                changeGroupData("age", { from: v[0], to: v[1] })
              }
              step={1}
              valueLabelDisplay="off"
              marks
              min={1}
              max={40}
              getAriaValueText={() => {}}
            />
          </InputWrap>
          <InputWrap
            wrapStyle={{ mb: 2 }}
            id="visitingDays"
            label="Дни посещения"
          >
            <DaysPicker
              days={groupData.visitingDays}
              setDays={(d) => changeGroupData("visitingDays", d)}
            />
          </InputWrap>

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <TimePicker
              label="Начало занятия"
              value={moment()
                .set("hour", groupData.visitingTime.split(":")[0])
                .set("minute", groupData.visitingTime.split(":")[1])}
              onChange={(e, b) => {
                changeGroupData("visitingTime", e.format("HH:mm"));
              }}
              renderInput={(params) => (
                <TextField sx={{ mb: 2, width: "100%" }} {...params} />
              )}
            />
          </LocalizationProvider>
          <Controller
            name={"duration"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputWrap
                wrapStyle={{ mb: 2 }}
                id="duration"
                label="Длит. минут"
              >
                <TextField
                  id="duration"
                  label=""
                  error={Boolean(errors.duration?.message)}
                  {...register("duration", {
                    required: "Заполните поле",
                  })}
                  fullWidth
                  margin="normal"
                  helperText={errors.duration?.message}
                  sx={{ m: 0 }}
                  size="small"
                  variant="outlined"
                  name="duration"
                  type="number"
                  onChange={onChange}
                  value={value}
                />
              </InputWrap>
            )}
          />
          <Controller
            name={"desc"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputWrap wrapStyle={{ mb: 2 }} id="desc" label="Описание">
                <TextField
                  id="desc"
                  label=""
                  error={Boolean(errors.desc?.message)}
                  {...register("desc", {
                    required: "Мин. 4 символов",
                    minLength: {
                      message: "Мин. 4 символов",
                      value: 4,
                    },
                    maxLength: 200,
                  })}
                  fullWidth
                  multiline
                  rows={6}
                  margin="normal"
                  helperText={errors.desc?.message}
                  sx={{ m: 0 }}
                  size="small"
                  variant="outlined"
                  name="desc"
                  type="text"
                  onChange={onChange}
                  value={value}
                />
              </InputWrap>
            )}
          />
          <Button
            fullWidth
            sx={{ marginTop: 2 }}
            size="large"
            variant="outlined"
            type="submit"
            startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmiting}
          >
            {isNew ? "Добавить" : "Сохранить"}
          </Button>
        </form>
      </Box>
    </SidebarWrap>
  );
}

export default GroupInfo;
