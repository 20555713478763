import React, { useState, useCallback, useEffect } from "react";
import {
  TextField,
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Avatar,
  TableRow,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ButtonGroup,
  CircularProgress,
  Paper,
  Typography,
  Grid,
  IconButton,
  Divider,
  Modal,
  Alert,
} from "@mui/material";

export default function ShowTelegram({ data }) {
  return (
    <Box>
      <Typography sx={{ fontSize: 14, color: "gray", mt: 2 }}>
        Сообщение:
      </Typography>
      <Typography variant="body1">{data.message}</Typography>
    </Box>
  );
}
