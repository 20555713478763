import React from "react";
import {
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  Chip,
  TableContainer,
  TableHead,
  TableRow,
  Popover,
  Checkbox,
  Paper,
  Divider,
  Typography,
  SwipeableDrawer,
  Button,
  Menu,
  IconButton,
  FormControl,
  TablePagination,
  Avatar,
  Select,
  MenuItem,
} from "@mui/material";

export default function Trainers({ list }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{borderCollapse: 'collapse', borderSpacing: 'unset'}}>
        <TableHead sx={{ borderBottom: '2px solid #1C1C1E'}}>
          <TableRow sx={{ borderTop: "none" }}>
            <TableCell>#</TableCell>
            <TableCell>Имя</TableCell>
            <TableCell>Кол-во оплат</TableCell>
            <TableCell>Сумма</TableCell>
            <TableCell>Начислено</TableCell>
            <TableCell> </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((r, i) => {
            return (
              <TableRow sx={{width: '100%', borderBottom: '2px solid #1C1C1E'}}>
                <TableCell>{i + 1}</TableCell>
                <TableCell>{r.name}</TableCell>
                <TableCell>{r.totalAmount}</TableCell>
                <TableCell>{r.totalSum}</TableCell>
                <TableCell>{r.salary}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
