import axios from "axios";

class Sms {
  getByClient(clientId) {
    return axios.get(`/sms/by-client`, { params: { clientId } });
  }
  getAll() {
    return axios.get(`/sms`);
  }
  getBalance() {
    return axios.get(`/sms/balance`);
  }
  send(data) {
    return axios.post(`/sms/send`, data);
  }
}

export default new Sms({});
