import axios from "axios";

class HistoryPayment {
  getClientStat(data) {
    return axios.get(`/history-payment/stat`, { params: data });
  }
  getAll(data) {
    return axios.get(`/history-payment`, { params: data });
  }
  //   change(id, data) {
  //     return axios.put(`/group/${id}`, data);
  //   }
  //   getList() {
  //     return axios.get(`/group`);
  //   }
  //   create(name) {
  //     return axios.post(`/group`, { name });
  //   }
}

export default new HistoryPayment({});
