import RUG from "react-upload-gallery";
import { Box, Typography, Alert } from "@mui/material";

import { API, API_IMAGES } from "../../config";
// Add style manually
import "react-upload-gallery/dist/style.css"; // or scss

function Gallery({ setGallery, error, initialState = null }) {
  return (
    <>
      {error && (
        <Alert severity="error" sx={{ mb: 1 }}>
          {error}
        </Alert>
      )}
      <RUG
        headers={{
          token: localStorage.getItem("token"),
        }}
        initialState={initialState?.map((g) => ({ source: API_IMAGES + g }))}
        onChange={(images) => {
          let list = images.map((i) => {
            let ls = i.source.split("/");
            return ls[ls.length - 1];
          });
          setGallery(list);
        }}
        action={API + "/upload/image"} // upload route
        source={(response) => API_IMAGES + response.name} // response image source
      />
    </>
  );
}

export default Gallery;
