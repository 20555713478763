import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import MemebershipCard from "./MemebershipCard";
import ClientMembershipService from "../../../services/ClientMembership";

export default function MembershipList({ clientId }) {
  let [list, setList] = useState([]);
  useEffect(() => {
    ClientMembershipService.getByClient(clientId).then((r) => setList(r.data));
  }, []);

  list = list.filter((m) => m.isPayment);
  return (
    <Box>
      <Box>
        {list.map((m) => (
          <MemebershipCard data={m} />
        ))}
      </Box>
    </Box>
  );
}
