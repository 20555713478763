import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Typography,
  CircularProgress,
  SwipeableDrawer,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Tabs,
  Tab,
} from "@mui/material";
import SmsService from "../../../services/Sms";
import SmsTable from "../../../components/SmsTable/SmsTable";

// Store
import ClubStore from '../../../store/club'
import {observer} from 'mobx-react-lite';


function SmsTabe() {
  let clubId = ClubStore.currentClubId;
  let [list, setList] = useState([]);
  useEffect(() => {
    SmsService.getAll().then((r) => setList(r.data));
  }, [clubId]);

  return (
    <Box>
      <SmsTable list={list} />
    </Box>
  );
}

export default observer(SmsTabe);
