import React from "react";
import {
  TextField,
  Box,
  Typography,
  Card,
  Grid,
  Button,
  Divider,
} from "@mui/material";

import InputWrap from "../../components/InputWrap";
import { Controller } from "react-hook-form";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

export default function ChildData({ hookFormData, infoData, setInfoData }) {
  let { control, errors, register } = hookFormData;
  return (
    <Box>
      <Divider />
      <Controller
        name={"childSurname"}
        control={control}
        render={({ field: { onChange, value } }) => (
          <InputWrap
            wrapStyle={{ my: 1 }}
            id="childSurname"
            label="Фамилия Ребёнка"
          >
            <TextField
              id="childSurname"
              label=""
              error={Boolean(errors.childSurname?.message)}
              {...register("childSurname", {
                required: "Фамилия Ребёнка не может быть пустым",
                minLength: {
                  message: "Мин. 2 символа",
                  value: 2,
                },
                maxLength: 200,
              })}
              fullWidth
              margin="normal"
              helperText={errors.childSurname?.message}
              sx={{ m: 0 }}
              size="small"
              variant="outlined"
              name="childSurname"
              type="text"
              onChange={onChange}
              value={value}
            />
          </InputWrap>
        )}
      />
      <Controller
        name={"childName"}
        control={control}
        render={({ field: { onChange, value } }) => (
          <InputWrap wrapStyle={{ my: 1 }} id="childName" label="Имя Ребёнка">
            <TextField
              id="childName"
              label=""
              error={Boolean(errors.childName?.message)}
              {...register("childName", {
                required: "Имя Ребёнка не может быть пустым",
                minLength: {
                  message: "Мин. 2 символа",
                  value: 2,
                },
                maxLength: 200,
              })}
              fullWidth
              margin="normal"
              helperText={errors.childName?.message}
              sx={{ m: 0 }}
              size="small"
              variant="outlined"
              name="childName"
              type="text"
              onChange={onChange}
              value={value}
            />
          </InputWrap>
        )}
      />

      <Controller
        name={"childPatronymic"}
        control={control}
        render={({ field: { onChange, value } }) => (
          <InputWrap
            wrapStyle={{ my: 1 }}
            id="childPatronymic"
            label="Отчество Ребёнка"
          >
            <TextField
              id="childPatronymic"
              label=""
              error={Boolean(errors.childPatronymic?.message)}
              {...register("childPatronymic", {
                required: "Отчество Ребёнка не может быть пустым",
                minLength: {
                  message: "Мин. 2 символа",
                  value: 2,
                },
                maxLength: 200,
              })}
              fullWidth
              margin="normal"
              helperText={errors.childPatronymic?.message}
              sx={{ m: 0 }}
              size="small"
              variant="outlined"
              name="childPatronymic"
              type="text"
              onChange={onChange}
              value={value}
            />
          </InputWrap>
        )}
      />
      <InputWrap
        id="childBirthday"
        label="Дата рождения Ребёнка"
        wrapStyle={{ my: 1 }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment} locale="ru">
          <DesktopDatePicker
            inputFormat="DD.MM.YYYY"
            value={infoData.childBirthday}
            onChange={(d) =>
              setInfoData({
                ...infoData,
                childBirthday: d ? d.valueOf(): '',
              })
            }
            renderInput={(params) => (
              <TextField
                size="small"
                id="childBirthday"
                fullWidth
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      </InputWrap>
      <Divider />
    </Box>
  );
}
