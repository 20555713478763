import React, { useMemo } from "react";
import {
  Box,
  Card,
  Button,
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,
  Switch,
} from "@mui/material";
import InputWrap from "../../components/InputWrap";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import BirthdayRange from "../../components/FiltersUI/BirthdayRange";
import SelectMonth from "../../components/FiltersUI/SelectMonth";
import MultipleSelectChip from "../../components/FiltersUI/MultipleSelectChip";

import groupGetFullInfo from "../../common/groupGetFullInfo";
import TrainerStore from "../../store/trainer";
import GroupStore from "../../store/group";
import ClubStore from "../../store/club";

import FunnelStageStore from "../../store/funnelStage";
import SourceStore from "../../store/source";

const statusList = [
  {
    name: "Новый",
    _id: "new",
  },
  {
    name: "Открытый",
    _id: "open",
  },
  {
    name: "Закрытый",
    _id: "close",
  },
  {
    name: "Заблокированный",
    _id: "block",
  },
];

let levelList = [
  {
    name: "Начинающий",
    _id: 1,
  },
  {
    name: "Ученик",
    _id: 2,
  },
  {
    name: "Боец",
    _id: 3,
  },
  {
    name: "Мастер",
    _id: 4,
  },
];

export default function Filter({
  findHandler,
  setFilterData,
  filterData,
  initFilterData,
}) {
  function changeFilterHandler(name, value) {
    let newFilterData = Object.assign({}, filterData, { [name]: value });
    setFilterData(newFilterData);
  }
  function clearFilterHandler() {
    setFilterData(initFilterData);
  }

  let currentClubId = ClubStore.currentClubId;

  const groupList = useMemo(() => {
    let list = [...GroupStore.list].filter((g) => g.club == currentClubId);
    if (!filterData.trainer.length) return list;

    changeFilterHandler("group", []);

    let result = [];

    list.forEach((g) => {
      if (filterData.trainer.includes(g.trainer)) {
        result.push(g);
      }
    });

    return result;
  }, [filterData.trainer]);

  return (
    <Card sx={{ p: 1, my: 2, boxShadow: "none" }}>
      <Grid container spacing={1}>
        <Grid item xs={6} md={4} lg={2}>
          <InputWrap id="clientName" label="ФИО Клиента">
            <TextField
              id="clientName"
              label=""
              fullWidth
              sx={{ m: 0 }}
              size="small"
              variant="outlined"
              name="clientName"
              type="text"
              onChange={(e) =>
                changeFilterHandler("clientName", e.target.value)
              }
              value={filterData.clientName}
            />
          </InputWrap>
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <InputWrap id="childName" label="ФИО Ребёнка">
            <TextField
              id="childName"
              label=""
              fullWidth
              sx={{ m: 0 }}
              size="small"
              variant="outlined"
              name="childName"
              type="text"
              onChange={(e) => changeFilterHandler("childName", e.target.value)}
              value={filterData.childName}
            />
          </InputWrap>
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <BirthdayRange
            from={filterData.birthday.from}
            to={filterData.birthday.to}
            changeHandler={(d) => {
              changeFilterHandler("birthday", d);
            }}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <InputWrap id="phone" label="Телефон">
            <TextField
              id="phone"
              label=""
              fullWidth
              sx={{ m: 0 }}
              size="small"
              variant="outlined"
              name="phone"
              type="number"
              onChange={(e) => changeFilterHandler("phone", e.target.value)}
              value={filterData.phone}
            />
          </InputWrap>
        </Grid>

        <Grid item xs={6} md={4} lg={2}>
          <MultipleSelectChip
            title="Источник"
            data={SourceStore.list}
            list={filterData.source}
            setList={(list) => changeFilterHandler("source", list)}
          />
        </Grid>

        <Grid item xs={6} md={4} lg={2}>
          <MultipleSelectChip
            title="Статус"
            data={statusList}
            list={filterData.status}
            setList={(list) => changeFilterHandler("status", list)}
          />
        </Grid>

        <Grid item xs={6} md={4} lg={2}>
          <SelectMonth
            date={filterData.month}
            setDate={(d) => changeFilterHandler("month", d)}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <MultipleSelectChip
            title="Тренер"
            data={TrainerStore.list}
            list={filterData.trainer}
            setList={(list) => changeFilterHandler("trainer", list)}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <MultipleSelectChip
            title="Группа"
            data={groupList.map((g) => ({
              name: groupGetFullInfo(g),
              _id: g._id,
            }))}
            list={filterData.group}
            setList={(list) => changeFilterHandler("group", list)}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <MultipleSelectChip
            title="Этап воронки"
            data={FunnelStageStore.membershipStages}
            list={filterData.stage}
            setList={(list) => changeFilterHandler("stage", list)}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <MultipleSelectChip
            title="Бонусный уровень"
            data={levelList}
            list={filterData.level}
            setList={(list) => changeFilterHandler("level", list)}
          />
        </Grid>
        <Grid
          item
          xs={6}
          md={4}
          lg={2}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Box sx={{ pt: 2, display: "flex" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterData.withTelegram}
                  onChange={(e) =>
                    changeFilterHandler("withTelegram", e.target.checked)
                  }
                />
              }
              label="Telegram"
            />
            {filterData.withTelegram && (
              <FormControlLabel
                control={
                  <Switch
                    checked={filterData.hasTelegram}
                    onChange={(e) =>
                      changeFilterHandler("hasTelegram", e.target.checked)
                    }
                  />
                }
                label={filterData.hasTelegram ? "Есть" : "Нет"}
              />
            )}
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ display: "flex", mt: 2 }}>
        <Button
          variant="contained"
          size="small"
          startIcon={<SearchIcon />}
          onClick={findHandler}
        >
          Применить
        </Button>
        <Button
          sx={{ ml: 1 }}
          variant="outlined"
          size="small"
          startIcon={<ClearIcon />}
          onClick={clearFilterHandler}
        >
          Очистить
        </Button>
      </Box>
    </Card>
  );
}
