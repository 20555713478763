import {
  Box,
  Typography,
  Container,
  Button,
  Grid,
  Paper,
  CircularProgress,
  Popover,
  TextField,
  InputAdornment,
  IconButton,
  Alert,
} from "@mui/material";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteIcon from "@mui/icons-material/Delete";

export default function OfferCard({ data, deleteHandler, openDialogHandler }) {
  return (
    <Paper sx={{ p: 1, mb: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ grow: 1 }}>
          <Typography variant="body1" component="div">
            Название:
            {" " + data.name}
          </Typography>
          <Typography sx={{ mt: 1 }} variant="body1" component="div">
            Файл:
            {" " + data.fileName}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <IconButton onClick={() => openDialogHandler(data)}>
            <ModeEditOutlineIcon />
          </IconButton>
          <IconButton onClick={() => deleteHandler(data._id)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    </Paper>
  );
}
