import React, { useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { addDays } from "date-fns";
import moment from "moment";
import "moment/locale/ru";
import * as locales from "react-date-range/dist/locale";
import { DateRange } from "react-date-range";
import {
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Typography,
  SwipeableDrawer,
  Button,
  IconButton,
  FormControl,
  Select,
  Popover,
  MenuItem,
} from "@mui/material";

export default function DateRangePicker({
  startDate = null,
  endDate = null,
  setDate,
}) {
  console.log(startDate, "startDate", endDate, "endDate");
  function changeDate(e) {
    let { startDate, endDate } = e.selection;
    setDate(
      moment(startDate).startOf("date").valueOf(),

      moment(endDate).endOf("date").valueOf()
    );
  }
  return (
    <Box>
      <DateRange
        editableDateInputs={true}
        // onChange={(item) => setState([item.selection])}
        onChange={changeDate}
        moveRangeOnFirstSelection={false}
        ranges={[{ startDate, endDate, key: "selection" }]}
        locale={locales["ru"]}
      />
    </Box>
  );
}
