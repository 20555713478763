import axios from "axios";

class Auth {
  login(data) {
    return axios.post(`/login`, data);
  }
  getUser() {
    return axios.get(`/admin`);
  }
}

export default new Auth({});
