import React, { useEffect, useState } from "react";
import {
    Box,
    Card,
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Typography,
    ButtonGroup,
    Button,
} from "@mui/material";

import PushService from "../../services/Push";

// Store
import ClubStore from "../../store/club";
import { observer } from "mobx-react-lite";

function Purchases() {
    let clubId = ClubStore.currentClubId;
    let [list, setList] = useState([]);
    let [paginData, setPaginData] = useState({
        page: 0,
        rowsPerPage: 5,
        countRows: 10,
    });

    useEffect(() => {
        fetchNotifications();
    }, [clubId]);

    useEffect(() => {
        fetchNotifications();
    }, [paginData.page, paginData.rowsPerPage]);
    function fetchNotifications() {
        PushService.getByAdmin().then((res) => {
            let data = res.data;
            setList(data);
            //changePaginData("countRows", data.pagin.countRows);
            // setSubmit(false);
        });
    }

    useEffect(() => {
        console.log(list)
    }, [list]);


    return (
        <Box>
            <Typography variant="h5" gutterBottom>
                Уведомления
            </Typography>
            <div style={{display:'grid',gridGap:'10px'}}>
                <div style={{borderRadius:'30px',padding:'5px 15px',display:"grid",gridTemplateColumns:'200px 1fr auto'}}>
                    <b>Название</b>
                    <b>Содержание</b>
                    <b>Статус</b>

                </div>
                {list.map(p=>
                    <div style={{background:'#434242',borderRadius:'30px',padding:'15px',display:"grid",gridTemplateColumns:'200px 1fr auto'}}>
                        <div>{p.title}</div>
                        <div>{p.body}</div>
                        <div></div>

                    </div>
                )}
            </div>
        </Box>
    );
}

export default observer(Purchases);
