import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Container,
  Button,
  Grid,
  Paper,
  CircularProgress,
  Popover,
  TextField,
  InputAdornment,
  IconButton,
  Alert,
  Dialog,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import OfferDocumentService from "../../services/OfferDocument";
import { useForm, Controller } from "react-hook-form";
import InputWrap from "../../components/InputWrap";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

export default function OfferDialog({
  showDialog,
  handleClose,
  dialogData,
    addItemHandler,
    changeItemHandler
}) {
  console.log(dialogData, "dialogData");
  let [isSubmitting, setSubmitting] = useState(false);
  let [name, setName] = useState(dialogData ? dialogData.fileName : "");
  let [file, setFile] = useState(null);
  let [alert, setAlert] = useState(null);
  const {
    register,
    handleSubmit,
    control,
    watch,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: dialogData ? dialogData.name : "",
    },
  });

  function changeFileHandler(event) {
    setAlert(null);
    var file = event.target.files[0];
    let name = file.name;
    setName(name);
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onloadend = function (e) {
      setFile([reader.result]);
    };
  }

  function closeDialogHanlder() {
    handleClose();
    setName("");
    setFile(null);
    setAlert(null);
    setValue("name", "");
  }

  const onSubmit = handleSubmit((d) => {
    if (dialogData) {
      setSubmitting(true);

      const form = new FormData();
      form.append("id", dialogData._id);
      form.append("name", d.name);
      form.append("filename", name);

      if (file) {
        form.append("doc", file);
      }

      OfferDocumentService.change(form)
        .then((r) => {
          changeItemHandler(r.data);
          closeDialogHanlder();
        })
        .finally(() => setSubmitting(false));
    } else {
      if (!file) {
        setAlert("Файл не выбран!");
        return;
      }
      setSubmitting(true);

      const form = new FormData();
      form.append("doc", file);
      form.append("name", d.name);
      form.append("filename", name);

      OfferDocumentService.create(form)
        .then((r) => {
          addItemHandler(r.data);
          closeDialogHanlder();
        })
        .finally(() => setSubmitting(false));
    }
  });

  return (
    <Dialog
      PaperProps={{
        sx: {
          py: 1,
          px: 2,

          minWidth: { xs: "90vw", sm: "500px" },
        },
      }}
      keepMounted={true}
      open={!!showDialog}
      onClose={closeDialogHanlder}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          pb: 1,
        }}
      >
        <Typography variant="h6">
          {dialogData ? "Изменить документ" : "Добавить документ"}
        </Typography>
        <IconButton sx={{ ml: 2 }} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <form noValidate onSubmit={onSubmit}>
        <Controller
          name={"name"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputWrap id="name" label="Название">
              <TextField
                error={Boolean(errors.name?.message)}
                {...register("name", {
                  required: "Название не может быть пустым",
                  minLength: {
                    message: "Мин. 4 символов",
                    value: 4,
                  },
                  maxLength: 200,
                })}
                fullWidth
                helperText={errors.name?.message}
                label=""
                type="text"
                onChange={onChange}
                value={value}
                sx={{ m: 0 }}
                size="small"
                variant="outlined"
              />
            </InputWrap>
          )}
        />

        <Box sx={{ mt: 1, display: "flex", alignItems: "center" }}>
          <Button
            startIcon={<PictureAsPdfIcon />}
            variant="contained"
            component="label"
          >
            Файл
            <input
              onChange={changeFileHandler}
              type="file"
              accept="application/pdf"
              hidden
            />
          </Button>
          {name && (
            <Typography
              align="center"
              sx={{ ml: 1 }}
              variant="h6"
              component="div"
            >
              {name}
            </Typography>
          )}

          {alert && (
            <Typography
              align="center"
              color="error"
              sx={{ ml: 1 }}
              variant="body1"
              component="div"
            >
              {alert}
            </Typography>
          )}
        </Box>
        <Button
          type="submit"
          startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
          disabled={isSubmitting}
          sx={{ mt: 1 }}
          variant="contained"
          fullWidth
        >
          Сохранить
        </Button>
      </form>
    </Dialog>
  );
}
