const constants = {
  clientStatusChip: {
    new: "#0693E3",
    open: "#00D084",
    close: "#ff0000",
    block: "#000",
  },
  levels: [
    {
      level: 0,
      name: "Старт",
      countPayment: {
        from: 0,
        to: 0,
      },
      percent: 0,
    },
    {
      level: 1,
      name: "Начинающий",
      countPayment: {
        from: 0,
        to: 1,
      },
      percent: 3,
    },
    {
      level: 2,
      name: "Ученик",
      countPayment: {
        from: 1,
        to: 4,
      },
      percent: 6,
    },
    {
      level: 3,
      name: "Боец",
      countPayment: {
        from: 4,
        to: 10,
      },
      percent: 9,
    },
    {
      level: 4,
      name: "Мастер",
      countPayment: {
        from: 10,
        to: 999999,
      },
      percent: 12,
    },
  ],
};
export default constants;
