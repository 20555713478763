import { makeObservable, observable, action, computed } from "mobx";

class Source {
  list = [];
  constructor() {
    makeObservable(this, {
      list: observable,
      setList: action,
      addSource: action,
      changeSource: action,
      deleteSource: action,
    });
  }
  
  setList(data) {
    this.list = data;
  }
  addSource(s) {
    this.list = [s, ...this.list];
  }
  changeSource(st) {
    this.list = this.list.map((s) => {
      if (st._id == s._id) {
        return st;
      } else {
        return s;
      }
    });
  }
  deleteSource(id) {
    this.list = this.list.filter((s) => s._id !== id);
  }
}

const sourceStore = new Source();

export default sourceStore;
