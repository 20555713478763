import React, { useState } from "react";

import {
  TextField,
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Select,
  MenuItem,
  Menu,
  FormControl,
  InputLabel,
  ButtonGroup,
  CircularProgress,
  Paper,
  Collapse,
  Typography,
  Autocomplete,
  IconButton,
  ListItemButton,
  List,
  ListItem,
  ListItemText,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import ClientService from "../../services/Client";

export default function SearchClient({
  isChild = false,
  clientId = null,
  setClientId = () => {},
  clientError = null,
  clearClientError = () => {},
  title = null,
}) {
  let [searchName, setSearchName] = useState("");
  let [userList, setUserList] = useState([]);

  function changeNameHandler(value) {
    setSearchName(value);
    clearClientError();
  }

  function findClient(e) {
    if (e.key !== "Enter") {
      return;
    }
    if (searchName.length < 3) {
      setUserList([]);
      setClientId(null);
    } else {
      let reqData = { isChild, name: searchName };

      ClientService.search(reqData).then((res) => {
        let data = res.data;
        setUserList(data);
      });
    }
  }

  function selectClientHandler(name, id) {
    clearClientError();
    setClientId(id);
    setSearchName(name);
    setUserList([]);
  }

  return (
    <Paper sx={{ p: 1 }} variant="outlined">
      {title && (
        <Typography variant="body1" gutterBottom fontWeight="normal">
          {title}
        </Typography>
      )}

      <TextField
        clientError
        placeholder="Поиск"
        type="text"
        size="small"
        variant="outlined"
        error={!!clientError}
        helperText={clientError}
        fullWidth
        onKeyDown={findClient}
        onChange={(e) => changeNameHandler(e.target.value)}
        value={searchName}
        placeholder="Мин. 3 символа"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),

          endAdornment: clientId && (
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => changeNameHandler("")}
            >
              <CancelIcon />
            </IconButton>
          ),
        }}
      />
      {userList.length !== 0 && (
        <List>
          {userList.map((u) => {
            let name = "";

            if (isChild) {
              name = `${u.child.surname} ${u.child.name} ${u.child.patronymic}`;
            } else {
              name = `${u.surname} ${u.name} ${u.patronymic}`;
            }

            return (
              <ListItemButton
                key={u._id}
                onClick={() => selectClientHandler(name, u._id)}
              >
                <ListItemText primary={name} />
              </ListItemButton>
            );
          })}
        </List>
      )}
    </Paper>
  );
}
