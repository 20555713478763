import React from "react";
import {
  Box,
  Container,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
} from "@mui/material";
import getDayNameByNumer from "../../common/getDayNameByNumer";

export default function WageCard({ data }) {
  console.log(data, "data");

  let groups = data.groups;

  let allTotalAmount = 0;
  let allTotalSum = 0;
  let allSalary = 0;

  groups.forEach((g) => {
    allTotalAmount += g.totalAmount;
    allTotalSum += g.totalSum;
    allSalary += g.salary;
  });

  return (
    <Box sx={{ mb: "50px" }}>
      <Typography
        variant="h5"
        sx={{ fontWeight: 700, fontSize: "20px", mb: "20px" }}
      >
        {data.name}
      </Typography>
      <TableContainer sx={{ maxWidth: "100%" }}>
        <Table sx={{ maxWidth: "100%", borderCollapse: 'collapse', borderSpacing: 'unset'  }}>
          <TableHead>
            <TableRow sx={{ borderTopWidht: "2px", borderBottom: '2px solid #1C1C1E' }}>
              <TableCell style={{ textAlign: "left" }}>Основание</TableCell>
              <TableCell style={{ width: "100px", textAlign: "right" }}>
                Кол-во
              </TableCell>

              <TableCell style={{ width: "100px", textAlign: "right" }}>
                Сумма
              </TableCell>
              <TableCell style={{ width: "100px", textAlign: "right" }}>
                Начислено
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ borderTopWidht: "2px", fontWeight: "bold",borderBottom: '2px solid #1C1C1E'  }}>
              <TableCell style={{ textAlign: "left", fontWeight: "bold" }}>
                Продажи
              </TableCell>
              <TableCell
                style={{
                  width: "100px",
                  textAlign: "right",
                  fontWeight: "bold",
                }}
              >
                {allTotalAmount}
              </TableCell>
              <TableCell
                style={{
                  width: "100px",
                  textAlign: "right",
                  fontWeight: "bold",
                }}
              >
                {allTotalSum}
              </TableCell>
              <TableCell
                style={{
                  width: "100px",
                  textAlign: "right",
                  fontWeight: "bold",
                }}
              >
                {allSalary}
              </TableCell>
            </TableRow>
            <TableRow sx={{borderBottom: '2px solid #1C1C1E' }}>
              <TableCell style={{ textAlign: "left" }}>
                Итого по группам
              </TableCell>
            </TableRow>
            {groups.map((g) => {
              let days = g.visitingDays
                .map((d) => getDayNameByNumer(d))
                .join(",");
              return (
                <TableRow sx={{ borderTopWidht: "2px", borderBottom: '2px solid #1C1C1E'  }}>
                  <TableCell style={{ textAlign: "left" }}>
                    {days + " " + g.visitingTime}
                  </TableCell>
                  <TableCell style={{ width: "100px", textAlign: "right" }}>
                    {g.totalAmount}
                  </TableCell>
                  <TableCell style={{ width: "100px", textAlign: "right" }}>
                    {g.totalSum}
                  </TableCell>
                  <TableCell style={{ width: "100px", textAlign: "right" }}>
                    {g.salary}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow
              sx={{
                borderTopWidht: "2px",
                // backgroundColor: "#f9f9fa",
                fontWeight: "bold",
              }}
            >
              <TableCell style={{ textAlign: "left", fontWeight: "bold" }}>
                Итого по сотруднику
              </TableCell>
              <TableCell
                style={{
                  width: "100px",
                  textAlign: "right",
                  fontWeight: "bold",
                }}
              >
                {allTotalAmount}
              </TableCell>
              <TableCell
                style={{
                  width: "100px",
                  textAlign: "right",
                  fontWeight: "bold",
                }}
              >
                {allTotalSum}
              </TableCell>
              <TableCell
                style={{
                  width: "100px",
                  textAlign: "right",
                  fontWeight: "bold",
                }}
              >
                {allSalary}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
