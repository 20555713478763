import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Typography,
  IconButton,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import SidebarWrap from "../SidebarWrap";
import Preview from "./Preview";
import InputWrap from "../InputWrap";
import { useSnackbar } from "notistack";

import MessageService from "../../services/Message";

export default function MessageEdit({ closeHandler, data }) {
  const { enqueueSnackbar } = useSnackbar();

  let [isSubmiting, setSubmiting] = useState(false);
  let [previewImage, setPreviewImage] = useState(null);
  let [previewError, setPreviewError] = useState(null);
  let isEdit = data && Object.keys(data).length;

  let idNews = data?._id;
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
        phone: '+7',
        text: "",
    },
  });

  if (!data) return null;

  const onSubmit = handleSubmit((data) => {
      if (!previewImage) {
        //setPreviewError("Добавьте файл!");
        //return;
      }

      let fetchData = JSON.stringify(Object.assign({}, data));
      const form = new FormData();
      if (previewImage) {
          form.append("file", previewImage);
      }
      form.append("data", fetchData);
      console.log(form)
      setSubmiting(true);
      MessageService.send(form)
        .then((r) => {
             console.log(r)
            if (r.data.sended) {
                enqueueSnackbar("Сообщение отправлено!", { variant: "success" });
                closeHandler();
            } else {
                enqueueSnackbar(r.data.reason, { variant: "error" });
            }
        })
          .catch(e=>{
              enqueueSnackbar('Неизвестная ошибка. Возможно неверный тип файла.', { variant: "error" });
          })
        .finally(() => setSubmiting(false));

  });

  return (
    <SidebarWrap
      title={`Написать сообщение`}
      closeHandler={closeHandler}
    >
      <Preview
        url={isEdit ? data.preview : ""}
        setImage={setPreviewImage}
        error={previewError}
        setError={setPreviewError}
      />

      <form noValidate onSubmit={onSubmit}>
        <Controller
          name={"phone"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputWrap wrapStyle={{ my: 1 }} id="phone" label="Номер телефона">
              <TextField
                id="phone"
                label=""
                error={Boolean(errors.phone?.message)}
                {...register("phone", {
                  required: "Мин. 11 символов",
                  minLength: {
                    message: "Мин. 11 символов",
                    value: 11,
                  },
                  maxLength: 24,
                })}
                fullWidth
                margin="normal"
                helperText={errors.phone?.message}
                sx={{ m: 0 }}
                size="small"
                variant="outlined"
                name="phone"
                type="text"
                onChange={onChange}
                value={value}
              />
            </InputWrap>
          )}
        />
        <Controller
          name={"text"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputWrap wrapStyle={{ my: 1 }} id="text" label="Текст">
              <TextField
                id="text"
                label=""
                error={Boolean(errors.text?.message)}
                {...register("text", {
                  required: "Мин. 4 символов",
                  minLength: {
                    message: "Мин. 4 символов",
                    value: 4,
                  },
                })}
                fullWidth
                margin="normal"
                helperText={errors.text?.message}
                sx={{ m: 0 }}
                size="small"
                multiline
                rows={10}
                variant="outlined"
                name="text"
                type="text"
                onChange={onChange}
                value={value}
              />
            </InputWrap>
          )}
        />
        <Button
          fullWidth
          sx={{ marginTop: 4 }}
          size="small"
          variant="contained"
          type="submit"
          startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
          disabled={isSubmiting}
        >
          Отправить
        </Button>
      </form>
    </SidebarWrap>
  );
}
