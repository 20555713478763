import React, { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { loged } from "../../store/slices/auth";
import { useDispatch } from "react-redux";
import Alert from "@mui/material/Alert";
import AuthService from "../../services/Auth";

// console.log(loged, 'loged')

function Login() {
  let [number, setNumber] = useState("");
  let [pwd, setPwd] = useState("");
  let [numberError, setNumberError] = useState(null);
  let [pwdError, setPwdError] = useState(null);
  let [alert, setAlert] = useState(false);
  let [loading, setLoading] = useState(false);

  let dispatch = useDispatch();

  function changeNumberHandler(e) {
    setAlert(false);
    setNumber(e.target.value);
    setNumberError(null);
  }

  function changePwdHandler(e) {
    setAlert(false);
    setPwd(e.target.value);
    setPwdError(null);
  }

  function checkValidFields() {
    let isValid = true;
    const numberLength = 6;
    const pwdLength = 6;
    if (number.length < numberLength) {
      setNumberError(`Мин. количество символов: ${numberLength}`);
      isValid = false;
    }
    if (pwd.length < pwdLength) {
      setPwdError(`Мин. количество символов: ${pwdLength}`);
      isValid = false;
    }
    return isValid;
  }

  function submitHandler(e) {
    console.log("submitHandler");
    e.preventDefault();
    let isValid = checkValidFields();
    if (!isValid) return;
    fetchLogin();
  }

  async function fetchLogin() {
    try {
      setLoading(true);
      console.log("fetchLogin");
      AuthService.login({
        phone: number,
        password: pwd,
      }).then(({ data }) => {
        localStorage.setItem("token", data.token);
        dispatch(loged(data.user));
      });
    } catch (e) {
      const message = e.response && e.response.data && e.response.data.message;
      if (message) {
        setAlert(message);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container maxWidth="lg">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: "100vh" }}
      >
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Paper sx={{ padding: "15px 10px" }}>
            <Typography
              textAlign="center"
              variant="h6"
              component="div"
              sx={{ marginBottom: "10px" }}
            >
              Войти в аккаунт
            </Typography>
            <Box
              component="form"
              sx={{
                width: "80%",
                marginX: "auto",
                marginTop: "20px",
              }}
              onSubmit={submitHandler}
            >
              {alert && (
                <Alert sx={{ marginBottom: "15px" }} severity="error">
                  {alert}
                </Alert>
              )}

              <TextField
                fullWidth
                label="Номер"
                variant="outlined"
                size="small"
                margin="dense"
                value={number}
                onChange={changeNumberHandler}
                error={!!numberError}
                helperText={numberError}
              />
              <TextField
                fullWidth
                type="password"
                label="Пароль"
                variant="outlined"
                size="small"
                margin="dense"
                value={pwd}
                onChange={changePwdHandler}
                error={!!pwdError}
                helperText={pwdError}
              />
              <Button
                type="submit"
                sx={{ marginTop: "20px" }}
                fullWidth
                variant="contained"
                disabled={loading}
              >
                Войти
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Login;
