import axios from "axios";

class AppSettings {
    save(data) {
        return axios.post(`/settings`, data);
    }
    get() {
        return axios.get(`/settings`);
    }
}

export default new AppSettings({});
