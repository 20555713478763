import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuth: false,
    user: null,
  },
  reducers: {
    loged(state, action) {
      state.isAuth = true;
      state.user = action.payload;
    },
    logout(state) {
      console.log("dispatch logout");
      state.isAuth = false;
      state.user = null;
      localStorage.clear("token");
    },
  },
});

export const { loged, logout } = authSlice.actions;
export default authSlice.reducer;
