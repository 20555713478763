import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardActionArea,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Divider,
  Typography,
  SwipeableDrawer,
  Button,
  IconButton,
} from "@mui/material";
import FunnelStageStore from "../store/funnelStage";

function StageItem({ data, count, style = {} }) {
  return (
    <Card sx={{ boxShadow: "none", ...style }}>
      <CardActionArea sx={{ pt: 2 }}>
        <Typography variant="h5" align="center" gutterBottom>
          {count || 0}
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          {data.name}
        </Typography>
        <Box
          sx={{ w: "100%", minHeight: "10px", bgcolor: data.backgroundColor }}
        ></Box>
      </CardActionArea>
    </Card>
  );
}

export default function StageList({
  stat = {},
  setFilterHandler = () => {},
  mode = "membership",
  selectedFilter = [],
}) {
  console.log({ selectedFilter });
  let list =
    mode == "membership"
      ? FunnelStageStore.membershipStages
      : FunnelStageStore.orderStages;

  list = list.sort((a, b) => a.displayOrder - b.displayOrder);
  return (
    <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
      <Grid container justifyContent="center" spacing={2}>
        {list.map((f) => {
          let isActive = selectedFilter.find((s) => s == f._id);
          return (
            <Grid
              onClick={() => setFilterHandler(f._id)}
              key={f._id}
              item
              xs={4}
              sm={3}
              md={2}
              lg={1.2}
            >
              <StageItem
                style={{
                  border: isActive ? "1.5px solid white" : "none",
                }}
                data={f}
                count={stat[f._id]}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
