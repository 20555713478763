import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Grid,
  TableCell,
  TableContainer,
  Paper,
} from "@mui/material";
import Filter from "./Filter";
import moment from "moment";
import TrainerWageService from "../../services/TrainerWage";
import firstLetterUppercase from "../../common/firstLetterUppercase";
import TrainerStore from "../../store/trainer";
import groupGetFullInfo from "../../common/groupGetFullInfo";
import WageCard from "./WageCard";

import "moment/locale/ru";


// Store
import ClubStore from '../../store/club'
import {observer} from 'mobx-react-lite';

let initFilterData = {
  month: {
    startDate: moment().startOf("month").valueOf(),
    endDate: moment().endOf("month").valueOf(),
  },
  trainer: "",
  group: "",
};

function WagePage() {
  let clubId = ClubStore.currentClubId;
  // let trainers = TrainerStore.list;
  let [filterData, setFilterData] = useState(initFilterData);
  let [list, setList] = useState([]);

  useEffect(() => {
    findHandler();
  }, [clubId]);

  function changeFilterData(name, value) {
    let newFilterData = Object.assign({}, filterData, { [name]: value });
    setFilterData(newFilterData);
  }

  function findHandler() {
    TrainerWageService.getStat(Object.assign(filterData.month)).then((r) =>
      setList(r.data)
    );
  }

  function clearFilterHandler() {
    setFilterData(initFilterData);
  }

  // let allTotalAmount = 0;
  // let allTotalSum = 0;
  // let allSalary = 0;

  // list.forEach((r) => {
  //   allTotalAmount += r.totalAmount;
  //   allTotalSum += r.totalSum;
  //   allSalary += r.salary;
  // });

  let globalTotalAmount = 0;
  let globalTotalSum = 0;
  let globalsalary = 0;

  list.forEach((t) => {
    t.groups.forEach((g) => {
      globalTotalAmount += g.totalAmount;
      globalTotalSum += g.totalSum;
      globalsalary += g.salary;
    });
  });

  // <TableCell>{r.totalAmount}</TableCell>
  // <TableCell>{r.}</TableCell>
  // <TableCell>{r.salary}</TableCell>

  return (
    <Box component={Paper} sx={{ p: 2 }}>
      <Typography variant="h5" gutterBottom>
        Заработная плата
      </Typography>
      <Filter
        findHandler={findHandler}
        clearFilterHandler={clearFilterHandler}
        filterData={filterData}
        changeFilterData={changeFilterData}
      />
      <Grid container spacing={2}>
        <Grid item md={5} xs={12}>
          <Box>
            {list.map((d) => (
              <WageCard data={d} />
            ))}
          </Box>
          <TableContainer sx={{ maxWidth: "100%" }}>
            <Table sx={{ maxWidth: "100%", borderCollapse: 'collapse', borderSpacing: 'unset' }}>
              <TableHead>
                <TableRow
                  sx={{
              
                    borderTopWidht: "2px",
                    // backgroundColor: "#f9f9fa",
                    fontWeight: "bold",
                  }}
                >
                  <TableCell style={{ textAlign: "left", fontWeight: "bold" }}>
                    Общий итог
                  </TableCell>
                  <TableCell
                    style={{
                      width: "100px",
                      textAlign: "right",
                      fontWeight: "bold",
                    }}
                  >
                    {globalTotalAmount}
                  </TableCell>
                  <TableCell
                    style={{
                      width: "100px",
                      textAlign: "right",
                      fontWeight: "bold",
                    }}
                  >
                    {globalTotalSum}
                  </TableCell>
                  <TableCell
                    style={{
                      width: "100px",
                      textAlign: "right",
                      fontWeight: "bold",
                    }}
                  >
                    {globalsalary}
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
}

export default observer(WagePage);