import React, { useState, useEffect } from "react";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Fab from "@mui/material/Fab";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

import { API_IMAGES } from "../../config";

export default function UploadChatImage({ url, setImage, image }) {
    const [selectedFile, setSelectedFile] = useState(false);

    useEffect(() => {
        if (!image && selectedFile) {
            setSelectedFile(false)
        }
    }, [image]);

    const imageUrl = API_IMAGES + url;

    function changeImageHandler(event) {
        var file = event.target.files[0];
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onloadend = function (e) {
            setSelectedFile([reader.result]);
        };

        createImage(file);

        function createImage(file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    }

    const viewSelectedImg = (
        <CardActionArea>
            <input
                accept="image/*"
                style={{ display: "none" }}
                id="contained-button-file"
                multiple
                type="file"
                onChange={changeImageHandler}
            />
            <label htmlFor="contained-button-file">
                <div style={{display:'grid',justifyItems:'center'}}>
                    <CardMedia
                        component="img"
                        sx={{ maxHeight: "160px", maxWidth:'240px' }}
                        image={selectedFile + ""}
                        alt="Сменить изображение"
                    />
                </div>
            </label>
        </CardActionArea>
    );


    const viewNoneImg = (
        <Grid container justify="center" alignItems="center">
            <input
                accept="image/*"
                style={{ display: "none" }}
                id="contained-button-file"
                multiple
                type="file"
                onChange={changeImageHandler}
            />
            <label
                style={{
                    display: "flex",
                    "justify-content": "center",
                    width: "100%",
                }}
                htmlFor="contained-button-file"
            >
                <div style={{display:'grid',gridTemplateColumns:'auto 1fr',gridGap:'20px',alignItems:'center'}}>
                    <AddPhotoAlternateIcon />
                    <p>Добавить фото</p>
                </div>
            </label>
        </Grid>
    );

    function renderState() {
        if (selectedFile) {
            return viewSelectedImg;
        }

        return viewNoneImg;
    }

    let content = renderState();
    return <Card sx={{ maxWidth: "100%" }}>{content}</Card>;
}
