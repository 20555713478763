import ClientService from "../../../services/Client";

export default function fetchClients(
  filter,
  pagin,
  isSubmit,
  setSubmit,
  setList,
  setStat,
  changePaginData,
  setAllClientIds,
  sortData
) {
  if (isSubmit) return;
  setSubmit(true);
  ClientService.getAll({ filter, pagin, sort: sortData })
    .then((res) => {
      let data = res.data;
        console.log(data)
      setList(data.clients);
      setStat(data.stat);
      changePaginData("countRows", data.pagin.countRows);
      setAllClientIds(data.allClientIds);
    })
    .finally(() => {
      setSubmit(false);
    });
}
