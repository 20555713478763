import React, { useState, useMemo } from "react";
import {
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Typography,
  SwipeableDrawer,
  Button,
  TextField,
  IconButton,
  FormControl,
  Select,
  Popover,
  MenuItem,
  InputLabel,
  InputAdornment,
  Grid,
} from "@mui/material";
import moment from "moment";
import "moment/locale/ru";
import DateRangePicker from "../../components/DateRangePicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MultipleSelect from "../../components/MultipleSelect";
import getDateRangeText from "../../common/getDateRangeText";
import CustomInput from "../../components/CustomInput";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import InputWrap from "../../components/InputWrap";
import MultipleSelectChip from "../../components/FiltersUI/MultipleSelectChip";
import TrainerStore from "../../store/trainer";
import GroupStore from "../../store/group";
import ClubStore from "../../store/club";
import groupGetFullInfo from "../../common/groupGetFullInfo";
import FunnelStageStore from "../../store/funnelStage";


export default function Filter({
  filterData,
  changeFilterHandler,
  clearFilterHandler,
  findHandler,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  let [popoverViewName, setPopoverViewName] = useState("");

  const openPopover = (event, name) => {
    setPopoverViewName(name);
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
    setPopoverViewName("");
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function viewPopover() {
    switch (popoverViewName) {
      case "created":
        return (
          <DateRangePicker
            startDate={filterData.created.startDate}
            endDate={filterData.created.endDate}
            setDate={(a, b) =>
              changeFilterHandler("created", { startDate: a, endDate: b })
            }
          />
        );
      case "visitDay":
        return (
          <DateRangePicker
            startDate={filterData.visitDay.startDate}
            endDate={filterData.visitDay.endDate}
            setDate={(a, b) =>
              changeFilterHandler("visitDay", { startDate: a, endDate: b })
            }
          />
        );
      default:
        return "";
    }
  }

  let currentClubId = ClubStore.currentClubId;

  const groupList = useMemo(() => {
    let list = [...GroupStore.list].filter((g) => g.club == currentClubId);
    if (!filterData.trainer.length) return list;

    changeFilterHandler("group", []);

    let result = [];

    list.forEach((g) => {
      if (filterData.trainer.includes(g.trainer)) {
        result.push(g);
      }
    });

    return result;
  }, [filterData.trainer]);

  return (
    <Card sx={{ p: 1, my: 2, boxShadow: "none" }}>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {viewPopover()}
      </Popover>
      <Grid container spacing={2}>
        <Grid item xs={6} md={4} lg={2}>
          <InputWrap id="name" label="Название">
            <TextField
              id="name"
              label=""
              fullWidth
              sx={{ m: 0 }}
              size="small"
              variant="outlined"
              name="name"
              type="text"
              onChange={(e) => changeFilterHandler("name", e.target.value)}
              value={filterData.name}
            />
          </InputWrap>
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <InputWrap id="created" label="Дата создания записи">
            <TextField
              id="created"
              label=""
              fullWidth
              sx={{ m: 0 }}
              size="small"
              variant="outlined"
              name="created"
              onClick={(e) => openPopover(e, "created")}
              type="text"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarMonthIcon />
                  </InputAdornment>
                ),
              }}
              // onChange={(e) => changeFilterHandler("name", e.target.value)}
              value={getDateRangeText(
                filterData.created.startDate,
                filterData.created.endDate
              )}
            />
          </InputWrap>
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <InputWrap id="clientName" label="ФИО Клиента">
            <TextField
              id="clientName"
              label=""
              fullWidth
              sx={{ m: 0 }}
              size="small"
              variant="outlined"
              name="clientName"
              type="text"
              onChange={(e) =>
                changeFilterHandler("clientName", e.target.value)
              }
              value={filterData.clientName}
            />
          </InputWrap>
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <InputWrap id="childName" label="ФИО Ребёнка">
            <TextField
              id="childName"
              label=""
              fullWidth
              sx={{ m: 0 }}
              size="small"
              variant="outlined"
              name="childName"
              type="text"
              onChange={(e) => changeFilterHandler("childName", e.target.value)}
              value={filterData.childName}
            />
          </InputWrap>
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <InputWrap id="phone" label="Телефон">
            <TextField
              id="phone"
              label=""
              fullWidth
              sx={{ m: 0 }}
              size="small"
              variant="outlined"
              name="phone"
              type="number"
              onChange={(e) => changeFilterHandler("phone", e.target.value)}
              value={filterData.phone}
            />
          </InputWrap>
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <MultipleSelectChip
            title="Тренер"
            data={TrainerStore.list}
            list={filterData.trainer}
            setList={(list) => changeFilterHandler("trainer", list)}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <MultipleSelectChip
            title="Группа"
            data={groupList.map((g) => ({
              name: groupGetFullInfo(g),
              _id: g._id,
            }))}
            list={filterData.group}
            setList={(list) => changeFilterHandler("group", list)}
          />
        </Grid>

        <Grid item xs={6} md={4} lg={2}>
          <InputWrap id="visitDay" label="Дата занятия">
            <TextField
              id="visitDay"
              label=""
              fullWidth
              sx={{ m: 0 }}
              size="small"
              variant="outlined"
              name="visitDay"
              onClick={(e) => openPopover(e, "visitDay")}
              type="text"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarMonthIcon />
                  </InputAdornment>
                ),
              }}
              value={getDateRangeText(
                filterData.visitDay.startDate,
                filterData.visitDay.endDate
              )}
            />
          </InputWrap>
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <MultipleSelectChip
            title="Этап воронки"
            data={FunnelStageStore.orderStages}
            list={filterData.stage}
            setList={(list) => changeFilterHandler("stage", list)}
          />
        </Grid>
      </Grid>

      <Box sx={{ display: "flex", mt: 2 }}>
        <Button
          variant="contained"
          size="small"
          startIcon={<SearchIcon />}
          onClick={findHandler}
        >
          Применить
        </Button>
        <Button
          sx={{ ml: 1 }}
          variant="outlined"
          size="small"
          startIcon={<ClearIcon />}
          onClick={clearFilterHandler}
        >
          Очистить
        </Button>
      </Box>

      {/* <Grid container sx={{ mt: 2 }} spacing={2}>
        <Grid item xs={6} md={4} lg={2}>
          <Button
            variant="contained"
            fullWidth
            startIcon={<SearchIcon />}
            // onClick={findHandler}
          >
            Поиск
          </Button>
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <Button
            fullWidth
            variant="outlined"
            startIcon={<ClearIcon />}
            onClick={clearFilterHandler}
          >
            Очистить
          </Button>
        </Grid>
      </Grid> */}
    </Card>
  );
}
