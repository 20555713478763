import React, { useState } from "react";
import { Box, CardActionArea, Card, Typography, Grid } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        color: '#808080',
        borderColor: '#808080'
      },
      ticks: {
        color: "#808080",
       
    }
    },
    y: {
      grid: {
        color: '#808080',  
        borderColor: '#808080'
      },
      ticks: {
        color: "#808080",
       
    }
    }
  },
  plugins: {
    
    legend: {
      position: "none",
    },
    
    title: {
      display: false,
      text: "Chart.js Bar Chart",
    },
  },
};

//
//

function getName(c) {
  switch (c) {
    case "revenue":
      return "Выручка";
    case "newClients":
      return "Новые клиенты";
    case "countPayment":
      return;
    case "installApps":
      return;
  }
}

export default function Chart({ chart }) {
  let [name, setName] = useState("revenue");
  const labels = chart.map((c) => c.name);

  const data = {
    labels,
    maintainAspectRatio: false,
    datasets: [
      
      {
        // label: "Прибыль",
        data: chart.map((c) => c[name]),
        backgroundColor: "#FF0009",
      },
    ],
  };

  return (
    <Box sx={{ mt: 4, mb: 4 }}>
      <Grid sx={{ mb: 2 }} alignItems="stretch" justifyContent="center" container>
        <ChartSelect
          setName={setName}
          name="revenue"
          text="Выручка"
          isSelect={"revenue" == name}
        />

        <ChartSelect
          setName={setName}
          name="countPayment"
          text="Кол-во оплат"
          isSelect={"countPayment" == name}
        />

        <ChartSelect
          setName={setName}
          name="newClients"
          text="Новые клиенты"
          isSelect={"newClients" == name}
        />
        <ChartSelect
          setName={setName}
          name="installApps"
          text="Установленно приложений"
          isSelect={"installApps" == name}
        />
      </Grid>
      <Box style={{ height: "300px" }}>
        <Bar options={options} data={data} />
      </Box>
    </Box>
  );
}

function ChartSelect({ setName, name, text, isSelect = false }) {
  let st = { p: 1, height: '100%' };

  let textStyle = { fontSize: "16px" };

  if (isSelect) {
    st = Object.assign({}, st, { bgcolor: "#FF0009", color: "white" });
    textStyle.fontWeight = 600;
  }

  return (
    <Grid item xs={6} md={3}>
      <Card sx={{borderRadius: '0px', height: '100%'}}>
        <CardActionArea  onClick={() => setName(name)} sx={st}>
          <Typography sx={textStyle} variant="h6" align="center">
            {text}
          </Typography>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
