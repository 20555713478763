import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function SidebarWrap({
  title,
  closeHandler,
  children,
  large = false,
}) {
  return (
    <Box
      sx={{
        py: "20px",
        px: "10px",
        pt: "5px",
        minWidth: { xs: "calc(100vw - 0px)", sm: large ? "90vw" : "500px" },
        maxWidth: { xs: "calc(100vw - 0px)", sm: large ? "90vw" : "500px" },
        width: { xs: "calc(100vw - 0px)", sm: large ? "90vw" : "500px" },
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          maxWidth: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          pb: 1,
        }}
      >
        <Typography variant="h6">{title}</Typography>
        <IconButton sx={{ m: 0 }} onClick={closeHandler}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box>{children}</Box>
    </Box>
  );
}
