import React from "react";
import { Card } from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

function LessonPrice({ prices, changePrice }) {
  function addSumMembership() {
    changePrice(
      Object.assign({}, prices, { membership: [...prices.membership, 0] })
    );
  }
  function changeSumMembership(sum, i) {
    let newMemebership = prices.membership;
    newMemebership.splice(i, 1, sum);
    changePrice(Object.assign({}, prices, { membership: newMemebership }));
  }

  function changeSumOrder(e) {
    let sum = e.target.value;
    changePrice(Object.assign({}, prices, { order: +sum }));
  }

  return (
    <Card sx={{ p: 1 }}>
      <Typography variant="h6" gutterBottom component="div">
        Цены:
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            Пробное:
          </Typography>
          <TextField
            value={prices.order}
            fullWidth
            margin="dense"
            size="small"
            variant="filled"
            name="trial"
            type="number"
            onChange={changeSumOrder}
          />
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body2" gutterBottom>
            Абонемент:
          </Typography>
          <Grid container spacing={2}>
            {prices.membership.map((i, o) => (
              <Grid item xs={6} md={4}>
                <TextField
                  value={i}
                  fullWidth
                  label={o + 1}
                  margin="dense"
                  size="small"
                  variant="filled"
                  name="twoDayInWeek"
                  type="number"
                  onChange={(e) => changeSumMembership(e.target.value, o)}
                />
              </Grid>
            ))}
            <Grid item xs={6} md={4}>
              <Button
                sx={{ mt: 2 }}
                fullWidth
                variant="contained"
                onClick={addSumMembership}
              >
                Добавить
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

export default LessonPrice;
